// adapted from modal-account
@use 'sass:math';

.modal-country-selector {
    $this: &;

    .form-group {
        margin-bottom: 2rem;
    }

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    &__close {
        @include mq('medium') {
            top: 4rem;
            right: 8rem;
        }
    }

    &__content {
        display: block;

        text-align: center;

        h2 {
            margin: 0 auto 8rem;

            @include mq('medium', 'max') {
                margin: 0 auto 6rem;
            }
        }
    }

    &__body {
        display: block;

        width: 100%;

        text-align: left;

        .btn {
            display: block;

            width: 100% !important;
        }
    }

    .modal-dialog {
        position: relative;

        width: 100%;
        max-width: none;
        margin: 0;

        color: $white;

        background-color: $black;

        transform: none !important;

        pointer-events: none;
    }

    &__copy {
        margin-bottom: 1rem;

        .flagText {
            padding-bottom: 3rem;

            font-weight: 400;
            font-size: 2.8rem;
            line-height: math.div(7, 6);
            letter-spacing: -0.028rem;
        }

        .dropdown-country-selector {
            &.flags {
                svg {
                    width: 100%;
                }
            }

            .see-all-countries {
                display: block;

                padding-top: 2rem;
            }
        }
    }

    .modal-content {
        z-index: 2;

        min-height: 360px;
        padding: 8rem 2.5rem !important;

        border: 0;

        background-color: $black;
    }

    .modal-body {
        display: flex;
        align-items: center;

        padding: 0;

        // ELEMENTS

        .row {
            margin-right: -25px;
            margin-left: -25px;
        }

        [class*='col'] {
            padding-right: 25px;
            padding-left: 25px;
        }
    }

    .countrySelectorSubmit {
        margin-bottom: 2rem;
    }
}

.modal-region-mismatch.modal {
    .modal-body {
        .btn {
            width: 100%;
        }
    }
}
