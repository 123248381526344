.alert {
    @include p-xs;

    svg {
        display: inline;

        width: 18px;
        height: 18px;
        margin: {
            top: -1px;
            right: 3px;
        }
    }

    p {
        @include p-xs;
        display: inline-block;

        &.bold {
            font-weight: bold;
        }
    }

    &-info {
        background-color: $alert-info;
    }

    &-warning {
        background-color: $alert-warning;
    }

    &-success {
        background-color: $alert-success;
    }

    &-danger {
        background-color: $alert-danger;
    }

    &-link {
        @include underline-styles;
    }

    .text-link,
    .text-link-sm {
        text-decoration-line: underline;
    }
}

.alert-dismissible .btn-close {
    top: 50%;

    transform: translateY(-50%);
}

.is-dark,
.themed.is-dark {
    @each $state, $value in $theme-colors {
        $alert-color: shift-color($value, $alert-color-scale);

        .alert-#{$state} {
            color: $alert-color;

            .alert-link {
                color: $alert-color;
            }
        }
    }
}
