@import './mixed-layouts/mixed-layouts';
@import './callout/callouts';
@import './callout/callout-blurb';
@import './callout/callout-headline';
@import '../required/swiper';
@import './sliders/sliders';
@import './sliders/slider-cards';
@import './modules/flex-routing';
@import './modals/modals';
@import './modules/pages/page-home';
@import './hero/hero-caption';
@import './search/search-overlay';
@import './storytelling/storytelling-scroller-critical';
@import './modals/modal-content';
@import './modals/modal-table';
@import './modals/modal-sign-in';
@import './modules/email-signup';

.modal-open.modal-open--small-modal {
    overflow: auto !important;
}
