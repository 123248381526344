@use 'sass:map';
@use 'sass:math';
@import './../base-bootstrap';

.product-tile-minimal {
    padding: $spacer * 2 $spacer * 2 $spacer * 2 $spacer;

    color: $black;

    border-radius: $border-radius;

    background: $white;

    &,
    &__start,
    &__end {
        display: grid;
        grid-template-columns: fit-content(100%) auto;
        gap: $spacer * 0.5;
        align-content: space-between;
    }

    & > &__start,
    &__end {
        align-items: center;
    }

    & > &__end {
        grid-template-rows: auto auto auto;
        grid-template-columns: auto;
        row-gap: $spacer * 0.5;
    }

    &__name,
    &__pricing,
    .sales,
    .value {
        @include p-sm;
        @include font-family('regular');
    }

    &__meta {
        @include p-xs;
        @include font-family('regular');
        opacity: 0.7;
    }

    &__name {
        display: -webkit-box;

        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        overflow: hidden;
    }

    &__pricing {
        text-align: end;
    }

    .price {
        .sales,
        .list,
        .strike-through {
            display: block;

            margin: 0;
        }
    }

    .strike-through ~ .sales .value {
        @include font-family('regular');
        color: $red-brand;
    }

    &__image {
        display: block;

        width: 9.6rem;
        height: 9.6rem;
    }

    &__end &__end {
        grid-row: 3;

        justify-content: space-between;
    }
}
