@use 'sass:color';

@mixin custom-input-input-disabled-styles($color: $black, $background-color: transparent) {
    background-color: $background-color;

    outline: 0;

    box-shadow: none;

    cursor: not-allowed;

    opacity: 0.5;

    ~ span {
        opacity: 0.5;
    }
}

@mixin custom-input-input-invalid-border() {
    border-color: $red-utility;
}

@mixin custom-input-input($color: $black, $background-color: transparent) {
    @include font-family('regular');
    padding: 0;

    color: $color;
    font-size: 1.6rem;
    line-height: $line-height;

    background-color: $background-color;
    background-repeat: no-repeat;

    box-shadow: 0 0 0 0 $black;

    appearance: none;

    transition:
        all 0.2s,
        background-position 0s,
        background-size 0s;
    caret-color: currentColor;

    &:not([multiple], [type='checkbox'], [type='radio']),
    &[size='1'] {
        height: 30px;
    }

    &::placeholder {
        @include font-family('regular');
        color: transparent;

        transition: color 0.2s;
    }

    &:focus::placeholder {
        color: $grey;
    }

    &:focus,
    &:active,
    &.input--clicked {
        border-color: $blue-utility;
    }

    &:disabled,
    &.disabled {
        @include custom-input-input-disabled-styles($color, $background-color);
    }
}

@mixin custom-input-input-read-only($color: $black, $background-color: transparent) {
    &.is-invalid {
        &:read-only {
            @include custom-input-input-invalid-border;
        }
    }

    &:not(select):read-only {
        @include custom-input-input-disabled-styles($color, $background-color);
    }
}

@mixin custom-input-select-btn($color: $black, $background-color: transparent) {
    @include font-family('regular');

    left: 0;
    z-index: 2;

    display: flex;

    width: 100%;
    height: 30px;
    padding: 0 50px 0 0;

    color: $color;
    font-size: 1.6rem;
    text-align: left;

    border-bottom: 2px solid $color;

    box-shadow: 0 0 0 0 $black;

    appearance: none;
    user-select: none;

    transition: box-shadow 0.2s;
    -webkit-touch-callout: none;

    &:not(.skeleton-element) {
        background-color: $background-color;
        background-image: escape-svg($form-select-indicator);
        background-repeat: no-repeat;
        background-position: $form-select-bg-position;
        background-size: $form-select-bg-size;
    }

    &:not(.open):focus,
    &:not(.open):active {
        outline: 0.2rem $blue-utility solid;
    }

    em {
        width: 100%;

        font-style: normal;
    }

    &.open,
    &.show {
        z-index: 501;

        color: color.invert($color);

        background-color: $color;
        background-image: none;

        ~ span:not(.char-remain-count, .char-allowed-count) {
            opacity: 0;
        }

        em {
            position: absolute;
            top: $spacer * 2;
            left: $spacer * 2;
            right: $spacer * 2;
            z-index: 501;

            padding-right: $form-select-feedback-icon-padding-end;

            color: color.invert($color);

            background-image: #{svg(
                    'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-up-small.svg',
                    (
                        'black': color.invert($color),
                    )
                )};
        }
    }

    &:disabled,
    &input:read-only,
    &textarea:read-only {
        @include custom-input-input-disabled-styles($color, $background-color);
    }
}

@mixin custom-input-select($color: $white, $background-color: $black) {
    position: relative;
    top: 0;
    z-index: 500;

    width: 100%;
    padding: 1rem ($spacer * 2) 0;

    border: none;
    border-radius: 0 0 24px 24px;

    background: $background-color;

    box-shadow: 0 2px 2px 0 rgba($white, 0.25);

    overflow: hidden;

    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';

        width: 100%;
        height: 2rem;

        background: $background-color;
        background: linear-gradient(
            0deg,
            rgba($background-color, 1) 0%,
            rgba($background-color, 0) 100%
        );
    }

    ul {
        max-height: 0;

        overflow: auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        transition: 0.2s $ease-out-soft;

        // Internet Explorer Scroll Bar Styling
        scrollbar-base-color: $background-color;
        scrollbar-face-color: $color;
        scrollbar-highlight-color: $background-color;
        scrollbar-track-color: $background-color;
        scrollbar-arrow-color: $color;
        scrollbar-shadow-color: $background-color;

        // Firefox Scroll Bar Styling
        scrollbar-color: $color $background-color;
        scrollbar-width: thin;

        // Webkit Scroll Bar Styling
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 2px;

            background-color: $background-color;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;

            background-color: rgba(255, 255, 255, 0.2);
        }

        &::-webkit-scrollbar-thumb:hover {
            border-radius: 2px;

            background-color: $color;
        }
    }

    &.open ul,
    &.show ul {
        max-height: 300px;
    }

    li {
        position: relative;

        display: block;

        width: 100%;
        min-height: 40px;
        padding-top: 11px;

        color: $grey;
        font-size: 1.6rem;
        line-height: $line-height;

        cursor: pointer;

        user-select: none;

        transition: color 0.2s;
        -webkit-touch-callout: none;

        &:hover,
        &.selected,
        &.active {
            color: $white;
        }

        &.is-disabled {
            opacity: 0.5;

            pointer-events: none;
        }

        &.active,
        &:hover.active {
            &:before {
                position: absolute;
                top: 12px;
                right: 20px;
                content: '';
                z-index: 500;

                width: 8px;
                height: 12px;

                border: solid $color;
                border-width: 0 3px 3px 0;

                transform: rotate(45deg);
            }
        }

        &.selected:not([data-value='']):after {
            position: absolute;
            top: 12px;
            right: 20px;
            content: '';
            z-index: 501;

            width: 8px;
            height: 12px;

            border-color: $green-utility;
            border-style: solid;
            border-width: 0 3px 3px 0;

            transform: rotate(45deg);
        }

        &:last-child {
            margin-bottom: 2rem;
        }
    }

    &[x-placement*='top'] {
        padding-top: 0;
        padding-bottom: 1rem;

        border-radius: 24px 24px 0 0;

        &::after {
            top: 0;
            bottom: auto;

            background: linear-gradient(
                0deg,
                rgba($background-color, 0) 0%,
                rgba($background-color, 1) 100%
            );
        }

        li {
            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                margin-top: 2rem;
            }
        }
    }
}
