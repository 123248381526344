@use 'sass:math';

.positioning-header {
    @include font-family('sans');
    padding: ($spacer * 6) 0;

    color: var(--mt-foreground);

    background-color: var(--mt-background);

    @include mq('large') {
        padding: ($spacer * 10) 0;
    }

    &__inner {
        margin-bottom: ($spacer * 2);

        &__title {
            margin-bottom: math.div(24em, 48);
        }

        img {
            height: auto;
        }
    }

    &__cta-wrapper {
        display: flex;
        align-items: baseline;
    }

    &__ctas {
        a:not(.btn-sm, .btn-xs) {
            @include mq('large', 'max') {
                @include button-size(
                    $btn-padding-y-sm,
                    $btn-padding-x-sm,
                    $btn-font-size-sm,
                    $btn-border-radius-sm
                );
                min-width: 0;
            }
        }
    }

    &__text-link {
        margin: 0 ($spacer * 2) 0 0;
    }

    &__badge {
        position: relative;

        img {
            position: absolute;
            right: 0;

            width: auto;
            height: auto;
            max-width: 100px;
            max-height: 100px;
            margin-top: $spacer;

            object-fit: contain;

            pointer-events: none;

            @include mq('large') {
                position: absolute;
                top: -35px;
                right: 0;
                z-index: 1;

                max-width: 228px;
                max-height: 300px;
                margin-top: 0;
            }
        }
    }
}
