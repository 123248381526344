@use 'sass:math';

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: $spacer;

    margin: ($spacer * 2) 0 0;

    ~ .product-tile__quickadd-container--mobile {
        bottom: 16px; // mobile quick add btn size / 2
    }
}

.product-tag {
    @include p-xs;

    display: none;

    max-width: calc(100% - 40px); // mobile quick add btn size + $spacer
    padding: $spacer;

    white-space: nowrap;
    text-overflow: ellipsis;

    border-radius: $border-radius-sm * 0.5;

    background-color: $grey-white;

    overflow: hidden;

    @include mq('medium') {
        display: inline-block;

        max-width: 160px;
    }

    &:nth-child(1) {
        display: inline-block;
    }
}
