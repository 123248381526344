@use 'sass:map';

.form-check-label {
    margin-top: 0;
    margin-bottom: 0.8rem;
    padding-right: 0.5rem;
    padding-left: 0.1rem;
}

.form-check-input {
    margin-top: 0;

    @include mq('large') {
        margin-top: 0;
    }
}

// CSS Overrides for Custom Check Boxes to match new theme
.form-check {
    margin-bottom: 1rem;

    &.disabled {
        pointer-events: none;
    }

    label {
        @include p;

        opacity: inherit;
    }
}

// Add support for Dark Theme
.dark-theme,
.is-dark {
    $input-bg: $black;
    $input-color: $white;
    $form-check-input-bg: $black;
    $form-check-input-border: 2px solid $white;
    $form-check-input-focus-border: $white;
    $form-check-input-checked-color: $black;
    $form-check-input-checked-bg-color: $white;
    $form-check-input-checked-border-color: $form-check-input-checked-bg-color;
    $form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
    $form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");
    $form-check-input-indeterminate-color: $black;
    $form-check-input-indeterminate-bg-color: $white;
    $form-check-input-indeterminate-border-color: $white;
    $form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

    @import 'bootstrap/scss/forms/form-check';

    .form-check-input {
        margin-top: 0;

        @include mq('large') {
            margin-top: 0;
        }
    }

    .form-check {
        margin-bottom: 1rem;

        &.disabled {
            pointer-events: none;
        }

        label {
            @include p;

            opacity: inherit;
        }
    }

    @import './../bootstrap-override-variables';
}
