// adapted from modal-account

.modal-instore-pickup {
    $this: &;

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    &__close {
        position: sticky;

        border: 0;

        @include mq('medium') {
            top: calc(var(--bs-gutter-x) * 2);
            right: calc(var(--bs-gutter-x) * 4);
        }

        .cta-circle {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .modal-content {
        border-radius: 8px !important;

        @include mq('large') {
            margin-top: calc(var(--header-height) + 20px) !important;
        }
    }

    .modal-body {
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
    }

    .container {
        width: 90%;
        max-width: 50rem;
        padding: 0;

        .row {
            margin: 0;
        }
    }

    h4 {
        @include h5;

        margin: 0 0 2rem;

        @include mq('large') {
            @include h7;
        }
    }

    .card {
        background: transparent;
    }

    .in-store-inventory-dialog {
        .mt-auto {
            @include media-breakpoint-up(sm) {
                margin-top: -2rem !important;
            }

            @include media-breakpoint-down(xs) {
                margin-top: 1.15em !important;
            }
        }

        .modal-instore-pickup__close {
            position: sticky;
        }
    }

    .search-form {
        margin-bottom: 1rem;
        padding: 0 1rem;

        text-align: left;

        h2 {
            @include h3;

            margin: 0 0 2rem;
        }

        &_copy {
            padding: 0 0 2rem;
        }

        .card-body {
            padding: 0;
        }

        &_radius {
            display: inline-block;

            margin: 0;

            .radius_text {
                display: inline-block;

                vertical-align: middle;

                p {
                    display: inline-block;
                }
            }

            select.form-control {
                @include font-family('medium');
                display: inline-block;

                width: auto;
                height: auto;
                padding-right: 3rem;

                border-bottom: none;
            }
        }

        form {
            display: flex;
            align-items: flex-start;

            margin: 2rem auto 0;

            .custom-input {
                margin-top: 0;
                margin-bottom: 1rem;

                &.search-form_radius {
                    margin-bottom: 0;
                }
            }

            .btn-storelocator-search {
                width: auto;
                min-height: 30px;
                padding-right: 0;
                padding-left: 1rem;

                color: $black;
                line-height: 2.8rem;
                white-space: nowrap;

                border-bottom: 2px solid $black;

                background-color: transparent;

                &:hover {
                    color: $black;
                    text-decoration: none;

                    border-bottom: 2px solid $black;

                    background-color: transparent;
                }
            }
        }
    }

    .view-all {
        position: sticky;
        bottom: 0;
        z-index: 10;

        display: block;

        padding: 0 2rem 5rem;

        text-align: center;

        transition: transform 0.5s ease-in-out;

        &.hide {
            transform: translateY(150%);

            @include mq('medium') {
                transform: translateY(0);
            }
        }

        a {
            position: relative;
            z-index: 10;
        }

        &:after {
            position: absolute;
            top: auto;
            left: 0;
            bottom: 0;
            content: '';
            z-index: 9;

            width: 100%;
            height: 18rem;

            opacity: 1;

            pointer-events: none;

            transition: opacity 0.5s $ease-out-soft;
        }
    }
}
