@use 'sass:map';
@use 'sass:math';
@import '../variables';
@import '../base-bootstrap';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.giftcert {
    margin: $no-hero-margin-top-mobile auto $no-hero-margin-bottom-mobile;

    @include mq('medium') {
        margin-top: $no-hero-margin-top;
        margin-bottom: $no-hero-margin-bottom;
    }

    h1 {
        @include h3;
    }

    &-detail {
        width: 100%;
        padding: 0 $spacer * 3;

        @include mq('large') {
            margin: 0;
            padding: 0;

            background-color: $white-concrete;
        }

        &__wrap {
            padding: 0 0 $spacer * 5;

            text-align: center;

            border-bottom: 1px solid $grey-silver;

            @include mq('large') {
                margin: 0 auto;
                padding: $spacer * 8 0;

                text-align: left;

                border-bottom: 0;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            @include mq('large') {
                flex-direction: row;

                max-width: 66.6rem;
                margin: 0 auto;
            }

            &-text-wrapper {
                flex-basis: 100%;

                max-width: 37.4rem;
                margin: 0 auto;
            }

            &-title {
                &__mobile {
                    @include mq('large') {
                        display: none;
                    }
                }

                &__desktop {
                    display: none;

                    @include mq('large') {
                        display: block;
                    }
                }
            }

            &-text {
                @include mq('large') {
                    max-width: unset;
                    margin: $spacer * 2 0 0;
                }

                p {
                    @include p-sm;
                    font-weight: $font-weight-medium;
                }
            }

            &-wrap {
                flex-basis: 100%;
            }

            &-image {
                margin: $spacer * 2 auto;

                @include mq('large') {
                    display: flex;
                    align-items: center;

                    width: 29.2rem;
                    margin: 0;
                    padding-right: $spacer * 5;
                }

                img {
                    width: 100%;
                    max-width: 40rem;

                    @include mq('large') {
                        max-width: unset;
                    }
                }
            }
        }
    }

    &-form {
        margin: $spacer * 3 auto;

        font-size: map.get($body-desktop-sizes, 'p-sm');
        text-align: center;

        @include mq('large') {
            max-width: 1086px;
            margin: $spacer * 6 auto;
        }

        &__wrap {
            padding-bottom: $spacer * 5;

            border-bottom: 1px solid $grey-silver;

            @include mq('large') {
                padding-bottom: $spacer * 10;
            }

            &-row {
                display: flex;
                align-items: flex-start;

                max-width: 600px;
                margin: 0 auto !important;
            }

            form {
                text-align: left;

                .row {
                    margin: 0;
                }

                .col {
                    padding: 0;
                }
            }

            &-sectiontitle {
                @include font-family('medium');
                margin: $spacer * 3 0 $spacer;

                font-size: map.get($body-desktop-sizes, 'p-sm');
                text-transform: uppercase;
            }

            &--input-container {
                margin: 2.2rem 0 5rem;

                .custom-input {
                    margin: 0;
                }
            }
        }
    }

    &-checkbalance {
        margin-top: $spacer * 5;
        padding: 0 $spacer;

        text-align: center;

        &__title {
            @include font-family('medium');

            margin-bottom: math.div($spacer, 2);

            font-size: map.get($body-desktop-sizes, 'p-lg');
            line-height: 1;
        }

        &__content {
            line-height: 24px;
        }

        &__cta {
            @include font-family('demi');
            margin-top: $spacer * 2;
        }
    }

    .padding-right-sm {
        @include mq('large') {
            padding-right: $spacer * 10;
            padding-left: 0;
        }

        @include mq('xlarge') {
            padding-right: $spacer * 15;
        }
    }

    .padding-left-sm {
        @include mq('large') {
            padding-right: 0;
            padding-left: $spacer * 10;
        }

        @include mq('xlarge') {
            padding-left: $spacer * 15;
        }
    }

    .border-right-sm {
        @include mq('large') {
            border-right: 1px solid $grey-silver;
        }
    }

    .gift-cert-digital-button-wrap {
        margin-bottom: $spacer;

        text-align: center;

        @include mq('large') {
            text-align: left;
        }
    }

    .gift-cert-amount-currency {
        margin-right: math.div($spacer, 2);

        line-height: 2.25;
    }

    .gift-card-amounts-group {
        justify-content: flex-start;

        padding: 0;

        .gift-card-custom-radio {
            width: unset;
            padding: $spacer * 1.5;
        }
    }

    .gift-cert-terms-button {
        display: block;

        margin: $spacer * 2 auto 0;
    }
}
