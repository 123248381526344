$pull: left, right;

@mixin media($size) {
    @if $size == xs {
        @include media-breakpoint-up($size) {
            @content;
        }
    } @else {
        @include media-breakpoint-only($size) {
            @content;
        }
    }
}

@each $size in map-keys($grid-breakpoints) {
    @include media($size) {
        @each $side in $pull {
            .pull-#{$side}-#{$size} {
                float: $side;
            }
        }
    }
}

@each $bp in map-keys($grid-breakpoints) {
    .hidden-#{$bp}-down {
        @include media-breakpoint-down($bp) {
            display: none !important;
        }
    }

    .hidden-#{$bp}-up {
        @include media-breakpoint-up($bp) {
            display: none !important;
        }
    }
}

.row.equal-height > [class^='col'] {
    display: flex;
}

// Responsive utilities

// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack

@mixin responsive-visibility($parent) {
    #{$parent} {
        display: block !important;
    }

    table#{$parent} {
        display: table !important;
    }

    tr#{$parent} {
        display: table-row !important;
    }

    th#{$parent},
    td#{$parent} {
        display: table-cell !important;
    }
}

// [converter] $parent hack

@mixin responsive-invisibility($parent) {
    #{$parent} {
        display: none !important;
    }
}

// Print utilities

// Media queries are placed on the inside to be mixin-friendly.

// Note: Deprecated .visible-print as of v3.2.0

@include responsive-invisibility('.visible-print');

@media print {
    @include responsive-visibility('.visible-print');
}

.visible-print-block {
    display: none !important;

    @media print {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;

    @media print {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;

    @media print {
        display: inline-block !important;
    }
}

@media print {
    @include responsive-invisibility('.hidden-print');
}
