.icons {
    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__list {
        position: relative;

        display: flex;
        flex: 1 0 100%;
        flex-flow: row wrap;
        align-items: center;

        margin-bottom: $icon-space;
        padding: 2rem 3rem;

        // STATES

        &:last-child {
            margin-bottom: 0;
        }

        // ELEMENTS

        li {
            position: relative;

            flex: 1 0 25%;

            max-width: 12rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__list-badges {
        &--alt {
            background: $grey;
        }

        li {
            flex: 1 0 25%;

            max-width: none;

            text-align: center;

            a {
                &:hover {
                    transform: none;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    > span {
        flex: 0 0 100%;

        margin-bottom: 1.5rem;
    }
}

.icon {
    position: relative;

    width: 100%;
    padding-top: 100%;

    line-height: 0;

    cursor: pointer;

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    svg {
        position: absolute;
        top: calc(50% - 1.2rem);
        left: calc(50% - 1.2rem);

        width: 2.4rem;
        height: 2.4rem;
    }

    svg:not(.colored) {
        * {
            &:not(.colored) {
                fill: $black;
            }
        }
    }

    ////////////////////////////////////////////////////////////////
    // STATES

    ////////////////////////////////////////////////////////////////
    // VARIATIONS

    &__badge {
        padding-top: 50%;

        img {
            @include object-fit(contain);
            position: absolute;
            top: calc(50% - 4rem);
            left: calc(50% - 4rem);

            width: 8rem;
            height: 8rem;
        }
    }

    ////////////////////////////////////////////////////////////////
    // !! BAD BEM ELEMENTS

    &__flag {
        position: absolute;

        display: inline-block;

        margin-top: -0.1rem;
        padding-top: 0;

        svg,
        img {
            top: 0;
            left: 0;

            width: 1.7rem;
            height: 1.7rem;
            padding: 0.1rem;

            background-image: url('../images/vectors/circle.svg');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }

    &__payment {
        svg {
            top: calc(50% - 2.4rem);
            left: calc(50% - 2.4rem);

            width: 4.8rem;
            height: 4.8rem;
        }
    }

    &--search {
        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    &--shopping-bag {
        svg {
            width: 2rem;
            height: 2.4rem;
        }
    }

    &--burger {
        svg {
            width: 2rem;
            height: 1.2rem;
        }
    }

    &--chevron-down {
        svg {
            width: 1.7rem;
            height: 1.2rem;
        }
    }
}

// VARIATIONS

.is-dark {
    .icon {
        svg:not(.colored) {
            * {
                &:not(.colored) {
                    fill: $white;
                }
            }
        }
    }

    .is-light {
        .icon {
            svg:not(.colored) {
                * {
                    &:not(.colored) {
                        fill: $black;
                    }
                }
            }
        }
    }
}
