.pane-tab {
    @include p-sm;

    $this: &;

    position: relative;

    max-height: 4rem;
    margin-right: $spacer;
    padding: $spacer ($spacer * 2);

    color: $black;
    vertical-align: middle;

    border: none;
    border-radius: $spacer;

    background-color: $grey-white;

    backface-visibility: hidden;

    transition: transform $button-out-time $ease-hover;

    &__txt {
        display: block;

        max-width: 12em;

        white-space: nowrap;
        text-overflow: ellipsis;

        overflow: hidden;

        pointer-events: none;

        &--fifty {
            max-width: 24em;
        }
    }

    &:hover {
        color: $black;

        background-color: $grey-white;

        transform: scale(1.05);

        transition: transform $button-out-time $ease-hover;
    }

    &:active {
        color: $black !important;

        background-color: $grey-white !important;

        box-shadow: none !important;
    }

    &:focus {
        outline: 2px solid $blue-utility;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }

    &.light {
        color: $black;

        background-color: $grey-white;
    }

    &[aria-expanded='true'],
    &.light[aria-expanded='true'] {
        color: $white;

        background-color: $black;

        &:hover {
            color: $white;

            background-color: $black;
        }

        &:active {
            color: $white !important;

            background-color: $black !important;
        }
    }

    &.dark {
        color: $white;

        background-color: $black;

        &[aria-expanded='true'] {
            color: $black;

            background-color: $grey-white;

            &:hover {
                color: $black;

                background-color: $grey-white;
            }

            &:active {
                color: $black !important;

                background-color: $grey-white !important;
            }
        }
    }
}
