// from js/modules/callouts/callount-blurb/style.scss

.callout-blurb {
    $this: &;

    padding: 7.8rem 0;

    @include mq('medium') {
        padding: 21rem 0;
    }

    &__content {
        text-align: center;

        p {
            @include h4;

            margin: 0 auto;
            padding-bottom: 6.5rem;

            letter-spacing: -0.032rem;

            @include mq('medium') {
                padding-bottom: 9rem;
            }
        }
    }

    &--big-txt {
        @include mq('medium') {
            padding: 12.1rem 0;
        }

        #{$this}__content {
            p {
                @include h3;

                max-width: 73.5rem;

                @include mq('medium') {
                    @include h2;
                }
            }
        }
    }

    &.callouts--left-align {
        @include mq('medium') {
            padding: 9.2rem 0 10.2rem;
        }
    }
}
