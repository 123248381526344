@import './modules/navigation/navigation-subnav-tabs';

.modal.modal__content.modal__table {
    .table thead th {
        border-bottom: none;
    }

    .modal-content {
        max-width: 82.1rem;
    }

    .modal-body {
        width: 100%;
    }

    .navigation-subnav-tabs {
        margin-top: 0;

        background: transparent;

        @include media-breakpoint-down('md') {
            display: flex;
        }

        .nav {
            @include media-breakpoint-down('md') {
                padding-left: 0;
            }
        }

        .navigation-subnav-tabs__links-wrapper {
            padding: 0;

            @include media-breakpoint-down('md') {
                height: 4rem;
            }
        }
    }

    .navigation-subnav-tabs__links {
        @include media-breakpoint-down('medium') {
            width: 100%;
        }

        .nav-pills {
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;

                appearance: none;
            }

            .nav-link {
                color: $white;

                @include media-breakpoint-down('md') {
                    width: auto;
                }
            }
        }
    }

    .navigation-subnav-tabs__active-line {
        background: $white;
    }

    .navigation-subnav-tabs__bottom-line {
        background: $grey-dark;

        @include media-breakpoint-down('md') {
            width: 100% !important;
        }
    }
}
