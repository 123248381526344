@use 'sass:map';
@use 'sass:meta';

@mixin underline-styles {
    text-underline-offset: max(0.2rem, 0.3em);
    text-decoration-thickness: 0.1rem;
}

@mixin a() {
    @include underline-styles;
    position: relative;

    display: inline-block;

    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;

    text-decoration-line: underline;

    &:hover {
        text-decoration-line: none;
    }
}

@mixin p($args...) {
    $params: meta.keywords($args);

    font-size: map.get($body-mobile-sizes, p);
    letter-spacing: $body-letter-spacing;

    @if map.has-key($params, 'font') {
        $font: map.get($params, 'font');

        @include font-family($font);
    } @else {
        @include font-family('body');
        line-height: $body-line-height;
    }

    @include mq('large') {
        font-size: map.get($body-desktop-sizes, p);
    }

    :lang(ja) & {
        font-size: map.get($body-mobile-sizes-jp, p);
        line-height: $body-line-height * 1.1;

        @include mq('large') {
            font-size: map.get($body-desktop-sizes-jp, p);
        }
    }
}

@mixin p-sm($args...) {
    $params: meta.keywords($args);

    font-size: map.get($body-mobile-sizes, p-sm);
    letter-spacing: $body-letter-spacing;

    @if map.has-key($params, 'font') {
        $font: map.get($params, 'font');

        @include font-family($font);
    } @else {
        @include font-family('body');
        line-height: $body-line-height;
    }

    @include mq('large') {
        font-size: map.get($body-desktop-sizes, p-sm);
    }

    :lang(ja) & {
        font-size: map.get($body-mobile-sizes-jp, p-sm);
        line-height: $body-line-height * 1.1;

        @include mq('large') {
            font-size: map.get($body-desktop-sizes-jp, p-sm);
        }
    }
}

@mixin p-xs($args...) {
    $params: meta.keywords($args);

    font-size: map.get($body-mobile-sizes, p-xs);
    letter-spacing: $body-letter-spacing;

    @if map.has-key($params, 'font') {
        $font: map.get($params, 'font');

        @include font-family($font);
    } @else {
        @include font-family('body');
        line-height: $body-line-height;
    }

    @include mq('large') {
        font-size: map.get($body-desktop-sizes, p-xs);
    }

    :lang(ja) & {
        font-size: map.get($body-mobile-sizes-jp, p-xs);
        line-height: $body-line-height * 1.1;

        @include mq('large') {
            font-size: map.get($body-desktop-sizes-jp, p-xs);
        }
    }
}

@mixin p-lg($args...) {
    $params: meta.keywords($args);

    font-size: map.get($body-mobile-sizes, p-lg);
    letter-spacing: $body-letter-spacing;

    @if map.has-key($params, 'font') {
        $font: map.get($params, 'font');

        @include font-family($font);
    } @else {
        @include font-family('body');
        line-height: $body-line-height;
    }

    @include mq('large') {
        font-size: map.get($body-desktop-sizes, p-lg);
    }

    :lang(ja) & {
        font-size: map.get($body-mobile-sizes-jp, p-lg);
        line-height: $body-line-height * 1.1;

        @include mq('large') {
            font-size: map.get($body-desktop-sizes-jp, p-lg);
        }
    }
}

@mixin p-xl($args...) {
    $params: meta.keywords($args);

    font-size: map.get($body-mobile-sizes, p-xl);
    letter-spacing: $body-letter-spacing;

    @if map.has-key($params, 'font') {
        $font: map.get($params, 'font');

        @include font-family($font);
    } @else {
        @include font-family('body');
        line-height: $body-line-height;
    }

    @include mq('large') {
        font-size: map.get($body-desktop-sizes, p-xl);
    }

    :lang(ja) & {
        font-size: map.get($body-mobile-sizes-jp, p-xl);
        line-height: $body-line-height * 1.1;

        @include mq('large') {
            font-size: map.get($body-desktop-sizes-jp, p-xl);
        }
    }
}

// Helper Aliases
@mixin paragraph-xs($args...) {
    @include p-xs($args...);
}

@mixin p-s($args...) {
    @include p-sm($args...);
}

@mixin paragraph-s($args...) {
    @include p-sm($args...);
}

@mixin paragraph-sm($args...) {
    @include p-sm($args...);
}

@mixin paragraph($args...) {
    @include p($args...);
}

@mixin p-m($args...) {
    @include p($args...);
}

@mixin paragraph-m($args...) {
    @include p($args...);
}

@mixin p-l($args...) {
    @include p-lg($args...);
}

@mixin paragraph-l($args...) {
    @include p-lg($args...);
}

@mixin paragraph-lg($args...) {
    @include p-lg($args...);
}

@mixin paragraph-xl($args...) {
    @include p-xl($args...);
}
