.section-header {
    $this: &;

    padding: 0 $spacer * 7 $spacer * 2 $spacer * 2;

    color: var(--pat-theme-fore, $black);

    background-color: var(--pat-theme-back, transparent);

    @include mq('medium') {
        padding-right: $spacer * 5;
        padding-bottom: $spacer * 10;
        padding-left: $spacer * 5;
    }

    @include mq('large') {
        padding: 0 $spacer * 10 $spacer * 10 $spacer * 10;
    }

    &--fixed {
        #{$this}__wrapper {
            max-width: calc(70rem + ($spacer * 2));
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__wrapper {
        &--center {
            margin-right: auto;
            margin-left: auto;

            text-align: center;

            #{$this}__body {
                margin-right: auto;
                margin-left: auto;
            }

            #{$this}__cta-wrapper {
                justify-content: center;
            }
        }
    }

    &__headline {
        margin-bottom: $spacer;

        @include mq('large') {
            margin-bottom: $spacer * 2;
        }

        &--spacer {
            margin-bottom: $spacer * 1.5;

            @include mq('large') {
                margin-bottom: $spacer * 3;
            }
        }

        > * {
            font-weight: inherit;
            font-size: inherit;
            font-family: inherit;
            line-height: inherit;
            letter-spacing: inherit;
        }
    }

    &__body {
        max-width: 70rem;
    }

    &__cta-wrapper {
        display: flex;
        flex-flow: row wrap;
        gap: ($spacer * 2) $spacer;
        align-content: baseline;

        margin-top: $spacer * 3;

        @include mq('large') {
            margin-top: $spacer * 5;
        }
    }

    &__cta {
        @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
        min-width: 0;
    }
}
