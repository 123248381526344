.badge {
    display: inline-block;

    padding: 0 0.8rem;

    font-weight: $font-weight-bold;
    font-size: 1rem;
    line-height: 2.4rem;
    vertical-align: middle;

    border-radius: $border-radius-sm;

    &-pickup,
    &-backorder {
        color: $white;

        background-color: $red-brand;
    }

    &--generic {
        color: $black;

        background-color: $white;
    }

    &--faded {
        color: $black;

        background-color: $white;

        @include mq('large') {
            top: $spacer * 2;
            left: $spacer * 2;
        }
    }
}

.core-badge,
.product-tile .badge {
    padding: 0 0.6rem;

    border-radius: 0.4rem;
}

.marketing-tile,
.hero-main {
    .badge {
        margin-bottom: $spacer * 2;
    }

    &.is-light {
        .badge {
            color: $white;

            background-color: $black;
        }
    }
}

// routing tile badge
.card .badge {
    position: absolute;
    top: $spacer;
    left: $spacer;
    z-index: 10;

    @include mq('large') {
        top: $spacer * 2;
        left: $spacer * 2;
    }
}
