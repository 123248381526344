@import '~base/variables';
@import '../../variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/variables';
@import '~base/storeLocator/storeLocator';
@import '../../modules/modals/modal-instorepickup';
@import '../../app/locally';
@import './stores/availableStores';

.bopis-required {
    display: none;
}

.bopis-enabled {
    .bopis-required {
        display: block;
    }
}

.custom-input.store-search-radius {
    max-width: 11rem;

    li.selected:after {
        display: none;
    }
}

.store-availability-message {
    @include font-family('medium');
    margin-top: 7px;

    font-size: 1.2rem;

    .dot {
        display: inline-block;

        width: 8px;
        height: 8px;

        border-radius: 50%;

        background-color: #000;

        &.low {
            background-color: $yellow-utility;
        }

        &.in {
            background-color: $green-utility;
        }

        &.out {
            background-color: $red-utility;
        }
    }
}

.store-reset-date {
    color: #666;
    font-size: 1rem;
}

.store-stock-status {
    display: none;
}

.show-stock-status {
    .store-stock-status {
        display: block;
    }
}

.visi-only {
    .select-store {
        display: none;
    }
}
