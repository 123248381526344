// from bits/cta-progress/style.scss

@import '../variables';

.cta-progress {
    $this: &;

    position: absolute;
    top: auto;
    left: calc(50% - 6.9rem);
    bottom: 0;
    z-index: 100;

    width: 13.8rem;
    height: 0.4rem;

    border-radius: 3rem;

    overflow: hidden;

    transition: height 0.3s $ease-out-soft;

    @include mq('large') {
        left: auto;
        bottom: calc(100% - 2.7rem);
        right: 0;

        width: auto;
        height: 4.4rem;

        line-height: 4.4rem;

        overflow: visible;
    }

    &.is-expanded,
    &.is-end {
        height: 4.4rem;

        line-height: 4.4rem;

        transition:
            height 0.3s $ease-out-soft,
            line-height 0.3s $ease-out-soft,
            transform $button-out-time $ease-hover,
            scale 0.3s $ease-out-soft;

        #{$this}__head {
            transform: translateX(0) !important;
        }

        #{$this}__link {
            color: $white;
        }

        #{$this}__head-wrapper {
            height: 4.4rem;
        }
    }

    body.is-user-touch & {
        &.is-expanded,
        &.is-end {
            height: 0.4rem;

            line-height: 0.4rem;

            #{$this}__head-wrapper {
                height: 0.4rem;
            }
        }
    }

    &__head-wrapper {
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 0.4rem;

        border-radius: 3rem;

        background: rgba($black, 0.2);

        overflow: hidden;

        transition: height 0.3s $ease-out-soft;
    }

    &__head {
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 100%;

        border-radius: 3rem;

        background: $black;

        transform-origin: left center;

        overflow: hidden;

        transition: transform 0.3s $ease-out-soft;
    }

    &__text-all {
        @include font-family('medium');
        position: relative;

        display: inline-block;

        width: 100%;
        height: 0;
        padding: 0 2rem;

        font-size: 1.2rem;
        line-height: 0;
        text-align: center;

        cursor: pointer;

        opacity: 0;

        transition:
            transform $button-out-time $ease-hover,
            height 0.3s $ease-out-soft,
            line-height 0.3s $ease-out-soft,
            opacity 0.3s $ease-out-soft,
            scale 0.3s $ease-out-soft;

        @include mq('large') {
            height: auto;

            font-size: 1.6rem;
            line-height: 1;

            opacity: 1;
        }
    }

    &__link {
        position: relative;
        z-index: 1;

        display: block;

        color: $black;
        line-height: 4.4rem;

        transition: color 0.3s $ease-out-soft;

        &:hover {
            text-decoration-line: none;
        }
    }
}

.cta-progress-wrapper {
    position: relative;

    display: block;

    width: 100%;
    height: auto;

    overflow: hidden;
}
