.feature-pill {
    display: block;

    padding: 0 1.2rem;

    border-radius: 2rem;

    background-color: $grey-gallery;

    cursor: pointer;

    &:hover {
        background-color: $black;

        span {
            color: $white;
        }
    }

    span {
        @include font-family('medium');

        font-size: 1rem;
        line-height: 1.6rem;
        white-space: nowrap;
    }
}
