.cta-link {
    @include underline-styles;
    display: inline-block;

    margin: 0;

    color: #fff;
    font-weight: $font-weight-medium;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: 0;
    white-space: nowrap;

    cursor: pointer;
    text-decoration-line: none;

    &:hover,
    &:focus {
        text-decoration-line: underline;
    }

    &-light {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &-dark {
        color: $black;

        &:hover {
            color: $black;
        }
    }

    &.disabled {
        opacity: 0.3;

        pointer-events: none;
    }
}
