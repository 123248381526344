// from bits/cta-link-back/style.scss

.cta-link-back {
    @include underline-styles;
    position: relative;
    top: 3.5rem;

    display: flex;
    align-items: center;
    text-decoration-line: none;

    @include mq('medium') {
        top: 0;
    }

    ////////////////////////////////////////////////////////////////
    // STATES

    &:hover {
        text-decoration-line: underline;

        border: none;

        outline: none;

        span {
            text-decoration-line: underline;

            transform: scale(1.048) translateZ(0);

            transition: transform $button-out-time $ease-hover;
        }
    }

    ////////////////////////////////////////////////////////////////
    // VARIATIONS

    &.disabled {
        opacity: 0.3;

        pointer-events: none;
    }

    &.cta-link-back-light {
        figure {
            svg * {
                fill: $white !important;
            }
        }

        span {
            color: $white;
        }
    }

    &.is-left {
        figure ~ span {
            display: none;

            padding-left: 2.7rem;

            @include mq('medium') {
                display: inline-block;

                padding-left: 3.2rem;
            }
        }
    }

    &.is-right {
        figure ~ span {
            padding-right: 2.7rem;

            @include mq('medium') {
                padding-right: 3.2rem;
            }
        }
    }

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    span {
        position: relative;

        display: inline-block;

        margin-top: 0.7rem;

        color: $black;
        vertical-align: middle;

        backface-visibility: hidden;

        transition: transform $button-out-time $ease-hover;
    }

    figure {
        position: absolute;
        top: 0;
        left: 0;

        width: 4rem;
        height: 4rem;

        &.is-right {
            left: auto;
            right: 0;
        }

        svg {
            width: 3.5rem;
            height: 3.5rem;

            * {
                fill: $black !important;
            }
        }
    }
}

.cta-link-back-underlined {
    display: inline-block;

    padding-bottom: 0.9rem;

    color: inherit;
    line-height: 1;

    border-bottom: 2px solid $black;
    text-decoration-line: underline;

    span {
        line-height: 1;
    }

    &:hover {
        color: inherit;
        text-decoration-line: none;
    }
}
