.terms-modal {
    padding: 7rem 3rem;

    &__accordion {
        margin: 0;
        padding: 0;

        border: 1px solid $grey-silver;
        border-width: 1px 0 0;

        @include mq('large') {
            max-width: 760px;
            margin: 0 auto;

            border-width: 0;
        }

        &:last-child {
            border-bottom-width: 1px;

            @include mq('large') {
                border-width: 0;
            }
        }

        .accordion-content {
            padding-bottom: 20px;
        }

        ul,
        ol {
            margin: 0;
        }

        li {
            margin: 0;
            padding: 0;
        }

        h3 {
            @include font-family('medium');
            position: relative;

            margin: 20px 0;

            font-size: 16px;

            cursor: pointer;

            @include mq('large') {
                margin: 30px 0;

                font-size: 28px;

                cursor: auto;

                &:before,
                &:after {
                    display: none;
                }
            }

            &:before {
                position: absolute;
                top: 50%;
                right: 9px;

                width: 2px;
                height: 15px;

                background-color: $black;

                transform: translateY(-50%);
            }

            &:after {
                position: absolute;
                top: 50%;
                right: 3px;
                content: '';

                width: 15px;
                height: 2px;

                background-color: $black;

                transform: translateY(-50%);
            }

            &.closed:before {
                content: '';
            }
        }

        p {
            margin: 0 0 10px;

            font-size: 1.1rem;
        }
    }
}
