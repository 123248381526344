@use 'sass:string';

@mixin font-face($font-family, $file-path, $font-weight: null, $font-style: null) {
    @font-face {
        font-weight: $font-weight;
        font-family: $font-family;
        font-style: $font-style;
        src: local($font-family), url('#{$file-path}/#{$font-family}.eot');
        src:
            url('#{$file-path}/#{$font-family}.woff2') format('woff2'),
            url('#{$file-path}/#{$font-family}.woff') format('woff'),
            url('#{$file-path}/#{$font-family}.ttf') format('truetype'),
            url('#{$file-path}/#{$font-family}.svg#{$font-family}') format('svg'),
            url('#{$file-path}/#{$font-family}.eot?#iefix') format('embedded-opentype');
    }
}

@mixin font-family($type, $important: '') {
    @if $type == 'thin' or $type == 'light' {
        // light doesn't exist anymore, but kept for backwards compatibility
        font-weight: $font-weight-light #{$important};

        :lang(ja) & {
            font-family: var(--pata-font-sans-light-ja) #{$important};
            font-synthesis-weight: auto;
        }
    } @else if $type == 'regular' or $type == 'base' or $type == 'body' {
        font-weight: $font-weight-regular #{$important};

        :lang(ja) & {
            font-family: var(--pata-font-sans-regular-ja) #{$important};
            font-synthesis-weight: auto;
        }
    } @else if $type == 'medium' or $type == 'demi' {
        // demi doesn't exist anymore, but kept for backwards compatibility
        font-weight: $font-weight-medium #{$important};

        :lang(ja) & {
            font-weight: $font-weight-medium #{$important};
            font-family: var(--pata-font-sans-medium-ja) #{$important};
            font-synthesis-weight: auto;
        }
    } @else if string.index($type, 'heading') {
        font-weight: $font-weight-medium #{$important};

        :lang(ja) & {
            font-weight: $font-weight-bold #{$important};
            font-family: var(--pata-font-sans-bold-ja) #{$important};
            font-synthesis-weight: auto;
        }
    } @else if $type == 'medium-ja' {
        font-weight: var(--pata-font-sans-bold-ja) #{$important};
        font-synthesis-weight: auto;
    } @else if $type == 'bold' or $type == 'body-bold' {
        font-weight: $font-weight-bold #{$important};

        :lang(ja) & {
            font-family: var(--pata-font-sans-bold-ja) #{$important};
            font-synthesis-weight: auto;
        }
    } @else if $type == 'bold-ja' {
        font-weight: $font-weight-bold #{$important};
        font-family: var(--pata-font-sans-bold-ja) #{$important};
        font-synthesis-weight: auto;
    } @else if $type == 'heavy' {
        font-weight: $font-weight-heavy #{$important};

        :lang(ja) & {
            font-family: var(--pata-font-sans-bold-ja) #{$important};
            font-synthesis-weight: auto;
        }
    } @else if $type == 'secondary' or $type == 'special-sentinel' or $type == 'serif' {
        // sentinel doesn't exist anymore, but kept for backwards compatibility
        font-weight: $font-weight-medium #{$important};
        font-family: var(--pata-font-serif) #{$important};
        line-height: 1.4 #{$important};
        letter-spacing: $body-letter-spacing-serif #{$important};
        font-synthesis-weight: auto;
    } @else if $type == 'special-lusitana' or $type == 'special-copernicus' {
        // lusitana doesn't exist anymore, but kept for backwards compatibility
        font-weight: $font-weight-medium #{$important};
        font-family: var(--pata-font-serif) #{$important};
        font-synthesis-weight: auto;
    } @else if $type == 'sans-serif' or $type == 'sans' or $type == 'tertiary' {
        // tertiary doesn't exist anymore, but kept for backwards compatibility
        font-family: var(--pata-font-sans) #{$important};

        :lang(ja) & {
            font-family: var(--pata-font-sans-medium-ja) #{$important};
            font-synthesis-weight: auto;
        }
    } @else if $type == 'font-base' {
        font-family: var(--pata-font-sans) #{$important};
    } @else if $type == 'monospace' {
        font-family: var(--pata-font-monospace) #{$important};
    } @else {
        @warn "Unknown font-family: #{$type}";
    }
}
