// from js/modules/grid/grid-product-tiles-pdp-colors/style.scss
$product-tile-border-radius-mobile: 8px;
$product-tile-border-radius: 16px;

.grid-product-tiles-pdp-colors {
    overflow: visible;

    &:hover {
        .swiper-button-prev,
        .swiper-button-next {
            &:not(.swiper-button-disabled) {
                opacity: 1;
            }
        }
    }

    .row {
        flex-wrap: nowrap;
        justify-content: flex-start;

        @include mq('small') {
            flex-wrap: wrap;
        }
    }

    .swiper {
        max-width: 90rem;
        margin: 0 auto;

        overflow: visible;
    }

    .swiper-wrapper {
        width: auto;
        margin: 0.4rem -0.4rem 0;
        box-sizing: border-box;

        @include mq('small') {
            justify-content: center;
        }
    }

    .swiper-slide {
        flex-basis: calc(100% / 3);

        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        padding-right: 0.2rem;
        padding-left: 0.2rem;

        @include mq('medium') {
            padding-right: 0.4rem;
            padding-left: 0.4rem;
        }

        @include mq('small') {
            flex-basis: 12.5%; // 1/8

            max-width: 10.8rem;
        }

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ .swiper-slide {
            align-self: center;

            width: 50%;
        }

        &:first-child:nth-last-child(2) {
            margin-left: auto;
        }

        &:first-child:nth-last-child(2) ~ .swiper-slide {
            margin-right: auto;
        }

        &:last-child {
            position: relative;

            &:after {
                position: absolute;
                right: -2.2rem;
                content: '';

                display: block;

                width: 2.2rem;
                height: 1px;

                @include mq('small') {
                    display: none;
                }
            }
        }
    }

    // desktop slider styles

    &[data-desktop-slider] {
        .row {
            flex-wrap: nowrap;

            margin: 0;

            @include mq('small') {
                justify-content: center;
            }
        }

        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            flex: none;

            max-width: 10.8rem;
            padding: 0;
        }
    }

    // Overwrite for new PDP product tiles
    .product-tile--config {
        border-radius: $product-tile-border-radius;

        overflow: hidden;

        @include mq('small') {
            margin-bottom: 0;
        }

        @include mq('medium') {
            border-radius: $product-tile-border-radius;
        }

        &:after {
            border-radius: $product-tile-border-radius;
        }

        .product-tile__meta {
            display: none;

            visibility: hidden;
        }

        .product-tile__image-wrap {
            padding-top: calc(192 / 200 * 100%);
        }

        .color-value {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;

            border-radius: 16px;

            background-color: $grey-gallery;

            img {
                margin-top: 0;

                &.lazyload,
                &.lazyloading,
                &.ls-blur-up-img {
                    filter: blur(3px);
                }
            }
        }

        .product-tile__is-sale {
            @include font-family('medium');

            // display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;

            font-size: 0.9rem;
        }
    }
}
