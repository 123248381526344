// from js/modules/callouts/style.scss

// shared callout styles
.callouts {
    $this: &;
    position: relative;

    &__overlay {
        position: absolute;
        inset: 0;
        content: '';
        z-index: 0;

        background-color: rgba($black, 0.2);
    }

    &--left-align {
        .callout-blurb__content {
            // padding-right: 20px !important;
            text-align: left;

            @include mq('medium') {
                p {
                    @include h1;

                    // max-width: 86rem;
                }
            }
        }
    }

    &.is-after-grid {
        margin-top: 4.8rem;

        @include mq('medium') {
            margin-top: 9.6rem;
        }
    }
}
