/* Locally product availability overrides */

li.store-tile {
    &:last-of-type {
        margin-bottom: 0;
    }
}

.lcly-primary-trigger {
    display: none;
}

.lcly-dealers-wrap-outer {
    .lcly-dealers-wrap {
        margin: 0;
    }

    .lcly-dealer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        float: none;

        margin-bottom: 0;
        padding: 3.6rem 0;

        border-top: 2px solid $grey-silver;

        background-color: transparent;

        @include mq('small') {
            display: block;
        }

        &:hover {
            background-color: transparent;

            cursor: inherit;
        }

        &.lcly-dealer-n-0 {
            .tab-pane & {
                border-top: none;
            }
        }
    }

    .lcly-icon-marker {
        display: none;
    }

    .lcly-dealer-name {
        @include h6;

        width: 100%;

        color: $black;

        @include mq('large') {
            @include h8;
        }
    }

    .lcly-dealer-phone {
        display: block;

        margin: 1rem 0 0;

        line-height: 1.3333em;
    }

    .lcly-dealer-stock {
        display: none;
    }

    .lcly-icon-check-mark {
        display: none;
    }

    .lcly-dealer-distance {
        display: none;
    }

    .thing {
        display: none;
        float: right;

        width: auto !important;

        svg {
            width: 1.2rem;
            height: 1.2rem;
        }
    }
}

.store-locator-container {
    .results-card {
        .card-body {
            address.lcly-dealer-address {
                display: block;

                margin: 1rem 0 0;
                padding-bottom: 0;

                line-height: 1.3333em;
            }
        }
    }
}

.show-locally-buynow {
    .lcly-dealers-wrap-outer {
        .lcly-dealers-wrap {
            .thing {
                display: block;
                align-self: flex-end;

                @include mq('small', 'max') {
                    float: none;

                    margin-top: 2.4rem;

                    transform: none;

                    &:hover {
                        transform: translateY(0);
                    }
                }

                @include mq('small', 'min') {
                    margin-top: -3.2rem;
                }
            }
        }
    }
}
