$payment-card-bp: 400px;

.payment-cards-wrapper {
    margin-top: 10px;

    text-align: left;

    @media only screen and (min-width: $payment-card-bp) {
        position: absolute;
        top: 0;
        right: 0;

        text-align: right;
    }
}

.payment-card {
    display: inline-block;

    width: 45px;
    height: 28px;

    @media only screen and (max-width: $payment-card-bp) {
        width: 40px;
        height: 25px;
    }

    /* Alternate Sizes */
    &.md {
        width: 80px;
        height: 50px;
    }

    &.lg {
        width: 125px;
        height: 80px;
    }

    /* Default Style */
    svg {
        width: 100%;
        height: 100%;
    }

    /* Black & White */
    &.bw {
        svg {
            filter: grayscale(100%);
        }
    }
}
