.mega-text {
    margin-bottom: -112px;
    padding-bottom: 112px;

    color: var(--mt-foreground);

    background-color: var(--mt-background);

    @include mq('large') {
        margin-bottom: -210px;
        padding-bottom: 210px;
    }

    &--large {
        h2 {
            padding: ($spacer * 16.5) 0 ($spacer * 7.5);
        }
    }

    &--small {
        h2 {
            padding: ($spacer * 15) 0 ($spacer * 7.5);
        }
    }
}
