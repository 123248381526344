// from js/modules/grid/grid-product-tiles-overview/style.scss

.grid-product-tiles-overview {
    .row {
        flex-wrap: nowrap;

        @include mq('medium') {
            flex-wrap: wrap;
        }
    }

    .swiper {
        overflow: visible;

        @include mq('medium') {
            overflow: hidden;
        }
    }

    .swiper-wrapper {
        width: auto;
        margin-right: 0;
        margin-left: 0;
        box-sizing: inherit;

        @include mq('medium') {
            margin-right: calc(var(--bs-gutter-x) * -0.5);
            margin-left: calc(var(--bs-gutter-x) * -0.5);
        }
    }

    .swiper-slide {
        // margin-right: 8px;
        padding: 0;

        @include mq('medium') {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);

            // &.col-6 {
            // 	flex: 0 0 50%;
            // 	max-width: 50%;
            // }
        }
    }

    // desktop slider styles

    &[data-desktop-slider] {
        .row {
            flex-wrap: nowrap;

            margin: 0;
        }

        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            flex: none;

            max-width: none;
            padding: 0;
        }
    }
}
