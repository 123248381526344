@use 'sass:map';
@use 'sass:math';
@import '../utilities/swatch';
@import 'productCommon';
@import '../modules/modals/modal-add-to-bag';
@import '../modules/modals/modal-add-to-wishlist';
@import '../modules/modals/modal-quickadd-sizes';

$three-swatches-breakpoint: calc(
    (map.get($breakpoints, 'xsmall') + map.get($breakpoints, 'tiny')) / 2
);

.product-tile {
    $this: &; // reference to parent selector

    position: relative;

    transform: translateZ(0);
    backface-visibility: hidden;

    overflow: visible;

    a {
        text-align: left;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &:not(.btn) {
            &,
            section .themed.is-light & {
                color: $black;
            }

            .is-dark &,
            section .themed.is-dark & {
                color: $white;
            }
        }
    }

    img {
        content-visibility: auto;
    }

    &__image-wrap,
    &__color-wrap,
    &__video-wrap {
        position: relative;

        padding-top: 100%;

        border-radius: 0;

        background-color: $grey-yellow;

        overflow: hidden;

        // Safari bug to show rounded corners
        mask-image: radial-gradient($white, $black);

        @include mq('medium') {
            padding-top: (math.div(500, 415) * 100%);
        }

        &--foreground {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;

            width: 100%;

            transition:
                0.2s opacity ease,
                0s 0s z-index step-end;

            @include has-hover {
                #{$this}:hover & {
                    z-index: -1;

                    opacity: 0;

                    transition:
                        0.2s opacity ease,
                        0.2s 0s z-index step-end;
                }
            }
        }

        &:focus-within {
            outline: solid medium $blue-utility;

            a {
                outline: none;
            }
        }

        &:focus:not(:focus-within) {
            outline: none;
        }
    }

    .tile-image-bg-wrap {
        .grid-colorway-carousels .search-results & {
            @include mq('large', 'max') {
                position: relative;

                height: 0;
                padding-top: calc(328 / 263 * 100%);

                border-radius: $border-radius;

                background-color: $grey-yellow;

                overflow: hidden;

                // Safari bug to show rounded corners
                mask-image: radial-gradient($white, $black);

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    transform: translate(-50%, -50%);

                    transition:
                        opacity 0.75s $ease-out-soft,
                        filter $transition-time,
                        filter $transition-time;
                }
            }
        }
    }

    // Fix Zero Height Issue triggering Lazy Load
    &__image-container,
    &__video-container {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }

    &__video-container {
        video {
            width: 100%;
            height: 100%;

            object-fit: cover;
            object-position: center;
        }
    }

    &__cover-image {
        pointer-events: none;
    }

    &__image {
        @include is-absolute-fs;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        text-align: center;

        visibility: hidden;
        mix-blend-mode: darken;

        opacity: 0;
        transform: translateZ(0);

        .grid-colorway-carousels .search-results div.product-tile & {
            $md: map.get($grid-breakpoints, 'md');
            $md: map.get($grid-breakpoints, 'md');

            @include mq('large', 'max') {
                position: static;

                flex: 0 0 85%;

                margin-right: calc(var(--bs-gutter-x) * 0.5);

                visibility: visible;

                opacity: 1;
            }

            @include mq('medium') {
                margin-right: var(--bs-gutter-x);

                @include mq('large', 'max') {
                    // needed specialized query to mimic our module carousels and their bootstrap grid dimensions
                    flex: 0 0 45%;
                }
            }
        }

        img {
            display: block;

            width: 100%;
            height: auto;
            margin: 0 auto;

            mix-blend-mode: darken;

            transform-origin: center center;

            transition:
                transform 0.75s $ease-out-soft,
                opacity 0.75s $ease-out-soft,
                filter $transition-time,
                filter $transition-time;

            .grid-colorway-carousels .search-results & {
                @include mq('large', 'max') {
                    border-radius: $border-radius;

                    overflow: hidden;
                }
            }
        }

        &.active {
            visibility: visible;

            opacity: 1;
        }

        .buy-config & {
            position: absolute;

            visibility: visible;

            opacity: 1;

            img {
                width: 80%;

                transition:
                    opacity $transition-time $ease-out-soft,
                    transform $transition-time $ease-out-soft;

                @include mq('medium') {
                    width: 94%;
                }
            }
        }
    }

    &__image-overlay {
        position: relative;
        z-index: 1;

        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.32);

        transition: background-color $transition-time;
    }

    &__logo-badge {
        position: absolute;
        bottom: 4rem;
        right: 4rem;
        z-index: 2;

        @include mq('medium', 'max') {
            top: 1.2rem;
            left: 1.2rem;
        }

        a {
            &:hover {
                transform: none;
            }
        }

        img {
            width: 7.8rem;
            height: auto;

            transform: none !important;

            @include mq('medium', 'max') {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    &__right-badge {
        position: absolute;
        top: 2.4rem;
        right: 2.4rem;
        z-index: 2;

        @include mq('medium', 'max') {
            top: 1.2rem;
            right: 1.2rem;

            transform: scale(0.4);
            transform-origin: right top;
        }

        a {
            padding: 0 1.5rem;

            &:hover {
                transform: none;
            }
        }
    }

    &__meta {
        position: relative;

        padding: $spacer * 2;

        text-align: left;

        .grid-colorway-carousels .search-results div.product-tile & {
            @include mq('large', 'max') {
                order: 0;

                text-align: left;
            }
        }

        .grid-colorway-carousels & {
            @include mq('large', 'max') {
                padding: 1.6rem calc(var(--bs-gutter-x) * 0.5) 0;

                a {
                    text-align: left;
                }
            }
        }
    }

    &__meta-primary {
        transition:
            opacity $transition-time $ease-out-soft,
            transform $transition-time $ease-out-soft;

        @include mq('medium', 'max') {
            .color-swatches {
                position: relative;
                z-index: 1;

                padding-top: 0;
            }

            .swatch-next-link {
                height: 2.4rem;
                min-width: unset;

                span {
                    font-size: inherit;
                }
            }

            .cta-circle {
                &.cta-circle-sm {
                    position: relative;
                    z-index: 4;

                    width: 6vw;
                    height: 6vw;
                    min-width: 0;
                    max-width: 2.4rem;
                    max-height: 2.4rem;
                    margin: 0;

                    border: 1px solid rgba($white, 0.94);
                    border-radius: 6vw;
                }
            }

            #{$this}__bullet {
                padding-right: $spacer;
            }

            #{$this}__pagination {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-start;

                height: auto;
                max-height: 4rem;
                margin-top: 0;
                padding: 0 0 0.8rem;

                overflow: visible;
            }
        }

        .cta-circle {
            &.cta-circle-sm {
                svg.swatch-check {
                    fill: $grey-silver;

                    &.is-dark {
                        fill: $grey-silver;
                    }

                    &.is-light {
                        fill: $black;
                    }
                }
            }
        }

        h4 {
            position: relative; // ensuring that this heading doesn't hide on hover unintentionally
        }
    }

    &__name,
    &__meta,
    .tile-body-footer .form-check label {
        @include p-sm;
    }

    .price {
        .sales,
        .range,
        .starting {
            @include p-sm;
        }
    }

    &__name {
        position: relative;

        display: block;

        margin: 0;
        padding: 0 0.3rem 0.8rem 0;

        @include mq('large') {
            padding: 0 0.3rem 0.8rem 0;
        }

        .grid-colorway-carousels & {
            @include mq('large', 'max') {
                text-align: left;
            }
        }
    }

    &__price {
        display: inline-block;

        margin: 0;
        padding: 0 0.3rem 0.4rem 0;

        @include mq('medium', 'max') {
            display: block;
        }

        .is-dark &,
        section .themed.is-dark & {
            .price {
                color: rgba($white, 0.9);
            }
        }
    }

    &__ser {
        display: block;

        padding: 0.4rem 0.3rem 0.4rem 0;

        svg {
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 0.2rem;

            &,
            section .themed.is-light & {
                fill: $black;
            }

            .is-dark &,
            section .themed.is-dark & {
                fill: $white;
            }
        }
    }

    &__rating {
        @include p-xs;

        display: flex;

        &__stars-wrapper {
            position: relative;

            display: flex;
            align-items: center;

            margin-right: 0.4rem;
        }

        &__stars {
            display: flex;

            svg {
                flex: 0 0 1.2rem;

                width: 1.2rem;

                // adjust for star ratio
                height: (math.div(12, 13) * 1.2rem);
                margin-right: 0.15rem;
                margin-left: 0.15rem;

                &,
                section .themed.is-light & {
                    fill: $black;
                }

                .is-dark &,
                section .themed.is-dark & {
                    fill: $white;
                }
            }

            &--loading {
                svg {
                    fill: $grey-white;
                }
            }

            &--loading + .product-tile__rating__stars--filled {
                display: none;
            }

            &--empty {
                opacity: 0.4;
            }

            &--filled {
                position: absolute;
                top: 50%;
                left: 0;

                transform: translateY(-50%);

                overflow: hidden;
            }
        }
    }

    &__color-count {
        color: $grey-tundora;
    }

    &__colorway {
        display: block;

        padding: 0;
    }

    &__stat {
        display: block;

        padding: 0.2rem 0.3rem 0 0;

        @include mq('medium', 'max') {
            display: none;
        }

        &,
        section .themed.is-light & {
            color: rgba($black, 0.4);
        }

        .is-dark &,
        section .themed.is-dark & {
            color: rgba($white, 0.4);
        }
    }

    &__meta-secondary {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        opacity: 0;
        transform: translateY(5%);

        transition:
            opacity $transition-time $ease-out-soft,
            transform $transition-time $ease-out-soft;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;

            height: auto;
            padding: 0.55rem;
        }

        li {
            padding: 0.4rem;
        }

        .cta-circle.cta-circle-sm {
            width: 2rem;
            height: 2rem;
            min-width: 2rem;

            border-color: rgba($black, 0.1);
            border-radius: 2rem;

            &,
            section .themed.is-light & {
                border-color: rgba($black, 0.1);
            }

            svg * {
                fill: $grey-silver;
            }

            .is-dark &,
            section .themed.is-dark & {
                border-color: rgba($white, 0.15);
            }
        }
    }

    .swatches {
        .cta-circle.cta-circle-sm {
            border: 1px solid rgba($black, 0.1);
            border-radius: 2rem;

            &,
            section .themed.is-light & {
                border-color: rgba($black, 0.1);
            }

            .is-dark &,
            section .themed.is-dark & {
                border-color: rgba($white, 0.15);
            }
        }
    }

    &__mobile-meta {
        display: none;

        padding: 1.6rem calc(var(--bs-gutter-x) * 0.5) 0;

        .grid-colorway-carousels .search-results & {
            @include mq('large', 'max') {
                display: block;
            }
        }
    }

    img.ls-blur-up-img {
        z-index: 3;

        height: 100%;

        mix-blend-mode: normal;

        object-fit: contain;
        object-position: center;
    }

    &__cover {
        position: absolute;
        inset: 0;
        z-index: 2;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        opacity: 0;

        transition: opacity $transition-time $ease-out-soft;

        // To prevent alt images from loading on the page on non-hover capable devices, we must hide this using more than opacity
        @media ($no-hover-mq) {
            display: none;
        }

        a {
            display: block;

            height: 100%;

            img {
                position: relative;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                mix-blend-mode: darken;

                object-fit: cover;
                object-position: center;
            }

            .ls-blur-up-img {
                transform: translateY(-100%);
            }
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';

            width: 100%;
            height: 100%;

            background-color: $grey-yellow;

            pointer-events: none;
        }
    }

    @media ($no-hover-mq) {
        &__wishlist-container:not(.is-wishlist-grid-tile) {
            display: none;
        }
    }

    @media ($hover-mq) {
        &__wishlist-container:not(.added, .actively-adding),
        &__wishlist-container.remove {
            display: none;
        }

        &:hover {
            .product-tile__wishlist-container {
                display: block;
            }
        }
    }

    &__wishlist-container {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;

        padding: $spacer * 2;

        line-height: 0;

        svg {
            display: block;

            transition: transform $button-out-time $ease-hover;
        }

        .icon--heart-solid {
            display: none;
        }

        &.actively-adding,
        &:hover {
            svg {
                transform: scale(1.1);
            }
        }

        &.added {
            .icon--heart-outline {
                display: none;
            }

            .icon--heart-solid {
                display: block;
            }
        }
    }

    &__quickadd-container {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99;

        padding: ($spacer * 1.2) ($spacer * 2);

        background-color: rgba($white, 0.85);

        cursor: default;

        opacity: 0;

        @include mq('medium', 'max') {
            display: none;
        }

        > .btn-outlined.tile-quickadd-btn {
            &:hover,
            &:focus {
                color: var(--bs-btn-color);

                border-color: var(--bs-btn-bg) !important;

                background-color: var(--bs-btn-bg);
            }

            &:focus {
                outline: solid medium $blue-utility;
            }
        }

        &--mobile {
            position: absolute;
            bottom: 0;
            right: $spacer * 2;

            @include mq('medium') {
                right: $spacer * 2;
            }

            @include mq('medium') {
                display: none;
            }

            svg {
                pointer-events: none;
            }

            .btn-loading {
                svg {
                    opacity: 0;
                }
            }
        }

        .tile-quickadd-btn,
        .quickadd-atb-btn {
            width: 100%;

            text-align: center;

            &.btn-loading {
                color: transparent;

                background: $black !important;
            }

            &:hover {
                color: var(--bs-btn-color);

                background-color: var(--bs-btn-bg);
            }
        }

        &.show-size-active {
            .tile-quickadd-btn {
                display: none;
            }
        }

        .quickadd-atb-btn {
            margin-top: $spacer;
        }

        .size-container {
            margin: $spacer * -0.5;
        }

        .select-size {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            min-height: 5.6rem;

            > li {
                &.disabled,
                .disabled {
                    color: $grey-tundora;
                    text-decoration: line-through;
                    text-decoration-thickness: 2px;

                    cursor: default;
                }
            }
        }

        .product-quickadd {
            @include p-sm;

            &--oos-text {
                margin-top: $spacer;

                .text-link-sm {
                    @include underline-styles;
                    text-decoration-line: underline;
                }
            }
        }
    }

    &__wrapper-marketing {
        margin-bottom: $spacer * 6;
    }

    &__content {
        #{$this}__image {
            a {
                width: 100%;
            }
        }
    }

    &:hover {
        cursor: pointer;

        @include mq('medium') {
            #{$this}__meta-secondary {
                opacity: 1;
                transform: translateY(0);

                transition:
                    opacity $transition-time $ease-out-soft,
                    transform $transition-time $ease-out-soft;
            }

            #{$this}__meta-primary {
                opacity: 0;
                transform: translateY(-5%);

                &.swatch-alt {
                    opacity: 1;
                    transform: none;
                }
            }

            #{$this}__image {
                img {
                    transform: scale(1.0362);
                }
            }

            #{$this}__image-overlay {
                background-color: rgba(0, 0, 0, 0.2);

                transition: background-color $transition-time;
            }

            #{$this}__cover {
                opacity: 1;

                transition: opacity $transition-time $ease-out-soft;

                &.lazyloading {
                    opacity: 0.75;
                }
            }

            #{$this}__quickadd-container {
                opacity: 1;
            }
        }

        .badge {
            opacity: 0;
        }

        &.cursor-default,
        .skeleton & {
            cursor: default;
        }

        &.is-cover-hidden {
            #{$this}__cover {
                opacity: 0;
            }

            #{$this}__slide {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &:focus-within {
        @include mq('medium') {
            #{$this}__quickadd-container {
                opacity: 1;
            }
        }
    }

    .tile-body-footer {
        .form-check {
            margin: $spacer 0 0;

            @include mq('medium') {
                margin: ($spacer * 2) 0 0;
            }

            &-input {
                width: $form-check-input-width-mbl;
                height: $form-check-input-width-mbl;

                @include mq('medium') {
                    width: $form-check-input-width;
                    height: $form-check-input-width;
                }
            }

            label {
                vertical-align: middle;
            }
        }
    }

    /* TODO: Are any of these used? */

    // single view variation

    &--single {
        #{$this}__name,
        #{$this}__price {
            display: inline-block;

            margin: 0;

            @include mq('medium') {
                padding: 0 0.3rem;
            }
        }

        #{$this}__price {
            @include mq('medium', 'max') {
                display: block;

                padding: 0.25rem 0;
            }
        }

        #{$this}__stat {
            opacity: 0;
            transform: none;
        }

        &:hover {
            #{$this}__image {
                img {
                    transform: scale(1.0362);
                }
            }

            #{$this}__meta-primary {
                opacity: 1;
                transform: none;
            }

            #{$this}__meta-secondary {
                opacity: 0;
                transform: none;
            }
        }
    }

    // featured variation (background image)

    &--feat {
        margin-bottom: $spacer * 6;

        overflow: hidden;

        .product-tile__inner {
            position: relative;

            height: auto;
            padding-top: (math.div(500, 415) * 100%);

            border-radius: $border-radius;

            overflow: hidden;
        }

        .product-tile__image-wrap.in-grid,
        .product-tile__color-wrap.in-grid {
            padding-top: (math.div(500, 415) * 100%);
        }

        .product-tile__image-wrap.in-grid.is-full-width,
        .product-tile__color-wrap.in-grid.is-full-width {
            padding-top: calc(291 / 1187 * 100%);
        }

        #{$this}__icon {
            margin-bottom: 1.2rem;

            > svg {
                width: 4.4rem;
                height: 4.4rem;
                max-width: 15%;

                + svg {
                    margin-left: 2%;
                }

                * {
                    fill: $white;
                }
            }
        }

        #{$this}__meta-wrapper {
            display: flex;
            align-items: center;
        }

        #{$this}__meta-icon {
            margin-right: 0.4rem;

            > svg {
                width: 2rem;
                height: 2rem;

                fill: $white;
            }
        }

        &.is-center-aligned {
            #{$this}__meta {
                justify-content: center;
                align-items: center;

                text-align: center;

                #{$this}__meta-wrapper {
                    align-self: flex-start;

                    margin-top: auto;
                }
            }

            #{$this}__meta-primary {
                margin-top: auto;

                &.mt-initial {
                    margin-top: initial;
                }

                .card__svg {
                    margin: 0 auto;
                }
            }
        }

        &.is-left-aligned {
            #{$this}__meta {
                justify-content: flex-start;
                align-items: flex-start;

                text-align: left;
            }

            h4 {
                margin: 0 0 0.8rem;

                text-align: left;
            }
        }

        &.is-left-aligned--space-between {
            #{$this}__meta {
                h4 {
                    margin: 0 0 0.8rem;
                }
            }
            #{$this}__meta-primary {
                display: grid;
                grid-template-rows: auto 1fr 2fr;

                height: 100%;
            }

            h4 {
                text-align: left;
            }
        }

        &.is-left-aligned--top {
            #{$this}__meta {
                justify-content: flex-start;
            }

            #{$this}__meta-wrapper {
                margin-top: auto;
            }
        }

        &.is-left-aligned--bottom {
            #{$this}__meta {
                justify-content: flex-end;
            }

            #{$this}__meta-wrapper {
                margin-top: 2rem;
            }
        }

        #{$this}__image-wrap {
            height: auto;
            padding-top: 0;

            &.in-grid {
                .product-tile__image {
                    visibility: visible;

                    opacity: 1;
                }
            }

            img {
                @include object-fit(cover);
                width: 100%;
                height: 100%;

                transition:
                    transform 0.8s $ease-out-soft,
                    opacity 0.75s $ease-out-soft,
                    filter $transition-time,
                    filter $transition-time;
            }
        }

        #{$this}__name {
            margin-bottom: 0;
        }

        #{$this}__price {
            display: none;
        }

        #{$this}__meta {
            position: absolute;
            bottom: 0;
            right: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;
            padding: ($spacer * 2);
            box-sizing: border-box;

            color: $white;
            text-align: center;

            background-color: transparent;

            &:not(.no-hover):hover {
                h4 {
                    transform: translate3d(0, 0, 0);
                }

                .product-tile--feat__cta {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }

            .cta-circle {
                width: 4.4rem;
                margin-top: 1.6rem;
            }

            h4 {
                display: block;

                margin: 0 auto 0.8rem;

                color: inherit;
            }

            .product-tile--feat__cta {
                margin-top: 1.6rem;

                .cta-rounded-rect__heading {
                    width: auto;
                }
            }
        }

        #{$this}__meta-primary {
            opacity: 1;

            transition: none;
        }

        #{$this}__meta-secondary {
            display: none;
        }

        &:hover {
            @include mq('medium') {
                #{$this}__meta-primary {
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }

    // full image bg variation

    &--bg {
        &:hover {
            #{$this}__image {
                img {
                    transform: none;
                }
            }
        }

        #{$this}__meta {
            min-height: 8rem;
        }

        #{$this}__image {
            img {
                @include object-fit(cover);
                width: 100%;
                height: 100%;
            }
        }
    }

    // overlay variation
    &--overlay {
        &:hover {
            #{$this}__overlay-wrapper {
                opacity: 1;
            }

            #{$this}__image {
                img {
                    opacity: 0.4;
                    transform: none;
                }
            }
        }

        #{$this}__overlay-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            width: 100%;
            height: 100%;

            background-color: $black;

            opacity: 0;

            transition: opacity $transition-time $ease-out-soft;
        }

        #{$this}__overlay-content {
            span,
            img {
                display: block;

                margin: 0 auto;
            }

            img {
                margin-bottom: 3.5rem;

                @include mq('medium', 'max') {
                    width: 4rem;
                    margin-bottom: 1.5rem;
                }
            }

            span {
                padding: 5.7rem 4rem;

                color: $white;
                text-align: left;

                @include mq('medium', 'max') {
                    padding: 1.5rem;
                }
            }
        }
    }

    // all variation

    &--all {
        #{$this}__view-all {
            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);
        }

        &:hover {
            .cta-rounded-rect {
                transform: scale(1.05);

                transition: transform $button-out-time $ease-hover;
            }
        }
    }

    // cta variation
    &--cta {
        background-color: $white;

        #{$this}__inner {
            position: relative;

            padding-top: calc(500 / 412 * 100%);

            text-align: center;

            border-radius: $border-radius;

            overflow: hidden;

            // Safari bug to show rounded corners
            mask-image: radial-gradient($white, $black);
        }

        #{$this}__content {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;

            h3 {
                @include h2;

                max-width: 20rem;
                margin: 0 auto 4.5rem;

                @include mq('xlarge') {
                    @include h3;

                    max-width: 24rem;
                    margin: 0 auto 6.5rem;
                }
            }
        }
    }

    // config swatch variation
    &--config {
        border-radius: ($border-radius-sm);

        transition:
            transform 0.3s $ease-out-soft,
            box-shadow $button-out-time $ease-hover;

        &:hover {
            cursor: pointer;

            transform: scale(1.02);

            @include mq('medium', 'min') {
                box-shadow: 0 0.4rem 2rem rgba($black, 0.2);
            }

            &:before {
                opacity: 1;
            }
        }

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: 1;

            width: 100%;
            height: 100%;

            background-image: url('../images/vectors/check-pdp-swatch.svg');
            background-repeat: no-repeat;
            background-position: center center;

            opacity: 0;

            transition: opacity $button-out-time $ease-hover;
        }

        &.disabled {
            &.active {
                &:after {
                    opacity: 0.4;
                }
            }

            .color-value {
                img {
                    opacity: 0.2;
                }
            }
        }

        #{$this}__image-wrap {
            padding-top: (math.div(216, 164) * 100%);

            background-color: $grey-gallery;
        }

        #{$this}__image {
            justify-content: flex-start;

            img {
                width: 75%;
                margin-top: 2.4rem;

                mix-blend-mode: darken;

                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;

                @include mq('medium') {
                    width: 85%;
                }

                @include mq('large') {
                    width: 75%;
                }

                &.ls-blur-up-img {
                    top: 50%;
                    left: 50%;

                    height: auto;

                    transform: translate(-50%, -50%);
                }
            }
        }

        #{$this}__meta {
            position: absolute;
            left: 0;
            bottom: 0;

            width: 100%;
            height: auto;
            margin-bottom: 2.7rem;
            padding: 0;

            text-align: center;

            background-color: transparent;
        }

        #{$this}__name,
        #{$this}__price {
            display: block;

            margin: 0;
        }

        #{$this}__name {
            margin-bottom: 0.4rem;
        }

        &.active {
            &:after {
                opacity: 1;
            }
        }
    }

    .color-swatches {
        @include mq('large') {
            padding-top: 1em;
        }
    }

    .pdp-link a.link {
        display: block;

        margin-bottom: 6px;

        &:focus {
            border-radius: $border-radius;

            outline: solid medium $blue-utility;
            outline-offset: 3px;
        }
    }

    .badge {
        position: absolute;
        top: $spacer;
        left: $spacer;
        right: auto;

        color: $black;

        background-color: $white;

        pointer-events: none;

        @include mq('medium') {
            top: $spacer * 2;
            left: $spacer * 2;
        }

        &:not(.d-none) ~ .badge {
            display: none;
        }
    }

    .swatch-alt {
        .color-swatches {
            padding: 0 0 ($spacer * 2);

            #{$this}__pagination {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                padding-bottom: 0;
            }

            #{$this}__bullet {
                padding-right: 0;
                padding-left: $spacer;

                // Shows 5 swatches max on desktop; last list item is for View More
                @include mq('large', 'min') {
                    &:nth-of-type(1n + 6) {
                        // Hides 6th swatch onwards
                        display: none;
                    }
                }

                // Shows 4 swatches max on mobile; last list item is for View More
                @include mq('medium', 'max') {
                    &:nth-of-type(1n + 5) {
                        // Hides 5th swatch onwards
                        display: none;
                    }
                }

                &:first-child {
                    padding-left: 0;
                }
            }
        }

        .cta-circle.cta-circle-sm {
            width: 2.4rem;
            height: 2.4rem;
            min-width: 2.4rem;

            border: 2px solid $grey-tundora;

            svg {
                width: 0.8rem;
                height: 0.8rem;
            }

            &.split-swatch {
                svg {
                    &:first-child {
                        clip: rect(auto, 0.4rem, auto, auto);
                    }

                    &:last-child {
                        clip: rect(auto, auto, auto, 0.4rem);
                    }
                }
            }

            &:focus {
                outline: solid medium $blue-utility;
            }
        }

        #{$this}__view-more {
            display: none;

            &.show-m-2up-xs {
                display: block;

                @include mq($three-swatches-breakpoint, 'max') {
                    display: none;
                }
            }

            &.show-m-2up {
                display: block;

                @include mq('medium') {
                    display: none;
                }
            }

            &.show-desktop {
                @include mq('medium') {
                    display: block;
                }
            }

            .swatch-next-link {
                display: inline-flex;
                align-items: center;

                height: 2.4rem;
                min-width: 2.4rem;
                padding-left: $spacer;
            }
        }

        .color-price {
            display: none;

            &.active {
                display: inline;
            }
        }
    }

    .grid-product-tiles & {
        .swatch-alt {
            .color-swatches {
                #{$this}__bullet {
                    display: block;

                    // Shows 4 swatches max on mobile; last list item is for View More
                    @include mq('medium', 'max') {
                        &:nth-of-type(1n + 5) {
                            // Hides 5th swatch onwards
                            display: none;
                        }
                    }

                    // Shows 5 swatches max on desktop; last list item is for View More
                    @include mq('large', 'min') {
                        &:nth-of-type(1n + 6) {
                            // Hides 6th swatch onwards
                            display: none;
                        }
                    }
                }

                #{$this}__view-more {
                    display: none;

                    &.show-m-1up {
                        display: block;
                    }

                    &.show-m-1up,
                    &.show-m-2up,
                    &.show-m-2up-xs {
                        @include mq('medium') {
                            display: none;
                        }
                    }

                    &.show-desktop {
                        @include mq('medium') {
                            display: block;
                        }
                    }
                }
            }
        }

        .product-tile__quickadd-container--mobile {
            right: $spacer * 2;
        }
    }
}

// wornwear tile updates
.is-left-aligned--space-between {
    .product-tile--feat__cta {
        place-self: flex-end normal;
    }
}

.after-long-title {
    display: block;

    padding-top: 0;

    @include mq('medium') {
        padding-top: 1.25rem;
    }
}

.featured-products-carousel {
    .product-tile:hover .product-tile__meta-primary {
        opacity: 1;
    }

    .color-swatches,
    .cta-progress.is-expanded {
        display: none;
    }
}

.grid-colorway-carousels {
    @include mq('large', 'max') {
        .container .search-results-wrapper {
            position: relative;
            left: -2.4rem;

            width: calc(100% + 4.8rem);
            margin-right: -4.8rem;

            @include mq('medium') {
                left: calc(var(--bs-gutter-x) * -3);

                width: calc(100% + (var(--bs-gutter-x) * 6));
                margin-right: calc(var(--bs-gutter-x) * -6);
            }
        }

        .search-results {
            padding-right: 0;
            padding-left: 0;

            .search-results--no-decorator-title {
                padding: 0 $spacer * 6;

                text-align: left;

                @include mq('medium') {
                    padding: 0 7rem;
                }
            }

            .product-tile__meta-primary,
            .product-tile__image-container {
                padding-right: calc(var(--bs-gutter-x) * 2);
                padding-left: calc(var(--bs-gutter-x) * 2);

                @include mq('medium') {
                    padding-right: calc(var(--bs-gutter-x) * 3);
                    padding-left: calc(var(--bs-gutter-x) * 3);
                }
            }

            .product-tile__image-container {
                &:after {
                    content: '';

                    display: block;
                    flex: 0 0 2.4rem;

                    width: 2.4rem;

                    @include mq('medium') {
                        flex: 0 0 calc(var(--bs-gutter-x) * 3);

                        width: calc(var(--bs-gutter-x) * 3);
                    }
                }
            }
        }

        .grid-product-tiles__header {
            margin-bottom: 2rem !important;
        }

        .grid-product-tiles__header-inner {
            padding: 0 calc(var(--bs-gutter-x) * 0.5);
        }

        .grid-product-tiles-plp-carousel .swiper-slide {
            flex: 0 0 45%;

            max-width: 45%;

            @include mq('medium', 'max') {
                flex: 0 0 263px;

                max-width: 263px;
            }

            .product-tile__price {
                display: block;

                padding: 0.6rem 0 0;
            }

            .product-tile__stat {
                padding-top: 0.5rem;
            }

            .product-tile__name {
                white-space: nowrap;
                text-overflow: ellipsis;

                overflow: hidden;
            }
        }

        .grid-product-tiles__header-title,
        .grid-product-tiles-plp-carousel[data-desktop-slider] .grid-product-tiles__header-title {
            text-align: left;

            @include mq('medium', 'max') {
                align-items: flex-start !important;
            }

            span {
                @include h4;
            }
        }

        .grid-product-tiles__header-count {
            margin-top: 0.4rem;
        }

        .grid-product-tiles__header-subhead {
            margin-top: 0.7rem;

            text-align: left;

            opacity: 0.6;
        }
    }

    .product-grid {
        margin-bottom: 4rem;

        .product-tile__wrapper.col-6.col-lg-6 {
            @include mq('large', 'max') {
                flex: 0 0 100%;

                max-width: 100%;
            }
        }
    }

    .grid-product-tiles-plp-carousel {
        .swiper {
            margin-right: 0;
            margin-left: 0;
        }

        .container {
            @include mq('medium', 'max') {
                padding-right: var(--bs-gutter-x);
                padding-left: var(--bs-gutter-x);
            }

            .swiper-slide {
                margin-right: calc(var(--bs-gutter-x) * 0.5);
                padding-right: 0;
                padding-left: 0;
            }
        }

        .swiper-button-prev {
            @include mq('medium', 'max') {
                left: 0 !important;
            }
        }

        .swiper-button-next {
            @include mq('medium', 'max') {
                right: 0 !important;
            }
        }
    }

    .load-more-present .product-tile-gradient {
        margin-bottom: 0;

        &:after {
            display: none;
        }
    }

    .grid-footer:not(.content-grid-footer),
    .show-more {
        position: static !important;
    }

    .show-more {
        margin-top: 4rem;
    }
}
