// scroll hooks

.no-scroll,
.is-overflow-hidden {
    overflow: hidden;
}

body.no-scroll {
    position: fixed !important;

    height: 100%;
}

// visibility hooks

.is-transparent {
    opacity: 0 !important;
}

.is-invisible {
    visibility: hidden !important;
}

.is-display-none {
    display: none !important;
}

// position

.is-fixed {
    position: fixed !important;
}

// responsive

.is-mobile-only {
    @include mq('medium') {
        display: none !important;
    }
}

.is-desktop-only {
    @include mq('medium', 'max') {
        display: none !important;
    }
}

// object-fit

.is-object-position-left-top,
.is-object-position-left-top + .ls-blur-up-img {
    transform-origin: 0% 0% !important;

    object-position: 0% 0% !important;
}

.is-object-position-left-center,
.is-object-position-left-center + .ls-blur-up-img {
    transform-origin: 0% center !important;

    object-position: 0% center !important;
}

.is-object-position-left-bottom,
.is-object-position-left-bottom + .ls-blur-up-img {
    transform-origin: 0% 100% !important;

    object-position: 0% 100% !important;
}

.is-object-position-center-top,
.is-object-position-center-top + .ls-blur-up-img {
    transform-origin: center 0% !important;

    object-position: center 0% !important;
}

.is-object-position-center-center,
.is-object-position-center-center + .ls-blur-up-img {
    transform-origin: center center !important;

    object-position: center center !important;
}

.is-object-position-center-bottom,
.is-object-position-center-bottom + .ls-blur-up-img {
    transform-origin: center 1000% !important;

    object-position: center 100% !important;
}

.is-object-position-right-top,
.is-object-position-right-top + .ls-blur-up-img {
    transform-origin: 100% 0% !important;

    object-position: 100% 0% !important;
}

.is-object-position-right-center,
.is-object-position-right-center + .ls-blur-up-img {
    transform-origin: 100% center !important;

    object-position: 100% center !important;
}

.is-object-position-right-bottom,
.is-object-position-right-bottom + .ls-blur-up-img {
    transform-origin: 100% 100% !important;

    object-position: 100% 100% !important;
}

// background position

.is-background-position-left-top {
    background-position: 0% 0% !important;

    transform-origin: 0% 0% !important;
}

.is-background-position-left-center {
    background-position: 0% center !important;

    transform-origin: 0% center !important;
}

.is-background-position-left-bottom {
    background-position: 0% 100% !important;

    transform-origin: 0% 100% !important;
}

.is-background-position-center-top {
    background-position: center 0% !important;

    transform-origin: center 0% !important;

    img {
        opacity: 0;
    }
}

.is-background-position-center-center {
    background-position: center center !important;

    transform-origin: center center !important;
}

.is-background-position-center-bottom {
    background-position: center 100% !important;

    transform-origin: center 100% !important;
}

.is-background-position-right-top {
    background-position: 100% 0% !important;

    transform-origin: 100% 0% !important;
}

.is-background-position-right-center {
    background-position: 100% center !important;

    transform-origin: 100% center !important;
}

.is-background-position-right-bottom {
    background-position: 100% 100% !important;

    transform-origin: 100% 100% !important;
}

.is-object-fit-cover,
.is-object-fit-full {
    &,
    + .ls-blur-up-img {
        @include object-fit(cover);
    }
}

.is-object-fit-contain {
    &,
    + .ls-blur-up-img {
        @include object-fit(contain);
    }
}

.disabled-element,
.disabled-element * {
    cursor: progress !important;

    pointer-events: none !important;
    touch-action: none !important;
}
