// from modules/sliders/style.scss

// shared sliders styles
.slider {
    overflow-x: hidden;

    &--l-grey {
        background-color: $white-alabaster;
    }

    &--l-grey-white {
        background-color: $grey-white;
    }

    &--l-black {
        background-color: $black;
    }

    &--l-purp {
        color: $white;

        background-color: #6a8ce9;

        .slider__sidebar {
            background-color: #6a8ce9;
        }
    }

    &--l-transparent {
        background-color: transparent;
    }

    &__sidebar {
        z-index: 2;
    }

    &.is-after-grid {
        margin-top: 9.6rem;
    }
}

.swiper {
    .swiper-button-prev,
    .swiper-button-next {
        figure:has(.icon--chevron-slider) {
            padding: 2.2rem;

            @include mq('medium', 'max') {
                padding: 1rem;
            }
        }
    }
}

.swiper-button-prev .icon--chevron-slider {
    transform: translate(-5%);
}

.swiper-button-next .icon--chevron-slider {
    transform: translate(5%);
}
