.modal-giftcert-checkbalance {
    .check-balance {
        .gift-cert-balance-check-submit {
            &__button {
                width: 100%;
                margin-top: $spacer;
            }
        }

        &__form {
            &.deactive {
                display: none;
            }

            .error {
                display: block;

                margin: $spacer * 3 0 0;

                color: $red-utility;
                text-align: center;
            }
        }

        &__amount-wrap {
            display: none;

            text-align: center;

            &.active {
                display: block;
            }
        }

        &__amount {
            @include font-family('medium');
            margin: $spacer * 2 0;

            font-size: 20px;
        }

        &__secondary {
            padding-top: $spacer * 2;

            @include mq('large') {
                margin-top: $spacer * 4;
                padding-top: $spacer * 4;

                border-top: 1px solid $grey-silver;
            }

            &-content {
                display: none;

                margin-bottom: 0;
                padding: 0;

                text-align: center;

                @include mq('large') {
                    display: block;

                    margin: 0 auto;
                    padding: 0 $spacer * 2;

                    text-align: left;
                }

                // From Content Asset
                strong,
                h3 {
                    @include font-family('medium');
                    margin: 0;

                    font-size: 20px;
                }
            }
        }

        &__toggle-link {
            @include font-family('medium');
            margin-bottom: 0;

            font-size: 16px;
            text-align: center;

            @include mq('large') {
                display: none;
            }

            a {
                color: #999;
            }
        }
    }
}
