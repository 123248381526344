// from js/modules/grid/grid-product-tiles-plp-default/style.scss

.grid-product-tiles-plp-default {
    .row {
        flex-wrap: nowrap;

        @include mq('medium') {
            flex-wrap: wrap;
        }
    }

    .swiper {
        overflow: visible;

        @include mq('medium') {
            overflow: hidden;
        }
    }

    .swiper-wrapper {
        width: auto;
        margin-right: 0;
        margin-left: 0;
        box-sizing: inherit;
    }

    .swiper-slide {
        // margin-right: 8px;
        // height: auto;
        padding: 0;

        @include mq('medium') {
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: calc(var(--bs-gutter-x) * 0.5);

            // &.col-6 {
            // 	flex: 0 0 50%;
            // 	max-width: 50%;
            // }
        }
    }

    // desktop slider styles

    &[data-desktop-slider] {
        .row {
            flex-wrap: nowrap;

            margin: 0;
        }

        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            flex: none;

            max-width: none;
            padding: 0;
        }
    }

    .grid-product-tiles__header {
        display: none;
    }

    &[data-no-slider] {
        @include mq('medium', 'max') {
            .row {
                flex-wrap: wrap;

                margin-right: -4px;
                margin-left: -4px;
            }

            .swiper-slide {
                padding-right: 4px;
                padding-left: 4px;
            }
        }

        .grid-product-tiles__header {
            display: none;
        }
    }
}
