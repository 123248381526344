#onetrust-accept-btn-handler {
    @include font-family('medium', '!important');
    padding: 0 3.2rem !important;

    font-size: 1.6rem !important;
    line-height: 3.6rem !important;
    white-space: nowrap !important;
    text-align: center !important;
    vertical-align: middle !important;

    border: 0.4rem solid transparent !important;
    border-radius: 3rem !important;
}

#onetrust-group-container {
    width: 100% !important;
}

#onetrust-policy {
    margin: 3.75em 10% 0.625em !important;
}

#onetrust-policy-title {
    @include font-family('medium', '!important');
    margin-bottom: 30px !important;

    font-size: 2.4rem !important;
}

#onetrust-policy-text {
    @include font-family('light', '!important');
    font-size: 1.667rem !important;
}

#onetrust-button-group-parent {
    position: relative !important;
    top: initial !important;
    left: initial !important;

    width: 100% !important;

    transform: none !important;
}

#onetrust-button-group {
    float: right !important;

    width: auto !important;
    margin: 0 !important;
}

#onetrust-button-group button {
    width: auto !important;
}
