@mixin toast-message() {
    position: fixed;
    top: 15%;
    left: 50%;

    transform: translate(-50%, -50%);
}

@mixin toast-alert() {
    padding: 1em;

    box-shadow: 1px 1px 5px $grey4;

    animation: fade 5s linear forwards;

    @keyframes fade {
        0% {
            opacity: 0;
        }

        10% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    &.show {
        display: block;
    }
}
