// Accordion overrides
.accordion-button {
    justify-content: space-between;

    font-weight: $font-weight-medium;
    font-size: 14px;

    &:not(.collapsed) {
        box-shadow: none;
    }

    &::after {
        margin-left: 2rem;

        transform-origin: center;
    }

    &:focus {
        border-radius: 20px !important;

        outline: solid medium $blue-utility;
        outline-offset: -5px;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }
}

.accordion-item {
    padding: 3rem 1.25rem;

    border-right: 0;
    border-left: 0;
}

.accordion-toggle {
    @include font-family('medium');
    position: relative;

    padding-right: 2.4rem;

    cursor: pointer;

    .icon--chevron-down {
        position: absolute;
        top: 50%;
        right: 0;

        width: 17px;
        padding: 0;

        &.is-expanded svg {
            transform: rotate(180deg);
        }
    }

    &:focus {
        border-radius: $border-radius;

        outline: solid medium $blue-utility;
        outline-offset: 3px;
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }
}

.accordion-content {
    padding-top: 2rem;
    padding-right: var(--bs-gutter-x);

    &.collapse {
        &:not(.show) {
            display: none;
        }
    }

    &.collapsing {
        @include transition($transition-collapse);
        height: 0;

        overflow: hidden;
    }
}

.accordion-group {
    padding: 3rem 0;

    border: 2px solid $black;
    border-width: 2px 0 0;

    &:last-child {
        border-bottom: 2px solid $black;
    }
}

.is-dark {
    .accordion-item {
        border-color: $white;
    }

    .accordion-button {
        &::after {
            background-image: var(--chevron-down-small-white);
        }
    }

    .accordion-button:not(.collapsed) {
        color: $white;

        &::after {
            background-image: var(--chevron-down-small-white);
        }
    }
}
