.sign-in-modal {
    &#signInModal,
    &#signInModalPro {
        .modal-body {
            @include mq('large') {
                margin-bottom: 0;
            }

            .password__help {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .login {
            width: 100%;
            margin: 0;
            padding: 0;

            &--create-prompt {
                display: none;
            }

            &--title {
                font-size: 2.5em;
            }

            &--subhead {
                margin: 1rem 0 3rem;

                @include mq('large') {
                    margin-bottom: 4.5rem;

                    line-height: 1.5;
                }
            }
        }

        .login-submit {
            @include mq('large') {
                width: 20rem;
            }
        }

        .register-prompt {
            display: block;
        }

        .row {
            width: 100%;
            margin: 0;
        }
    }
}
