@use 'sass:map';

.header-tabs {
    position: absolute;
    top: 0;
    left: calc(var(--bs-gutter-x) * 2);
    z-index: (map.get($z-indexes, 'sticky') + 2);

    display: none;
    justify-content: flex-start;
    align-items: center;

    width: auto;
    height: var(--pat-utility-bar-height);
    margin: 0;
    padding: 0;

    list-style: none;

    @include mq('large') {
        left: calc(var(--bs-gutter-x) * 4);

        display: flex;
    }

    @include mq('xlarge') {
        left: calc(var(--bs-gutter-x) * 5);
    }

    &__link {
        margin-right: 1.6rem;

        a {
            font-weight: $font-weight-normal;

            &.tab-active {
                font-weight: $font-weight-bold;

                &:after {
                    content: '';

                    display: block;

                    width: 0;
                    height: 1px;

                    border-bottom: 1px solid $white;

                    animation: fillBorder 200ms ease-in 1 forwards;
                }
            }
        }
    }

    .navigation-primary.is-expanded & {
        display: block;

        opacity: 0;

        animation: none;
    }
}

// animation
@keyframes fillBorder {
    0% {
        width: 0;
    }

    20% {
        width: 40%;
    }

    40% {
        width: 40%;
    }

    60% {
        width: 60%;
    }

    80% {
        width: 80%;
    }

    100% {
        width: 100%;
    }
}
