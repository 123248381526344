@import './../../base-bootstrap';

// from modules/mixed-layouts/style.scss

// shared mixed layout styles

.layout-hl-w-btns-group {
    padding: 8.4rem 0;

    background-color: #f8f8f8;

    .layout-hl-w-btns:first-of-type {
        margin-bottom: 0.8rem;
    }
}

.layout-slider-group {
    padding: 6.4rem 0;

    @include mq('medium') {
        padding: 0 0 8.4rem;
    }

    .grid-product-tiles-container {
        + .grid-product-tiles-container {
            /* stylelint-disable-line */
            margin-top: 4.1rem;

            @include mq('medium') {
                margin-top: 13.9rem;
            }
        }
    }

    .grid-product-tiles__header {
        min-height: 2.6rem;
        margin-top: 0;
        margin-right: 2.5rem;
        margin-left: 2.5rem;

        @include mq('medium') {
            margin-right: 5rem;
            margin-left: 5rem;
        }

        &:not(.grid-product-tiles__header--centered) {
            .grid-product-tiles__header-title {
                @include mq('medium') {
                    @include h1;
                }
            }
        }
    }

    .product-tile__image-wrap {
        background-color: #efefef;
    }

    .product-tile__image {
        img {
            mix-blend-mode: normal;
        }
    }

    .product-tile__meta {
        background-color: transparent;
    }

    .cta-rounded-rect.is-outlined .cta-rounded-rect__bg {
        background: transparent;
    }
}

.intro-text {
    $btn-horz-margin: $grid-gutter-width;

    background-color: var(--intro-text-background, transparent);

    &__cta-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        width: calc(100% + #{$btn-horz-margin});
        margin: 1rem ($btn-horz-margin * -0.5) 0;

        .cta-link-underline {
            padding: 0 2rem 1.2rem 1rem;

            @include mq('medium', 'max') {
                display: inline-block;
            }
        }

        > .btn,
        > [data-pd-component-type] {
            margin: 1.5rem ($btn-horz-margin * 0.5) 0;

            @include mq('medium', 'max') {
                justify-content: space-evenly;

                min-width: calc(50% - 0.8rem);
                margin: 0.8rem auto;
            }
        }
    }
}
