@use 'sass:map';

.navigation-subnav-tabs {
    $this: &;

    position: relative;
    top: 0;
    left: 0;
    z-index: 0;

    display: none;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin-top: 7rem;

    background: $white;

    transform: translateZ(0);

    overflow: hidden;

    @include mq('large') {
        display: block;

        margin-top: 12rem;
    }

    ////////////////////////////////////////////////////////////////
    // STATES

    &.is-sticky {
        position: sticky;
        z-index: map.get($z-indexes, 'sticky');
    }

    &.is-ui-showing {
        #{$this}__cta-wrapper,
        #{$this}__heading {
            visibility: visible;

            opacity: 1;
        }
    }

    &.is-link-active {
        #{$this}__active-line {
            opacity: 1;
        }
    }

    &.is-shown {
        opacity: 1;
        transform: none;

        transition:
            opacity 0.3s $ease-out-soft,
            transform 0.3s $ease-out-soft;
    }

    &.is-hidden {
        opacity: 0;
        transform: translateY(-100%);

        transition:
            opacity 0.3s $ease-out-soft,
            transform 0.3s $ease-out-soft;
    }

    &__links {
        @include mq('large', 'max') {
            margin-right: 0;
            margin-left: 0;
        }
    }

    &__links-wrapper {
        height: 8rem;
        padding-right: 0;
        padding-left: 0;

        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        @include mq('medium') {
            padding-right: calc(var(--bs-gutter-x) * 3);
            padding-left: calc(var(--bs-gutter-x) * 3);
        }

        @include mq('large') {
            overflow-x: hidden;
        }
    }

    &__active-line,
    &__bottom-line {
        position: absolute;
        top: auto;
        left: 0;
        bottom: 0;

        height: 0.4rem;

        @include mq('large', 'max') {
            left: 0;
        }
    }

    &__active-line {
        left: 0;
        z-index: 1;

        width: 10rem;

        border-radius: $border-radius-sm;

        background: $black;

        opacity: 1;
        transform-origin: 0% 50%;

        transition:
            opacity 0.2s $ease-out-soft,
            transform 0.16s $ease-out-dynamic;
    }

    &__bottom-line {
        width: 100%;

        background: $grey-gallery;

        transition: transform 0.16s $ease-out-dynamic;

        @include mq('large', 'max') {
            transform: none !important;
        }
    }

    &__fpc-btn {
        position: absolute;
        top: 50%;
        left: 8rem;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        opacity: 0;
        transform: translateY(-50%);

        transition: opacity 0.3s $ease-out-soft;

        @include mq('xlarge', 'max') {
            left: 4rem;
        }

        &.is-shown {
            opacity: 1;
        }

        &:hover {
            #{$this}__fpc-hamburger {
                span {
                    transform: translate(0);
                }
            }
        }
    }

    &__fpc-hamburger {
        width: 2rem;
        height: auto;
        margin-right: 1rem;

        overflow: hidden;

        span + span {
            margin-top: 0.3rem;
        }

        span {
            display: block;

            width: 100%;
            height: 0.3rem;

            background: $black;

            transition: transform 0.3s $ease-out-soft;

            &:nth-child(1) {
                transform: translate(-40%);
            }

            &:nth-child(3) {
                transform: translate(-20%);
            }
        }
    }

    .nav {
        position: relative;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        height: inherit;
    }

    .nav-item {
        position: relative;

        display: block;
        flex: 1 0 auto;

        height: inherit;
        min-width: 28vw;
        margin: 0;

        @include mq('medium') {
            min-width: initial;
        }
    }

    .nav-link {
        position: relative;

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        height: inherit;

        color: $black;
        text-decoration: none;

        &:hover {
            text-decoration: none;

            span {
                transform: translateY(-0.4rem);

                @include mq('large', 'max') {
                    transform: none;
                }
            }
        }

        span {
            transition: transform 0.16s $ease-out-soft;

            @include mq('large', 'max') {
                @include h8;
            }
        }

        &.active {
            background-color: transparent;
        }
    }
}

.navigation-subnav-tabs-content {
    padding: $spacer * 2 0;

    @include mq('medium') {
        padding: $spacer * 3 0;
    }

    .fade {
        transform: translateY(2rem);

        transition:
            transform 0.25s $ease-out-soft,
            opacity 0.25s $ease-out-soft;

        &.show {
            transform: none;

            transition:
                transform 0.5s $ease-out-soft,
                opacity 0.5s $ease-out-soft;
        }
    }

    .tab-content {
        margin: 8rem 0;
    }

    .tab-pane {
        margin-right: 0;
        margin-left: 0;

        @include mq('xlarge') {
            margin-right: calc(25% / 3 - (var(--bs-gutter-x) * 0.5));
            margin-left: calc(25% / 3 - (var(--bs-gutter-x) * 0.5));
        }
    }

    .tab-pane-load-more {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
    }
}

.navigation-subnav-tabs-footer {
    display: block;

    margin-bottom: 8rem;
    padding: 1rem 0;

    @include mq('large') {
        display: none;
    }

    &__heading {
        margin-bottom: 3.5rem;
    }

    .nav-item {
        flex: 0 0 auto;

        margin-right: 0.8rem;

        &.is-hidden {
            display: none;
        }
    }

    .btn {
        padding: 0 1.5rem;
    }
}
