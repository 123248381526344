.info-icon {
    .popover {
        padding: 0.5rem 1rem;

        font-size: 14px;

        &::after {
            bottom: -0.6em;
        }
    }
}

.popover {
    a {
        color: $popover-color;
    }

    .popover-inner {
        text-align: left;
    }

    .icon {
        padding: 0;

        cursor: unset;

        &--ser--quality {
            width: 2.4rem;
            height: 2.4rem;

            background: var(--ser--quality-white) no-repeat center / 70%;
        }

        &--arrow-expand {
            display: inline-block;

            width: 2.4rem;
            height: 2.4rem;

            vertical-align: middle;

            background: var(--arrow-expand-white) no-repeat center / 50%;
        }
    }

    .text-link-sm {
        display: inline-block;

        margin-bottom: $spacer * 0.5;

        @include underline-styles;
        text-decoration-line: underline;
    }

    .is-dark & {
        .popover-arrow::before {
            border-bottom-color: $white;
        }

        .popover-inner {
            color: $black;

            background-color: $white;

            a {
                color: $black;
            }
        }

        .icon {
            &--ser--quality {
                background: var(--ser--quality) no-repeat center / contain;
            }

            &--arrow-expand {
                background: var(--arrow-expand) no-repeat center / 50%;
            }
        }
    }
}
