// js/modules/grid/style.scss

// global container styles

@import './grid-product-tiles';
@import './grid-product-tiles-overview';
@import './grid-product-tiles-pdp-colors';
@import './grid-product-tiles-plp-default';

body {
    --#{$variable-prefix}gutter-x: #{$grid-gutter-width};
}

// global container styles

.container {
    padding-right: var(--bs-gutter-x);
    padding-left: var(--bs-gutter-x);

    @include mq('medium') {
        &,
        &-md {
            padding-right: calc(var(--bs-gutter-x) * 2);
            padding-left: calc(var(--bs-gutter-x) * 2);
        }
    }

    @include mq('large') {
        &,
        &-md,
        &-lg {
            padding-right: calc(var(--bs-gutter-x) * 4);
            padding-left: calc(var(--bs-gutter-x) * 4);
        }
    }

    @include mq('xlarge') {
        &,
        &-md,
        &-lg,
        &-xl {
            padding-right: calc(var(--bs-gutter-x) * 5);
            padding-left: calc(var(--bs-gutter-x) * 5);
        }
    }

    &:not(.container--restricted, .container--skinny, .container--fpc, .write-review) {
        max-width: none;
    }

    &--wide-mbl {
        padding-right: calc(var(--bs-gutter-x) * 4);
        padding-left: calc(var(--bs-gutter-x) * 4);

        @include mq('xlarge') {
            padding-right: calc(var(--bs-gutter-x) * 5);
            padding-left: calc(var(--bs-gutter-x) * 5);
        }
    }

    &--slim {
        @include mq('xlarge') {
            padding-right: 15.6rem;
            padding-left: 15.6rem;
        }
    }

    &--skinny {
        @include mq('medium') {
            padding-right: calc(var(--bs-gutter-x) * 5);
            padding-left: calc(var(--bs-gutter-x) * 5);
        }

        @include mq('large') {
            padding-right: calc(var(--bs-gutter-x) * 8);
            padding-left: calc(var(--bs-gutter-x) * 8);
        }

        @include mq('xlarge') {
            max-width: 96rem;
            padding-right: calc(var(--bs-gutter-x) * 4);
            padding-left: calc(var(--bs-gutter-x) * 4);
        }
    }

    &--slider {
        height: fit-content;

        overflow: hidden;
        scrollbar-width: thin;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        @include mq('medium', 'max') {
            padding-right: calc(var(--bs-gutter-x) * 2);
            padding-left: var(--bs-gutter-x);
        }
    }

    &--config {
        padding-right: 2.4rem;
        padding-left: 2.4rem;

        @include mq('medium') {
            padding-right: calc(var(--bs-gutter-x) * 8);
            padding-left: calc(var(--bs-gutter-x) * 8);
        }

        @include mq('large') {
            padding-right: calc(var(--bs-gutter-x) * 11);
            padding-left: calc(var(--bs-gutter-x) * 11);
        }
    }

    &--fpc {
        max-width: 192rem;
        margin: 0 auto;
        padding-right: calc(var(--bs-gutter-x) * 1.5);
        padding-left: calc(var(--bs-gutter-x) * 1.5);

        @include mq('small') {
            padding-right: calc(var(--bs-gutter-x) * 6);
            padding-left: calc(var(--bs-gutter-x) * 6);
        }

        @include mq('large') {
            padding-right: calc(var(--bs-gutter-x) * 4);
            padding-left: calc(var(--bs-gutter-x) * 4);
        }

        .btn-group {
            @include mq('small', 'max') {
                width: auto;
                margin-right: auto;
                margin-left: auto;
            }

            .btn {
                @include mq('small', 'max') {
                    min-width: auto;
                    margin-left: 0;
                }
            }
        }
    }
}
