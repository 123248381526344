@mixin breadcrumb-arrow() {
    position: relative;
    top: 0;

    display: inline-block;

    margin-right: 0.8rem;
    margin-left: 0.4rem;

    &,
    svg {
        width: 1.6rem;
        height: 1.1rem;
    }

    svg {
        vertical-align: top;

        fill: $black;

        .page-wrapper.is-dark & {
            fill: $white;
        }
    }
}
