/*************************************
* Navigation Variant Styling,
* This will need to be deleted if this alt
* version is not selected. See PRD-195
**************************************/

.is-menu-mobile-only {
    @include mq('large', 'min') {
        display: none !important;
    }
}

.is-menu-desktop-only {
    @include mq('large', 'max') {
        display: none !important;
    }
}

.mega-menu {
    position: unset;

    overflow: unset;

    @include ie {
        position: inherit;

        &--item-listitem {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &--item__sports {
            display: block !important;
        }

        &--item__stories {
            display: block !important;
        }
    }

    &--item {
        position: absolute;
        top: 100%;
        left: 0;

        display: flex;
        flex-direction: row;
        align-items: flex-start;

        width: 100%;
        min-height: 40rem;
        padding: 6rem 8vw;

        background: $black;

        visibility: hidden;

        opacity: 0;

        transition-delay: 0s;

        a {
            @include font-family('demi');
            padding: 0;

            color: $white;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0;

            @include underline-styles;

            &.sale {
                @include font-family('medium');
                color: $red-brand;
            }

            &:hover {
                text-decoration-line: underline;
            }
        }

        h2,
        .h2 {
            margin-bottom: 1em;
            padding: 0;

            color: $white;
            font-size: 1.6rem;
        }

        > li {
            flex: 1 1 0px;

            margin: 0 calc(var(--bs-gutter-x) * 0.5);

            li {
                margin-bottom: 0.25rem;
            }

            ul {
                transition-delay: 0.1s;
            }
        }

        li {
            opacity: 0;
            transform: translateY(2rem);

            transition:
                opacity 0.4s $ease-out-soft,
                transform 0.3s $ease-out-slide;
            transition-delay: 0s;
        }

        &__sports {
            li {
                margin: auto;
            }
        }

        &__stories {
            li {
                margin: auto;
            }
        }
    }

    &__stories {
        &-image {
            height: 25.9rem;
            max-width: 25.9rem;

            border-radius: $border-radius;

            opacity: 1;
            transform: translateY(0);

            object-fit: cover;
            overflow: hidden;

            &--mobile {
                width: 100%;
                height: auto;
                max-width: unset;
                margin-bottom: 4rem;

                border-radius: 1rem;
                aspect-ratio: 295/168;
            }

            &.is-displayed {
                opacity: 0;
                transform: translateY(4rem);

                transition:
                    opacity 0.4s $ease-out-soft,
                    transform 0.3s $ease-out-soft;
            }
        }
    }

    &:focus,
    &.is-hovering {
        .mega-menu--item {
            visibility: visible;

            opacity: 1;

            transition:
                opacity 0.3s $ease-out-soft,
                transform 0.3s $ease-out-slide;
            transition-delay: 250ms;

            li {
                opacity: 1;
                transform: translateY(0%);

                transition:
                    opacity 0.2s $ease-out-soft,
                    transform 0.3s $ease-out-slide;
                transition-delay: 0.2s;
            }
        }

        &:before {
            position: absolute;
            bottom: 0;
            content: '';
            z-index: 10;

            display: block;

            width: 40vw;
            height: 40%;

            background: transparent;

            transform: translateX(-50%);

            clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
        }

        &:after {
            position: absolute;
            bottom: 0;
            content: '';
            z-index: 10;

            display: block;

            width: 40vw;
            height: 40%;

            background: transparent;

            transform: translateX(50%);

            clip-path: polygon(0 0%, 0% 100%, 100% 100%);
        }
    }

    .menu--category {
        &__web-specials {
            margin-top: 3rem;
        }
    }
}
