@use 'sass:map';
@import '../variables';
@import 'shapes';

@mixin swatch($diameter, $color) {
    @include circle($diameter, $color);
    @include swatch-check-mark;

    position: relative;

    display: inline-block;

    margin-right: 0.313em;

    border: 0.063em solid rgba(0, 0, 0, 0.3);

    // swatch-filter x mark to undo a filter
    i.fa-times-circle {
        width: 0.8em;
        height: 0.75em;

        line-height: 0.8em;

        border-radius: 50%;

        background: $white;
    }
}

@mixin swatch-check-mark() {
    &.color-value[data-selected='true']::after {
        position: absolute;
        left: 0.295em;
        content: '\f058';

        display: table-caption;

        color: $black;
        font-size: 1.625em;
        font-family: FontAwesome;
    }

    &.color-value.selected::after {
        position: absolute;
        top: 0.35em;
        left: 0.31em;
        content: '\f058';

        display: table-caption;

        width: 0.8em;
        height: 0.75em;

        color: $black;
        font-size: 1.625em;
        font-family: FontAwesome;
        line-height: 0.8em;

        border-radius: 50%;

        background: $white;
    }
}

$swatch-colors: (
    'beige': #f5f5dc,
    'black': $black,
    'blue': $blue,
    'brown': #a52a2a,
    'green': $green,
    'grey': #8f979d,
    'navy': #000080,
    'orange': #ffa500,
    'pink': #fe249a,
    'purple': #800080,
    'red': #f00,
    'white': $white,
    'yellow': #ff0,
);

@each $color in map-keys($swatch-colors) {
    $color-value: #{map.get($swatch-colors, $color)};

    .swatch-circle-#{$color} {
        @include circle(2.5em, $color-value);
        position: relative;

        display: block;

        &.disabled {
            opacity: 0.2;
        }
    }

    .swatch-filter-#{$color} {
        @include circle(1.38em, $color-value);
        position: relative;

        display: block;

        background-color: $color-value;

        &.disabled {
            opacity: 0.2;
        }
    }
}

// Credit: Christian Muecke (cmuecke@demandware.com)
.swatch-circle-miscellaneous {
    position: relative;

    display: block;

    width: 2.5em;
    height: 2.5em;

    border: 0.063em solid rgba(0, 0, 0, 0.3);
    border-radius: 1.25em;

    background: linear-gradient(
            0deg,
            rgba(130, 30, 145, 1) 0,
            rgba(130, 30, 145, 1) 25%,
            rgba(237, 209, 52, 1) 25%,
            rgba(255, 255, 0, 1) 50%,
            rgba(237, 209, 52, 1) 50%,
            #59ba00 50%,
            #59ba00 76%,
            #111 76%,
            #111 100%
        ),
        linear-gradient(
            0deg,
            rgba(14, 92, 209, 1) 0,
            rgba(14, 92, 209, 1) 50%,
            rgba(226, 11, 11, 1) 50%,
            rgba(226, 11, 11, 1) 100%
        );
    background-repeat: repeat-y, repeat;
    background-size:
        50% 100%,
        100% 100%;

    transform: rotate(35deg);

    &.disabled {
        opacity: 0.2;
    }

    &.selected::after {
        transform: rotate(-35deg);
    }
}

.product-swatch {
    display: flex;

    padding: 0;

    border-radius: 50%;

    pointer-events: all;

    transition: all 0.2s;

    &::before {
        // Larger space for mobile tap
        position: absolute;
        inset: $spacer * -0.5 $spacer * -0.5 $spacer * -0.5 $spacer * -0.5;
        content: '';
    }

    &:focus-visible {
        border-radius: 50%;

        outline: solid 2px $blue-utility;
    }

    &:active {
        border-radius: 50%;

        box-shadow:
            0 4px 5px rgba($black, 0.14),
            0 1px 10px rgba($black, 0.12),
            0 2px 4px -1px rgba($black, 0.2);
    }

    &.active {
        .cta-circle--swatch {
            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                content: '';

                display: block;

                width: 4rem;
                height: 4rem;

                border: 2px solid $black;
                border-radius: 3rem;

                transform: translate(-50%, -50%);
            }
        }
    }

    &.disabled {
        .cta-circle {
            opacity: 0.5;

            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                content: '';

                display: block;

                width: 0;
                height: 100%;

                border-left: 1px solid $black;

                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }

    .cta-circle {
        width: 3.2rem;
        height: 3.2rem;
        min-width: 2.4rem;

        border: 1px solid $grey-tundora;

        pointer-events: none;
    }

    &--lg {
        .cta-circle {
            width: 4.4rem;
            height: 4.4rem;
        }
    }

    &--sm {
        .cta-circle {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
}
