.popover {
    @include font-family('medium');
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 22rem;
    margin: 0;

    color: $white;
    font-size: 1.2rem;
    line-height: 1.6rem;

    border: 0;
    border-radius: 1rem;

    background-color: $grey-darkest;

    box-shadow: 0 3rem 6rem 0 rgba(0, 0, 0, 0.3);

    &.content-popup {
        min-width: 29.5rem;
    }

    &.bs-popover-bottom {
        margin-top: 1rem !important;
    }

    &.bs-popover-top {
        margin-bottom: 1rem !important;
    }

    &.bs-popover-start,
    &.bs-popover-end {
        .popover-arrow {
            &:before,
            &:after {
                border-top-color: transparent;
                border-bottom-color: transparent;
            }
        }
    }

    a {
        @include font-family('medium');

        color: $white;
    }

    .popover-arrow {
        &:before,
        &:after {
            border-top-color: $grey-darkest;
            border-bottom-color: $grey-darkest;
        }
    }

    .popover-body {
        color: $white;
    }
}

.popover-header {
    @include border-bottom-radius($popover-inner-border-radius);
}
