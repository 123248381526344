html {
    box-sizing: border-box;

    text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
}

*,
*:before,
*:after {
    box-sizing: inherit;

    backface-visibility: inherit;
}

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
    margin: 0;
    padding: 0;

    vertical-align: baseline;

    border: 0 none;

    background: transparent;
}

html,
body {
    width: 100%;
}

body {
    overflow-x: hidden;
}

button,
input,
select,
textarea {
    margin: 0;
    padding: 0;

    border: 0 none;
    border-radius: 0;

    background: transparent;

    outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

ul,
ol {
    list-style: none;
    list-style-image: none;
}

img {
    color: transparent;
    font-size: 0;
    vertical-align: middle;

    border: 0;
    image-rendering: smooth;
}

b,
strong {
    font-weight: bold;
}

a {
    text-decoration: none;
}

a:hover,
button:hover {
    outline: 0;

    box-shadow: none;
}

a:active,
a:focus,
button:active,
button:focus,
input:focus,
select:focus,
textarea:focus {
    // outline: 0; // tisk tisk www.outlinenone.com
    box-shadow: none;
}

input,
select {
    vertical-align: middle;
}

input[type='radio'] {
    vertical-align: text-bottom;
}

input[type='checkbox'] {
    vertical-align: bottom;
}

label,
button,
input[type='button'],
input[type='submit'],
input[type='file'],
input[type='reset'] {
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input::-moz-submit-invalid,
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0 none;

    outline: 0;

    box-shadow: none;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    appearance: none;
}

input::-ms-clear {
    display: none;
}
