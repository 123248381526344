// from bits/cta-link-underline/style.scss
@use 'sass:map';

.cta-link-underline {
    @include underline-styles;

    &:hover,
    &:focus {
        &,
        span {
            text-decoration-line: underline;

            transform: none;
        }
    }

    &:focus-visible {
        border-radius: 4px;

        outline: solid 3px $blue-utility;
        outline-offset: $spacer * 0.5;
    }

    &.disabled {
        opacity: 0.3;

        pointer-events: none;
    }

    &.btn,
    &.btn-md {
        --bs-btn-font-size: #{map.get($body-desktop-sizes, 'p')};

        @include mq('large') {
            --bs-btn-font-size: #{map.get($body-desktop-sizes, 'p')};
        }
    }

    @each $i in 'xs', 'sm', 'lg' {
        &.btn-#{$i} {
            --bs-btn-font-size: #{map.get($body-desktop-sizes, 'p-' + $i)};

            @include mq('large') {
                --bs-btn-font-size: #{map.get($body-desktop-sizes, 'p-' + $i)};
            }
        }
    }

    &.btn-xl {
        --bs-btn-font-size: #{map.get($body-desktop-sizes, 'p-lg')};

        @include mq('large') {
            --bs-btn-font-size: #{map.get($body-desktop-sizes, 'p-lg')};
        }
    }
}
