$form-feedback-icon-size: $input-height-inner-quarter $input-height-inner-quarter;
$form-pristine-validation-states: (
    'valid': (
        'color': $form-feedback-valid-color,
        'icon': $form-feedback-icon-valid,
    ),
    'invalid': (
        'color': $form-feedback-invalid-color,
        'icon': $form-feedback-icon-invalid,
    ),
);

@mixin form-pristine-validation-state-selector($state) {
    @if $state == 'valid' or $state == 'invalid' {
        .form-pristine-is-#{$state} #{if(&, "&", "")}.pristine-is-#{$state},
        .was-validated #{if(&, "&", "")}:#{$state},
        #{if(&, "&", "")}.pristine-is-#{$state}.had-focus,
        #{if(&, "&", "")}.pristine-is-#{$state} .has-value {
            @content;
        }
    } @else {
        #{if(&, "&", "")}.pristine-is-#{$state} {
            @content;
        }
    }
}

@mixin form-pristine-validation-state(
    $state,
    $color,
    $icon,
    $tooltip-color: color-contrast($color),
    $tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity)
) {
    @include form-pristine-validation-state-selector($state) {
        .pristine-#{$state}-feedback,
        .pristine-#{$state}-tooltip {
            display: block;
        }

        @if $state == 'invalid' {
            .is-dark &,
            .dark-theme & {
                $color: $red-brand;
            }

            span:not(.char-remain-count, .char-allowed-count),
            label span:not(.char-remain-count, .char-allowed-count),
            ~ span:not(.char-remain-count, .char-allowed-count) {
                color: $color !important;
            }

            .form-control:not(:focus),
            input:not(:focus),
            select:not(:focus),
            textarea:not(:focus) {
                color: $color;

                border-color: $color;

                &:focus {
                    border-color: $color;

                    box-shadow: 0 2px $color;
                }
            }

            button.custom-input__select-button {
                border-color: $color;

                &:focus {
                    border-color: $color;

                    box-shadow: 0 2px $color;
                }

                &.open {
                    border-color: transparent;
                }
            }

            select {
                color: $color;

                background-image: var(--chevron-down-small-invalid);
            }
        }

        @if $state == 'valid' {
            .form-control:not(:focus),
            input:not(.form-check-input, :focus, .suppress-check),
            .has-value textarea:not(:focus) {
                @if $enable-validation-icons {
                    background-image: escape-svg($icon);
                }
            }

            textarea.form-control,
            .custom-input textarea {
                @if $enable-validation-icons {
                    padding-right: $input-height-inner-half;

                    background-repeat: no-repeat;
                    background-position: top
                        $input-height-inner-quarter
                        right
                        calc($input-height-inner-quarter * 0.5);
                    background-size: $form-feedback-icon-size;
                }
            }
        }

        .form-control,
        input:not(.form-check-input) {
            @if $enable-validation-icons {
                padding-right: $input-height-inner-half;

                background-repeat: no-repeat;
                background-position: right calc($input-height-inner-quarter * 0.5) center;
                background-size: $form-feedback-icon-size;
            }
        }

        .form-select,
        select:not(:focus, .suppress-check),
        .custom-input__select-button:not(.open) {
            @if $enable-validation-icons {
                background-position: $form-select-bg-position, $form-select-feedback-icon-position;
                background-size: $form-select-bg-size, $form-feedback-icon-size;

                &:not([multiple], [size]),
                &:not([multiple])[size='1'] {
                    padding-right: $form-select-feedback-icon-padding-end;

                    @if $state == 'valid' {
                        background-image: escape-svg($form-select-indicator), escape-svg($icon);

                        .is-dark &,
                        .themed.is-dark & {
                            background-image: escape-svg($form-select-indicator-dark-theme),
                                escape-svg($icon);
                        }
                    }
                }
            }
        }
    }

    textarea.form-control,
    .custom-input textarea {
        @if $enable-validation-icons {
            padding-right: $input-height-inner-half;

            background-repeat: no-repeat;
            background-position: top
                $input-height-inner-quarter
                right
                calc($input-height-inner-quarter * 0.5);
            background-size: $form-feedback-icon-size;
        }
    }

    .pristine-#{$state}-feedback {
        $form-feedback-height: $form-feedback-font-size * $line-height + $form-feedback-margin-top;

        @include font-size($form-feedback-font-size);
        display: none;

        width: 100%;
        margin-top: $form-feedback-margin-top;

        color: $color;
        font-style: $form-feedback-font-style;

        &:not(:empty) {
            margin-bottom: $form-feedback-height * -1;
        }
    }

    .pristine-#{$state}-tooltip {
        @include font-size($form-feedback-tooltip-font-size);
        @include border-radius($form-feedback-tooltip-border-radius);
        position: absolute;
        top: 100%;
        z-index: 5;

        display: none;

        max-width: 100%; // Contain to parent when possible
        margin-top: 0.1rem;
        padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;

        color: $tooltip-color;
        line-height: $form-feedback-tooltip-line-height;

        background-color: $tooltip-bg-color;
    }

    .form-check-inline .form-check-input {
        ~ .pristine-#{$state}-feedback {
            margin-left: 0.5em;
        }
    }

    .input-group .form-control,
    .input-group .custom-input input,
    .input-group .custom-input select,
    .input-group .custom-input textarea,
    .input-group .form-select,
    .input-group .custom-input:not(.custom-select) select {
        @include form-pristine-validation-state-selector($state) {
            @if $state == 'valid' {
                z-index: 1;
            } @else if $state == 'invalid' {
                z-index: 2;
            }

            &:focus {
                z-index: 3;
            }
        }
    }
}

@each $state, $data in $form-pristine-validation-states {
    @include form-pristine-validation-state($state, $data...);
}

// Legacy fields
form.form-pristine-is-valid div.pristine-is-invalid:not(.custom-input),
form:not(.form-pristine-is-valid) div.pristine-is-invalid.had-focus:not(.custom-input) {
    .form-control {
        &:not(select) {
            padding-top: 2.6rem;
            padding-bottom: 1.1rem;
        }
    }
}

// #1 Submitted Form with Invalid Fields ( regardless if user interacted with inputs )
// #2 Unsubmitted Form with Invalid Fields ( but only inputs user interacted with )
form.form-pristine-is-valid div.pristine-is-invalid,
form:not(.form-pristine-is-valid) div.pristine-is-invalid.had-focus {
    .form-control:not(.custom-input) {
        ~ .pristine-invalid-feedback {
            position: absolute;
            top: 4.2rem;
            left: 3.2rem;
        }
    }

    // Hide input caption for JP when error shows
    .input__caption {
        display: none;
    }

    // Handle Older Inputs without Labels
    &.no-label .form-control ~ .pristine-invalid-feedback {
        top: 1rem;
    }

    // Hide Server Errors if there are Validation Errors
    .invalid-feedback {
        display: none;
    }
}
