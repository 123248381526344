$loading-height: 110px;
$loading-padding: 24px;
$loading-width: 200px;

.veil {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;

    width: 100%;
    height: 100%;

    text-align: center;

    touch-action: none;

    animation: modal-fade-in 0.25s;

    body > & {
        position: fixed;
    }

    .underlay {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: $white;

        opacity: 0.95;

        pointer-events: none;
        touch-action: none;
    }

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;

        width: $loading-width + ($loading-padding * 2);
        height: $loading-height;
        margin: 0 auto;
        padding: 0 $loading-padding;

        border-radius: $border-radius;

        transform: translate(-50%, -50%);

        pointer-events: none;
        touch-action: none;

        main & {
            position: sticky;
            left: auto;

            transform: translateY(-50%);
        }

        .spinner-centered & {
            position: absolute;
            left: 50%;

            transform: translate(-50%, -50%);
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    &.is-dark {
        .underlay {
            background-color: $black;
        }
    }
}
