.store-inventory-header {
    margin: 0.8rem;

    font-size: 1.4rem;
}

#collapseInstoreInventory {
    margin-bottom: 0;
}

.btn-storelocator-search {
    margin-bottom: 1rem;

    color: $primary;

    border-color: $primary;
    border-radius: 0;

    background-color: $light-gray;

    &:hover {
        color: $light-gray;

        background-color: $primary;
    }
}

.btn-get-in-store-inventory {
    min-width: 30.5rem;
    margin-top: 1.2rem;

    .btn-storelocator-search {
        display: block;

        width: 100%;
        margin-bottom: 0.625rem;

        @include media-breakpoint-up(sm) {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }
}

.store-locator-container .results-card,
.selected-store-with-inventory {
    .results {
        @include media-breakpoint-up(md) {
            max-height: initial;

            overflow: hidden auto;
        }

        &__stores,
        &__dealers {
            padding: 0 calc(var(--bs-gutter-x) * 0.5);
        }

        .stock-status {
            padding-top: 0.5rem;

            &_in {
                color: $green-utility;
            }

            &_low {
                color: $red-brand;
            }

            &_out {
                color: $grey;
            }
        }

        .store-details {
            .store-details {
                display: flex;
            }

            .btn {
                width: auto !important;
            }
        }

        .card-body {
            border-top: 2px solid $grey-silver;
        }

        .tab-pane {
            .card-body {
                &:first-child {
                    border-top: none;
                }
            }
        }
    }

    .card-body {
        padding: 3.6rem 0;

        .card-header {
            padding: 0.75rem 1.5rem;

            border-radius: ($border-radius-sm) ($border-radius-sm) 0 0;

            background-color: $grey-dark;
        }

        address,
        .storelocator-phone,
        .store-hours {
            display: block;

            margin: 1rem 0 0;

            line-height: 1.3333em;

            @include media-breakpoint-up(md) {
                pointer-events: none;
            }
        }

        a:not(.btn),
        .store-hours {
            color: $black;
            font-size: 1.2rem;
        }

        .store-name {
            @include h6;

            @include mq('large') {
                @include h8;
            }

            a {
                @include h6;

                text-decoration: none;

                @include mq('large') {
                    @include h8;
                }
            }
        }

        .store-distance {
            @include font-family('medium');
            font-size: 1.2rem;
        }
    }

    .store-details {
        margin-left: 0;
    }
}

.selected-store-with-inventory {
    .change-store {
        width: auto;
        margin: 0 1em 1em;
    }

    .card-body {
        min-width: 100%;
        margin: 0 auto 2rem;
        padding: 2rem;

        text-align: left;

        @include mq('small') {
            min-width: 30.5rem;
        }

        a:not(.btn) {
            color: $black;
        }
    }
}
