@use 'sass:math';
@import './../bootstrap-override-variables';

.btn {
    $this: &;

    position: relative;

    color: var(--bs-btn-color);
    letter-spacing: 0;
    vertical-align: middle;

    border-color: transparent;

    background-color: var(--bs-btn-bg);

    backface-visibility: hidden;

    transition:
        transform $button-out-time $ease-hover,
        opacity $button-out-time $ease-hover;

    :disabled,
    &.disabled {
        opacity: 0.5;
    }

    &:not(.btn-outlined, .btn-loading, .btn-dark-outlined) {
        &:not(.btn-progress, .btn-underline, .btn-deep-underline):hover {
            --bs-btn-hover-bg: var(--bs-btn-bg);
            --bs-btn-hover-border-color: var(--bs-btn-bg);
        }
    }

    &-underline,
    &-deep-underline {
        @include underline-styles;

        &:hover {
            text-decoration-line: underline;

            background-color: transparent;
        }
    }

    span,
    figure {
        display: inline-block;
    }

    svg {
        vertical-align: text-top;
    }

    &-outlined,
    &-dark-outlined {
        --bs-btn-bg: #{$black};
        color: var(--bs-btn-bg);

        border-color: currentColor;

        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
            color: var(--bs-btn-bg);

            background-color: transparent;
        }

        &:hover {
            border-color: currentColor !important;
        }

        &.disabled,
        &:disabled {
            --bs-btn-disabled-color: var(--bs-btn-bg);
        }
    }

    &-lg {
        min-width: 15.6rem;
    }

    &-xs {
        @include button-size(
            $btn-padding-y-xs,
            $btn-padding-x-xs,
            $btn-font-size-xs,
            $btn-border-radius-sm
        );
        width: auto;
    }

    &-xl {
        width: auto;

        @include mq('large') {
            @include button-size(
                $btn-padding-y,
                $btn-padding-x,
                $btn-font-size-xl,
                $btn-border-radius-lg
            );
        }
    }

    &-has-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: math.div(1.4em, strip-unit($btn-font-size));
            height: math.div(1.4em, strip-unit($btn-font-size));
            margin-right: math.div(0.8em, strip-unit($btn-font-size));

            vertical-align: middle;

            &:last-child {
                margin-right: unset;
                margin-left: math.div(0.8em, strip-unit($btn-font-size));
            }
        }

        &.btn-sm {
            svg {
                width: math.div(1.2em, strip-unit($btn-font-size-sm));
                height: math.div(1.2em, strip-unit($btn-font-size-sm));
            }
        }
    }

    &-only-icon {
        display: flex;
        align-items: center;

        padding-top: 0;
        padding-bottom: 0;

        &.btn-lg svg {
            width: 100px;
            height: 4.8rem;
        }

        svg {
            width: 90px;
            height: 3.6rem;
        }

        &.btn-sm svg {
            height: 2.4rem;
        }
    }

    &-text-only {
        padding: 0;

        border: 0;

        transition: none;

        &:not(.btn-loading):hover {
            color: inherit;
            text-decoration-line: underline;

            border-color: transparent;

            background-color: transparent;

            transform: none;

            span {
                text-decoration-line: underline;
            }
        }

        &-underline {
            text-decoration-line: underline;
        }
    }

    &-underline,
    &-deep-underline,
    &.cta-link-underline {
        @include underline-styles;
        padding-right: 0;
        padding-left: 0;

        border-width: 0;
        border-radius: 0;

        background-color: transparent;
        text-decoration-line: none;

        &:hover {
            text-decoration-line: underline;
        }
    }

    &-underline,
    &.cta-link-underline {
        &,
        span {
            @include underline-styles;

            min-width: 0;

            &:hover {
                text-decoration-line: underline;
            }
        }
    }

    &-deep-underline {
        span {
            @include underline-styles;

            &:hover {
                text-decoration-line: underline;
            }
        }
    }

    &-text-only-dark {
        color: $black;

        &:not(.btn-loading):hover {
            color: $black;
        }
    }

    &-text-only-light {
        color: $white;

        &:not(.btn-loading):hover {
            color: $white;
        }
    }

    &-progress {
        position: relative;

        border: 0;

        &.disabled {
            color: $grey;

            &:after {
                background-color: rgba($black, 0.2);
            }
        }

        &.btn-xs {
            &:before,
            &:after {
                height: 0.2rem;
            }
        }

        &.btn-sm {
            &:before,
            &:after {
                height: 0.45rem;
            }
        }

        &:not(.btn-loading):hover {
            color: $black;

            background-color: transparent;
        }

        &:before,
        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';

            width: 100%;
            height: 0.4rem;

            border-radius: 3rem;

            background-color: rgba($black, 0.2);
        }

        &:after {
            width: 25%;

            background-color: $black;
        }
    }

    &-tile {
        @include p-xs;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 5.6rem;
        height: 5.6rem;
        margin: math.div($spacer, 2);
        padding: 0;

        color: $black;
        font-weight: $font-weight-medium;

        border: 2px solid $grey-white;
        border-radius: $border-radius;

        background-color: $grey-white;

        cursor: pointer;

        user-select: none;

        transition: all 0.2s;

        &-lg {
            width: 8rem;
        }

        &:active {
            box-shadow:
                0 4px 5px rgba(0, 0, 0, 0.14),
                0 1px 10px rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);
        }

        &:focus-visible,
        &.has-focus {
            outline: $blue-utility solid 4px;
        }

        &.active {
            border-color: $black;
        }

        &.is-disabled {
            color: $grey-tundora;

            border-color: $grey-white;

            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' style='width: 100%25%3b height: 100%25%3b'%3e%3cline x1='0' y1='100%25' x2='100%25' y2='0' style='stroke:%23999%3bstroke-width:1'/%3e%3c/svg%3e");

            cursor: not-allowed;

            opacity: 1;
        }

        small {
            @include p-xs;
        }

        &.btn-loading {
            &:after {
                border-color: $black $black transparent $black;
            }
        }
    }
}

// Show underline for cta-link-underline elements without btn class
.cta-link-underline {
    &,
    span {
        @include underline-styles;

        letter-spacing: 0;

        &:hover {
            text-decoration-line: underline;
        }
    }
}

.btn-loading {
    color: transparent;

    cursor: progress;

    pointer-events: none;

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';

        display: block;

        width: 18px;
        height: 18px;

        border: solid 2px $white;
        border-bottom-color: transparent;
        border-radius: 50%;

        animation: rotate 1s infinite ease;
    }

    @keyframes rotate {
        from {
            transform: translate(-50%, -50%) rotate(0deg);
        }

        to {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    &.disabled,
    &:disabled {
        color: transparent;
    }
}

.btn-primary {
    --bs-btn-color: #{$white};
}

.btn-light {
    --bs-btn-color: #{$black};

    &:not(.btn-loading, .btn-outlined):hover {
        color: $black;

        border-color: $white;

        background-color: $white;
    }
}

.btn-dark.btn-outlined {
    --bs-btn-bg: #{$black};
}

.btn-dark.btn-dark-outlined {
    --bs-btn-bg: #{$white};
}

.btn:not(.paypal-btn) {
    svg:not(.colored) {
        * {
            fill: currentColor;
        }
    }
}

.btn-light.btn-outlined {
    --bs-btn-bg: #{$white};
}

// disabled states
.btn:disabled,
.btn.disabled {
    pointer-events: none;

    &.clickable {
        pointer-events: all;
    }

    &.btn-outlined {
        background: transparent;
    }
}

.btn-paypal {
    color: $white;

    border-color: $paypal-brand;

    background-color: $paypal-brand;

    &:not(.btn-loading):hover {
        border-color: $paypal-brand;

        background-color: $paypal-brand;
    }
}

// active states
.btn-light.btn-outlined.active {
    color: var(--bs-btn-bg);

    background-color: var(--bs-btn-color);
}

// color variations
.btn-orange {
    color: $white;

    background-color: $red-brand;

    &:not(.btn-loading):hover {
        color: $white;

        border-color: $red-brand;

        background-color: $red-brand;
    }
}

.btn-checkout {
    color: $white;

    background-color: $red-utility;

    @include focus-visible {
        border-color: $blue-utility;

        background-color: $red-utility;
    }

    &:not(.btn-loading):hover {
        color: $white;

        border-color: $red-utility;

        background-color: $red-utility;
    }
}

.btn-checkout,
.btn-orange,
.btn-paypal {
    &.disabled,
    &:disabled {
        color: $white;

        border-color: $grey-silver;

        background-color: $grey-silver;

        opacity: 1;

        pointer-events: none;

        &.btn-loading {
            color: transparent;
        }
    }
}

.btn-netflix,
.btn-itunes {
    max-width: 16rem;

    &.btn-sm {
        max-width: 12rem;
    }

    &.btn-lg {
        max-width: 20rem;
    }

    &.btn-lg svg,
    &.btn svg,
    &.btn-sm svg {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
}

.btn-itunes {
    &.btn-lg svg,
    &.btn svg,
    &.btn-sm svg {
        max-width: 115px;
    }
}

.btn-netflix {
    &.btn-lg svg,
    &.btn svg,
    &.btn-sm svg {
        max-width: 80px;
    }
}

// Add support for Dark Theme
.dark-theme,
.is-dark.is-themed {
    .btn.btn-dark,
    .btn.btn-dark:focus,
    .btn.btn-dark:active,
    .btn.btn-primary,
    .btn.btn-primary:focus,
    .btn.btn-primary:active {
        color: var(--bs-btn-bg);

        background-color: var(--bs-btn-color);
    }

    .btn.btn-dark.btn-outlined,
    .btn.btn-dark.btn-outlined:focus,
    .btn.btn-dark.btn-outlined:active,
    .btn.btn-primary.btn-outlined,
    .btn.btn-primary.btn-outlined:focus,
    .btn.btn-primary.btn-outlined:active {
        color: var(--bs-btn-color);

        border-color: var(--bs-btn-color);

        background-color: transparent;
    }

    .btn.btn-light,
    .btn.btn-light:focus,
    .btn.btn-light:active {
        color: var(--bs-btn-color);

        background-color: var(--bs-btn-bg);
    }

    .btn.btn-light.btn-outlined,
    .btn.btn-light.btn-outlined:focus,
    .btn.btn-light.btn-outlined:active {
        color: var(--bs-btn-bg);

        border-color: var(--bs-btn-bg);

        background-color: transparent;
    }

    .btn.btn-text-only.btn-text-only-dark {
        color: var(--bs-btn-color);

        background-color: transparent;
    }

    .btn.btn-text-only.btn-text-only-light {
        color: var(--bs-btn-color);

        background-color: transparent;
    }

    .btn-progress {
        color: var(--bs-btn-bg);

        &::before {
            background-color: rgba(255, 255, 255, 0.2);
        }

        &:after {
            background-color: var(--bs-btn-bg);
        }
    }

    .cta-link-back {
        background-color: transparent;

        .h9 {
            color: var(--bs-btn-color);

            background-color: transparent;
        }
    }

    .btn.cta-link-underline {
        color: var(--bs-btn-color);

        background-color: transparent;
    }

    .cta-circle,
    .cta-circle.disabled {
        border: 4px solid var(--bs-btn-bg);

        background: var(--bs-btn-bg);

        .cta-circle__heading {
            color: var(--bs-btn-bg);
        }
    }

    .cta-circle.cta-circle-outlined,
    .cta-circle.cta-circle-outlined.disabled {
        border: 4px solid var(--bs-btn-color);

        background: transparent;

        .cta-circle__heading {
            color: var(--bs-btn-color);
        }
    }

    .cta-circle.disabled,
    .cta-circle.cta-circle-outlined.disabled {
        opacity: 0.5;
    }
}

.btn-layout {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include mq('small') {
        margin: 0 calc(var(--bs-gutter-x) * -0.5);
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        margin: 0 0 1rem;

        @include mq('small') {
            width: auto;
            margin: 0 1rem 1rem;
        }
    }
}
