.modal.modal__content {
    .modal-content-hidden {
        display: none;
    }

    .modal-close {
        top: 0;
        right: 0;

        .cta-circle {
            height: 5.6rem;
            min-width: 5.6rem;
        }
    }

    .modal-content {
        position: relative;

        width: calc(100vw - 2rem);
        height: 100%;
        max-width: 62rem;
        min-height: 0;
        margin: 0 auto;

        overflow: auto;
        overflow-x: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;

        @include mq('medium') {
            height: auto;
            margin: 8rem auto;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;

            appearance: none;
        }
    }

    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;

        @include ie {
            justify-content: flex-start;
        }
    }

    .modal-body {
        display: block;

        width: 100%;
        height: auto;
        min-height: auto;
        padding: 8rem 3rem;

        text-align: left;

        @include mq('medium') {
            padding: 8rem 8rem 10rem;
        }

        > div {
            > h4 {
                margin-bottom: 4rem;
            }

            > h6,
            > p,
            > .modal-email-capture--byline {
                margin-bottom: 2.4rem;
            }

            > div,
            .content-asset > div {
                margin-bottom: 2.4rem;

                > h4 {
                    margin-bottom: 4rem;
                }

                > h6,
                > p,
                > .modal-email-capture--byline {
                    margin-bottom: 2.4rem;
                }
            }
        }
    }

    textarea {
        background-color: $white;
    }

    .custom-input--two {
        display: flex;
        justify-content: space-between;

        div {
            flex-basis: calc(50% - 1rem);
        }
    }

    .btn--container {
        @include mq('medium', 'max') {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        a,
        button {
            display: inline-block;

            vertical-align: middle;

            &:last-child {
                margin-top: 2rem;

                @include mq('medium') {
                    margin-top: 0;
                    margin-left: 2rem;
                }
            }
        }
    }

    .navigation-subnav-tabs {
        margin-top: 0;

        background: transparent;

        @include media-breakpoint-down('md') {
            display: flex;
        }

        .nav {
            @include media-breakpoint-down('md') {
                padding-left: 0;
            }
        }

        .navigation-subnav-tabs__links-wrapper {
            padding: 0;

            @include media-breakpoint-down('md') {
                height: 4rem;
            }
        }
    }

    .navigation-subnav-tabs__links {
        @include media-breakpoint-down('medium') {
            width: 100%;
        }

        .nav-pills {
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;

                appearance: none;
            }

            .nav-link {
                @include media-breakpoint-down('md') {
                    width: auto;
                }
            }
        }
    }

    .navigation-subnav-tabs__active-line {
        background: $black;
    }

    .navigation-subnav-tabs__bottom-line {
        background: $grey-silver;

        @include media-breakpoint-down('md') {
            width: 100% !important;
        }
    }
}
