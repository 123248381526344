@use 'sass:list';

/** Static Drop Shadows
 - Based on the new design system
 - Shadows are cast by a key light and ambient light. In Android and iOS development, shadows occur when light sources are blocked by surfaces at various positions along the z-axis. On the web, shadows are depicted by manipulating the y-axis only.
 **/

$accepted-depths: 4, 8, 12, 16, 24;

@mixin DropShadow($depth, $color: $black) {
    @if index($accepted-depths, $depth) {
        @if $depth == 4 {
            box-shadow:
                0 4px 5px rgba($color, 0.14),
                0 1px 10px rgba($color, 0.12),
                0 2px 4px -1px rgba($color, 0.2);
        }

        @if $depth == 8 {
            box-shadow:
                0 8px 10px 1px rgba($color, 0.14),
                0 3px 14px 2px rgba($color, 0.12),
                0 5px 5px -3px rgba($color, 0.2);
        }

        @if $depth == 12 {
            box-shadow:
                0 12px 17px 2px rgba($color, 0.14),
                0 5px 22px 4px rgba($color, 0.12),
                0 7px 8px -4px rgba($color, 0.2);
        }

        @if $depth == 16 {
            box-shadow:
                0 16px 24px 2px rgba($color, 0.14),
                0 6px 30px 5px rgba($color, 0.12),
                0 8px 10px -5px rgba($color, 0.2);
        }

        @if $depth == 24 {
            box-shadow:
                0 24px 38px 3px rgba(0, 0, 0, 0.14),
                0 9px 46px 8px rgba(0, 0, 0, 0.12),
                0 11px 15px -7px rgba(0, 0, 0, 0.2);
        }
    } @else {
        @warn "DropShadow: Please use a depth of 4, 8, 12, 16 or 24.";
    }
}
