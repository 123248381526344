.swiper {
    --#{$variable-prefix}gutter-x: #{$grid-gutter-width};
}

.swiper-wrapper {
    min-height: 0;

    &.row {
        flex-wrap: nowrap;

        margin-right: 0;
        margin-left: 0;
    }
}

.swiper-button-prev,
.swiper-button-next {
    display: none;

    border-radius: 100%;

    @include focus-visible {
        outline: $blue-utility solid 4px;
    }
}

.swiper-initialized {
    .swiper-button-prev,
    .swiper-button-next {
        display: block;
    }
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    display: none;
}

.swiper-slide {
    img {
        backface-visibility: hidden;
    }
}

.swiper-scrollbar {
    position: relative;

    border-radius: $border-radius;

    background: rgba(0, 0, 0, 0.1);

    touch-action: none;

    .is-dark & {
        background: rgba($white, 0.1);
    }
}

// touch device styles only

// not getting rid of this, just altering the rule
// a little in case we have to solve for larger issue documented in
// https://patagonia.atlassian.net/browse/DSX-5
body.is-user-touch {
    .swiper-button-next,
    .swiper-button-prev {
        opacity: 1;

        &[aria-disabled='true'] {
            opacity: 0 !important;
        }

        &[aria-disabled='false'] {
            opacity: 1 !important;
        }
    }
}
