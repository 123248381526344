@use 'sass:map';
@use 'sass:math';
@use 'sass:color';

.header-store-location {
    $this: '.header-store-location';

    @extend %account-login-container;
    position: fixed;
    top: 100%;
    left: 0;
    z-index: (map.get($z-indexes, 'sticky') + 2);

    display: none;

    width: auto;
    height: var(--pat-utility-bar-height);
    margin-top: 1rem;
    margin-right: 0;

    color: $black;

    transition:
        z-index 0s 0.5s step-end,
        transform 0.3s 0.6s $ease-out-soft;

    @include mq('medium') {
        position: absolute;
        top: 0;

        margin-top: 0;
    }

    @include mq('large') {
        margin: 0 calc(var(--bs-gutter-x) * 4);
    }

    @include mq('xlarge') {
        margin: 0 calc(var(--bs-gutter-x) * 5);
    }

    &.fade:not(.show) {
        pointer-events: none;
    }

    .offcanvas--utility-nav & {
        color: $black;

        &.fade:not(.show) {
            transform: translateY(-8 * $spacer);
        }
    }

    .navigation-primary & {
        position: absolute;
        top: 0;

        margin: 0;
    }

    .header-store-enabled & {
        display: block;
    }

    .utility-bar & {
        display: none;

        color: $white;

        @include mq('medium') {
            display: block;
        }
    }

    &--open {
        z-index: (map.get($z-indexes, 'modal') + 2);

        transition:
            z-index 0s 0s step-end,
            transform 0.3s 0.6s $ease-out-soft;
    }

    .header-store-enabled .navigation-primary.is-expanded & {
        display: block;

        opacity: 0;

        animation: none;
    }

    .header-store-enabled .navigation-primary.is-expanded.is-section-mobile & {
        display: block;

        opacity: 1;

        animation: 0.5s header-store-location-mobile-show $ease-out-soft;

        @keyframes header-store-location-mobile-show {
            from {
                opacity: 0;
                transform: translateY(4rem);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    svg {
        vertical-align: middle;
    }

    svg,
    svg path {
        fill: currentColor;
    }

    &__header {
        display: grid;

        height: 4.8rem;
        padding-right: calc(var(--bs-gutter-x) * 0.5);

        white-space: nowrap;

        > div {
            display: grid;
            grid-template-columns: 13px 1fr;
            align-items: center;

            column-gap: $spacer;

            animation: header-store-appear 0.5s ease;
        }

        @keyframes header-store-appear {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        #{$this}--clickable & {
            cursor: pointer;
        }

        .header-store-location__inner {
            grid-column: 2;

            display: inline-block;

            max-width: 19.5rem;

            color: currentColor;
            line-height: 1.5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;

            overflow: hidden;

            @include mq('medium') {
                max-width: 18.2rem;
            }

            @include mq('xxlarge') {
                max-width: 20rem;
            }

            @include mq('xxxlarge') {
                max-width: 22rem;
            }

            @include mq('xxxxlarge') {
                max-width: 24rem;
            }
        }

        a,
        a:hover {
            color: currentColor;
            text-decoration: none;
        }
    }

    &__pin {
        width: 1.3rem;
        height: 1.6rem;
        margin-right: $spacer;
    }

    &__arrow {
        width: 1rem;
        height: 1rem;
    }

    &__overlay {
        position: fixed;
        inset: 0 0 100%;
        z-index: (map.get($z-indexes, 'modal') + 2);

        display: block;

        background: rgba($black, 0.75);

        opacity: 0;

        transition:
            opacity 0.5s ease,
            bottom 0s 0.5s;

        #{$this}--open & {
            bottom: 0;

            opacity: 1;

            transition:
                opacity 0.5s ease,
                bottom 0s 0s;
        }

        .navigation-primary & {
            display: none;
        }
    }

    &__flyout {
        position: fixed;
        left: 50%;
        bottom: 3rem;
        z-index: 0;

        display: none;

        width: 90%;
        max-height: 0;

        color: $white;
        text-align: center;

        border-radius: $border-radius;

        background-color: $darker-gray;

        visibility: hidden;

        opacity: 0;
        transform: translate(-50%, 50%);

        overflow: hidden;

        pointer-events: none;

        transition:
            opacity 0.5s ease,
            max-height 0.5s step-end,
            transform 0.5s ease,
            visibility 0.5s step-end;

        @include mq('medium') {
            top: 50vh;
            bottom: auto;

            max-width: 50rem;

            transform: translate(-50%, -40%);
        }

        #{$this}--open & {
            max-height: 1000rem;

            visibility: visible;

            opacity: 1;
            transform: translate(-50%, 0);

            pointer-events: all;

            transition-timing-function: ease, step-start, ease, step-start;

            @include mq('medium') {
                transform: translate(-50%, -50%);
            }
        }

        #{$this}--has-data & {
            z-index: (map.get($z-indexes, 'modal') + 3);

            display: block;
        }

        .navigation-primary & {
            display: none;
        }
    }

    &__close.cta-circle {
        position: absolute;
        top: calc(var(--bs-gutter-x) * 0.5);
        right: calc(var(--bs-gutter-x) * 0.5);
        z-index: 2;

        svg {
            width: 1rem;
            height: 1rem;

            @include mq('medium') {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }

    &__details {
        &__img {
            @include object-fit(cover);
            position: absolute;
            inset: 0;

            width: 100%;
            height: 100%;
        }

        &__store {
            position: relative;

            padding: 6rem var(--bs-gutter-x) 0;

            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &--has-img {
                padding-bottom: 4.5rem;

                &:before {
                    position: absolute;
                    inset: 0;
                    content: '';
                    z-index: 1;

                    display: block;

                    background-color: $black;

                    opacity: 0.6;
                }
            }
        }

        &__name,
        address {
            position: relative;
            z-index: 2;
        }

        &__name {
            @include h5;

            margin-bottom: 3.2rem;

            @include mq('large') {
                @include h7;
            }
        }

        &__map-link,
        &__phone,
        &__hours {
            margin-bottom: 1em;
        }

        &__name a,
        address a {
            @include underline-styles;

            display: block;

            color: $white;

            &:hover {
                color: $white;
                text-decoration-line: underline;
            }
        }

        &__footer {
            padding: 4rem 3rem 4.6rem;

            text-align: center;

            a,
            button {
                display: block;

                width: 100%;
            }
        }
    }

    &__loading-icon {
        position: absolute;
        top: 50%;
        left: 0;

        display: inline-block;

        width: 30px;
        height: 30px;

        transform: translateY(-50%);

        div {
            position: absolute;
            top: 50%;
            left: 50%;

            border: 2px solid currentColor;
            border-radius: 50%;

            opacity: 0;
            transform: translate3d(-50%, -50%, 0);

            animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

            &:nth-child(2) {
                animation-delay: -1s;
            }
        }
    }

    &.header-tabs-enabled {
        @include mq('large', 'min') {
            left: unset;
            right: 13.6rem;
        }
    }

    @keyframes lds-ripple {
        0% {
            width: 0;
            height: 0;

            opacity: 0.8;
        }

        100% {
            width: (math.div(72, 80) * 100%);
            height: (math.div(72, 80) * 100%);

            opacity: 0;
        }
    }
}

main {
    &:not(.customer-store-found, .customer-store-none) {
        .customer-store-found,
        .customer-store-none {
            display: none;
        }
    }

    &.customer-store-found {
        .customer-store-none {
            display: none !important;
        }
    }

    &.customer-store-none {
        .customer-store-found {
            display: none !important;
        }
    }
}
