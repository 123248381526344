/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);

  https://github.com/bfred-it/object-fit-images
*/

@mixin object-fit($fit: fill, $position: null) {
    object-fit: $fit;

    @if $position {
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';

        object-position: $position;
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}
