@import 'components/custom-controls';

@mixin form-validation-state-selector($state) {
    @if $state == 'valid' or $state == 'invalid' {
        .was-validated #{if(&, "&", "")}:#{$state},
        .is-#{$state} #{if(&, "&", "")} {
            @content;
        }
    } @else {
        .is-#{$state} #{if(&, "&", "")} {
            @content;
        }
    }
}

@each $state, $data in $form-validation-states {
    @include form-validation-state($state, $data...);
}

label {
    margin-bottom: 1rem;

    font-size: 1.2rem;
}

.form-group {
    position: relative;

    margin-bottom: 4rem;
}

.required:not(.custom-input) .form-control-label {
    &:after {
        content: ' *';
    }
}

.custom-input.required label span:not(.char-remain-count, .char-allowed-count),
.custom-input .required span:not(.char-remain-count, .char-allowed-count) {
    &:after {
        content: ' *';
    }
}

.invalid-feedback {
    position: absolute;
    top: 3.4rem;
    left: 3.4rem;

    text-align: left;
}

.form-group-radio {
    .invalid-feedback {
        position: initial;
    }
}

.no-label {
    .invalid-feedback {
        top: 0;
    }
}

.form-control {
    position: relative;

    font-weight: 500;
    font-size: 1.6rem;

    border: none;

    box-shadow: 0 0 0 2px rgba($black, 0.06);

    appearance: none;
    caret-color: $black;

    &:focus {
        background-color: unset;

        outline: 0;
    }

    &.is-invalid:not(select) {
        padding-top: 2.9rem;
        padding-bottom: 1.1rem;

        &:focus {
            box-shadow: none;
        }
    }
}

textarea.form-control {
    height: auto;
}

.form-select {
    line-height: $line-height;

    cursor: pointer;
}

.form-legacy {
    .form-select {
        padding: 1.5rem ($form-select-indicator-padding * 2) 1.5rem $form-select-padding-x;

        background-position: right $form-select-indicator-padding center;
    }

    .form-control:focus {
        box-shadow: 0 0 0 2px $blue-utility;
    }
}

option {
    color: $black;

    background-color: $white;
}

.grecaptcha-badge {
    visibility: hidden;
}

.recaptcha-messaging {
    @include p-xs;

    margin-top: $spacer * 2;

    color: $grey-light;

    a {
        color: $grey-light;
    }

    &.no-margin-top {
        margin-top: 0;
    }
}

.is-dark {
    .recaptcha-messaging {
        color: $white;

        a {
            color: $white;
        }
    }
}
