////////////////////////////////////////////////////////
// Font Helper Classes
////////////////////////////////////////////////////////

.font {
    &__body {
        @include font-family('body');

        &__medium {
            @include font-family('medium');
        }

        &__bold {
            @include font-family('bold');
        }
    }

    &__heading {
        @include font-family('heading');
    }

    &__serif,
    &__secondary {
        @include font-family('serif', '!important');
    }

    &__sans,
    &__primary {
        @include font-family('sans', '!important');
    }

    &__heavy {
        @include font-family('heavy', '!important');
    }
}

p.font-special-sentinel,
.font-special-sentinel {
    @include font-family('serif');
}

b,
strong {
    font-weight: 700;
}

q {
    font-weight: 500;
}

ul {
    &.bullet-list {
        li {
            margin-left: $spacer * 2;

            list-style: disc;
        }
    }
}

a {
    &.text-link,
    &.text-link-sm {
        @include underline-styles;
        font-weight: $font-weight-medium;
        text-decoration: none;

        &:hover {
            @include mq('large') {
                text-decoration-line: underline;
            }
        }

        &.external-link {
            &::after {
                content: '';

                display: inline-block;

                white-space: nowrap;
                vertical-align: middle;

                background: var(--arrow-expand) no-repeat center / 50%;
            }
        }
    }

    &.text-link {
        font-size: 1.6rem;

        &.external-link {
            &::after {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    &.text-link-sm {
        font-size: 1.2rem;

        &.external-link {
            &::after {
                width: 1.6rem;
                height: 1.6rem;
            }
        }
    }
}
