#modalQuickAddSizes {
    .modal-dialog {
        position: absolute;
        inset: 0;

        min-height: 0;
    }
}

.modal-quickadd-sizes {
    z-index: 9999;

    .modal-content {
        padding-bottom: $spacer * 3;

        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .modal-header {
        padding: ($spacer * 1.25) 0 0;

        border-bottom: 0;

        @include mq('large') {
            padding: ($spacer * 2) 0 0;
        }
    }

    .modal-body.light-theme {
        margin: 0;

        text-align: left;

        .product-quickadd-content {
            margin-top: $spacer * 3;
            padding: 0;
        }

        .size-container {
            margin: $spacer * -0.5;

            @include mq('large') {
                display: inline-block;
                flex-basis: auto;
                flex-grow: 0;

                width: auto;
            }
        }

        .select-size {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            max-width: calc(100% + $spacer);
            margin: 0 $spacer * -1 0 0;

            > li {
                min-width: 33.33%;
                padding: $spacer * 0.5;

                @include mq('medium') {
                    min-width: 25%;
                }

                @include mq('large') {
                    min-width: 0;
                }

                .disabled {
                    color: $grey-silver;

                    border-color: $grey-silver;

                    cursor: default;

                    pointer-events: none;
                }

                button {
                    margin: 0;

                    @include mq('large', 'max') {
                        width: 100%;
                        min-width: 10rem;
                    }
                }

                .btn.pdp-size-select {
                    &:hover {
                        color: $black;
                    }
                }
            }
        }

        .product-quickadd--oos-text {
            margin: ($spacer * 2) 0 0;

            text-align: left;

            @include mq('large') {
                flex-basis: 50%;
            }

            .cta-link-underline {
                font-weight: $font-weight-medium;
                font-size: inherit;
            }
        }

        .quickadd-atb-btn {
            &.btn-loading-circle {
                color: transparent;

                background: $black !important;
            }
        }
    }
}
