.field-search {
    position: relative;

    max-width: 29.5rem;

    border: none;
    border-radius: 30px;

    ////////////////////////////////////////////////////////////////
    // VARIATIONS

    &.is-inverse {
        figure {
            svg * {
                fill: $white !important;
            }
        }

        span {
            color: $white;

            .placeholder,
            .suggestion {
                color: rgba($white, 0.6);
            }
        }

        input {
            background: $black;
            caret-color: $white;

            box-shadow: 0 0 0 2px rgba($black, 0);

            &:focus {
                border: none;

                outline: 0;

                box-shadow: 0 0 0 4px rgba($black, 0);
            }
        }
    }

    &.is-no-outline {
        input {
            box-shadow: none;
        }
    }

    ////////////////////////////////////////////////////////////////
    // ELEMENTS

    figure {
        position: absolute;
        top: calc(50% - 1.2rem);
        left: 0;
        z-index: 3;

        width: 2.5rem;
        height: 2.5rem;

        @include mq('medium') {
            left: 0.5rem;
        }

        @include mq('large') {
            left: calc(var(--bs-gutter-x) * -0.5);
        }

        &.is-right {
            left: auto;
            right: 0;
        }

        svg * {
            fill: $black !important;
        }
    }

    input,
    > span {
        @include font-family('regular');
        left: -1.6rem;

        width: calc(100% + 2.8rem);
        padding: 0 0 0 5.2rem;

        font-size: 1.6rem;

        @include mq('medium') {
            left: -1.6rem;

            padding: 0 0 0 6.2rem;
        }

        @include mq('large') {
            left: -3.2rem;

            width: calc(100% + 5.6rem);
            padding: 0 0 0 5.7rem;
        }
    }

    input {
        position: relative;

        height: 4rem;

        color: $black;
        line-height: 1;

        border: none;

        background: $white-alabaster;

        appearance: none;

        caret-color: $black;

        &:focus {
            box-shadow: 0 0 0 2px $blue-utility;
        }
    }

    > span {
        position: absolute;
        top: 0;
        z-index: 2;

        color: $black;
        line-height: 4rem;

        pointer-events: none;

        .placeholder,
        .suggestion {
            color: rgba($black, 0.6);
        }
    }

    figure.is-left ~ input {
        padding-left: 5.2rem;

        @include mq('medium') {
            padding-left: 6.2rem;
        }

        @include mq('large') {
            padding-left: 5.7rem;
        }
    }

    figure.is-right ~ input {
        padding-right: 5.7rem;

        @include mq('medium') {
            padding-right: 6.2rem;
        }

        @include mq('large') {
            padding-right: 5.7rem;
        }
    }
}
