@use 'sass:map';
@use 'sass:math';
@import './../base-bootstrap';

$photo-tall-ratio: math.div(416, 582);

:root {
    // Setting default slide width to fix bug in mobile devices
    // this value gets updated by JS
    --rtfl-slide-width: 380px;
}

.routing-tile-flex-layout {
    $this: &; // reference to parent selector

    position: relative;

    padding: $spacer * 2 0;

    background-color: var(--pat-theme-back, transparent);

    overflow-x: hidden;

    &--2,
    &--3,
    &--4 {
        @include mq('large') {
            padding-right: calc(var(--bs-gutter-x) * 4);
            padding-left: calc(var(--bs-gutter-x) * 4);
        }

        @include mq('xlarge') {
            padding-right: calc(var(--bs-gutter-x) * 5);
            padding-left: calc(var(--bs-gutter-x) * 5);
        }

        #{$this}__items {
            @include mq('large') {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    // has 5 or more tiles in desktop
    &:has(.routing-tile-flex-layout__items > :nth-child(5)) {
        @include mq('large') {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.is-tall {
        .card .card__image {
            @include mq('medium') {
                padding-bottom: math.percentage($photo-tall-ratio);
            }
        }
    }

    &.is-stacked {
        @include mq('medium', 'max') {
            padding-right: 0;
            padding-left: 0;

            #{$this}__items {
                flex-wrap: wrap;

                #{$this}__item:last-child {
                    margin-top: var(--bs-gutter-x);
                    padding-right: calc(var(--bs-gutter-x) * 0.5);
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }
    }

    &.is-dark {
        .routing-tile-flex-layout__item {
            .card__copy,
            .card__addon .cta-link-underline:not(.text-white) {
                color: $white !important;
            }

            // Fix for Background Fill in tile where theme does not apply.
            .card--bg-fill {
                .card__copy,
                .card__addon .cta-link-underline {
                    color: $black !important;
                }

                .card__addon .btn-light {
                    color: $white !important;

                    background: $black !important;
                }
            }
        }
    }

    &__items {
        display: flex;
        flex-wrap: nowrap;

        padding-right: var(--bs-gutter-x);
        padding-left: var(--bs-gutter-x);

        white-space: nowrap;
        text-align: start;

        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior-x: contain;
        scrollbar-width: none;
        -ms-overflow-style: none;

        scroll-snap-type: x mandatory;

        @include mq('medium') {
            scroll-padding-right: calc(var(--bs-gutter-x) * 3);
        }

        &::-webkit-scrollbar {
            display: none;
        }

        @include mq('medium') {
            padding-right: calc(var(--bs-gutter-x) * 3);
            padding-left: calc(var(--bs-gutter-x) * 3);
        }

        @include mq('large') {
            padding-right: calc(var(--bs-gutter-x) * 4);
            padding-left: calc(var(--bs-gutter-x) * 4);
        }

        @include mq('xlarge') {
            padding-right: calc(var(--bs-gutter-x) * 5);
            padding-left: calc(var(--bs-gutter-x) * 5);
        }

        @media (hover: none) {
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }
    }

    &__item {
        flex: 1;

        width: auto;
        height: 100%;
        min-width: var(--rtfl-slide-width);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);

        white-space: normal;

        scroll-snap-align: center;

        @include mq('medium') {
            scroll-snap-align: start end;
        }

        &:first-child {
            scroll-snap-align: end;
        }

        &:last-child {
            scroll-snap-align: start;
        }
    }

    #{$this}__container {
        position: relative;

        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    // Fix for content height on iOS 13 and lower
    body.is-ios13-or-lower & {
        .routing-tile-flex-layout__item {
            @include mq('medium', 'max') {
                height: auto;
            }
        }
    }

    // Fixes for iOS 15 and lower
    body.is-ios15-or-lower & {
        &--2.is-carousel,
        &--3 {
            .routing-tile-flex-layout__item {
                padding-right: 0;
                padding-left: 0;

                .card {
                    padding-right: 0.8rem;
                    padding-left: 0.8rem;
                }

                &:last-child {
                    min-width: calc(var(--rtfl-slide-width) + 1.6rem);
                    padding-right: 1.6rem;
                }
            }
        }

        // has 4 or more tiles
        &--4 .routing-tile-flex-layout__item,
        &__item:nth-child(4) ~ .routing-tile-flex-layout__item {
            padding-right: 0;
            padding-left: 0;

            .card {
                padding-right: 0.8rem;
                padding-left: 0.8rem;
            }

            &:last-child {
                min-width: calc(var(--rtfl-slide-width) + 1.6rem);
                padding-right: 1.6rem;

                @include mq('medium') {
                    min-width: calc(var(--rtfl-slide-width) + 4.8rem);
                    padding-right: 4.8rem;
                }
            }
        }
    }
}
