.email-signup-inline {
    margin: 0 auto;

    h3 {
        margin-bottom: 0;
        padding-bottom: 0;

        p {
            padding-bottom: 3rem;

            line-height: 1.3;
        }
    }

    h3,
    p {
        padding-bottom: 4rem;

        @include mq('medium') {
            text-align: center;
        }
    }

    .form-check-inline {
        transform: translateX(-1.4rem);
    }

    form {
        max-width: 32rem;
        margin: 0 auto;

        .form-check {
            margin: {
                left: 1.3rem;
                bottom: 2rem;
            }
        }

        .btn {
            display: block;

            margin: 0 auto;
        }
    }

    .privacy-policy {
        margin: {
            top: -2.5rem;
            bottom: 5rem;
        }

        a {
            @include underline-styles;
            color: $white;
            text-decoration-line: underline;
        }
    }
}
