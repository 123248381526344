@import './variables';

// Combines Bootstrap 4 utility classes for color, with brand color variables

@each $new-color, $color in $brand-colors {
    .text-#{$new-color} {
        color: $color !important;
    }

    .bg-#{$new-color} {
        background-color: $color !important;
    }

    .border-#{$new-color} {
        border-color: $color !important;
    }

    .fill-#{$new-color} {
        fill: $color !important;
    }
}

@each $new-color, $color in $colors {
    .fill-#{$new-color} {
        fill: $color !important;
    }
}

@each $new-color, $color in $theme-colors {
    .fill-#{$new-color} {
        fill: $color !important;
    }
}
