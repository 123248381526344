// from js/modules/callouts/callount-headline/style.scss

.callout-headline {
    position: relative;

    width: 100%;
    height: auto;
    padding-top: 82.5%;

    background: $white;

    @include mq('medium') {
        padding-top: 52.5%;
    }

    &.is-light {
        color: $white;

        background: $black;
    }

    &.is-dark {
        color: $black;

        background: $white;
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;
        padding: 20px;

        text-align: center;
    }

    &__content {
        z-index: 1;
    }

    &__title {
        margin-bottom: 3.5rem;

        @include mq('medium') {
            margin-bottom: 6.5rem;
        }
    }

    &__secondary-text {
        margin-bottom: 3.5rem;
    }

    // state hooks

    &.has-bg {
        color: $white;
    }
}
