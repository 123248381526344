.page-home {
    position: relative;

    &__header {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        width: 100%;
        height: auto;
    }

    &__paw {
        background-color: $blue-utility;
    }

    // Full sections for marketing scroller styles

    &__full {
        position: relative;

        color: $white;

        background-size: cover;
    }

    &__full-inner {
        position: relative;
        z-index: 1;

        padding: 44.6rem 0 29.2rem;
    }

    &__full-eyebrow {
        @include font-family('bold');
        display: inline-block;

        margin-bottom: 10.5rem;

        line-height: 1;
    }

    &__full-heading {
        margin-bottom: 5rem;
    }

    &__full-blurb {
        @include h5;
        @include font-family('body');
        max-width: 53.2rem;
        margin-bottom: 5.3rem;

        @include mq('large') {
            @include h6;
        }
    }

    &__full-overlay {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: rgba($black, 0.25);
    }

    .footer {
        margin-top: 0;
    }
}
