@import '../mixins/headers';

@for $i from 1 through 10 {
    /* .h1-.h10 (classes) */
    .h#{$i},
    %h#{$i} {
        @include heading($i);
    }

    @if $i < 7 {
        /*
            h1-h6 (semantic tags)
            .display-1 - 6 (override bootstrap)
        */
        h#{$i},
        .display-#{$i} {
            @extend %h#{$i};
        }
    }

    @if $i < 3 {
        h#{$i} sup,
        .h#{$i} sup,
        .display-#{$i} sup {
            font-size: 0.5em;
        }
    }

    // Title 1 - 5 -> mapped to heading + 5
    @if $i > 5 {
        .title-#{($i - 5)} {
            @extend %h#{$i};
        }
    }
}

/* jumbo */

@for $i from 1 through 2 {
    %jumbo-#{$i},
    .jumbo#{$i},
    .jumbo-#{$i} {
        @include jumbo($i);
    }
}

/* Marketing Header - Legacy, mapped to Jumbo/Display | see: https://patagonia.atlassian.net/browse/DSX-1498 */

@for $i from 1 through 6 {
    .marketing-#{$i} {
        @if $i > 2 {
            @extend %h#{($i - 2)};
        } @else {
            @extend %jumbo-#{$i};
        }
    }
}
