@import './consentbanner';
@import './countryselector';
@import '../modules/modals/modal-country-selector';
@import '../modules/modals/modal-flag-selector';

// shared footer styles
.footer {
    position: relative;
    z-index: 100;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    clear: both;

    padding: 0 calc(var(--bs-gutter-x) * 1.5) 12rem;

    color: $white;

    background: $black;

    @include mq('medium') {
        padding: 0 calc(var(--bs-gutter-x) * 2.5) 8rem;
    }

    @include mq('xlarge') {
        padding: 0 calc(var(--bs-gutter-x) * 4) 12rem;
    }

    // temporary 50th prefooter
    &__fiftieth {
        padding-top: ($spacer * 10);

        @include mq('medium') {
            padding-top: ($spacer * 13.5);
        }
    }

    &__subscribe-form .btn {
        line-height: 3.5rem;
    }

    &__help-column a {
        &:hover,
        &:focus {
            color: $white;
        }
    }

    &__promo {
        position: relative;

        display: block;
        flex: 1 0 100%;

        margin-bottom: -1.5rem;

        .callout-headline {
            padding: 8rem 0;

            @include mq('large') {
                padding: 11rem 0 8rem;
            }

            &__content {
                max-width: 87rem;
            }

            &__inner {
                position: relative;

                padding: 0;

                @include mq('large') {
                    padding: 2rem;
                }
            }
        }
    }

    &__quote + &__sitemap {
        padding-top: 0;

        @include mq('large') {
            padding-top: 0;
        }
    }

    &__sitemap {
        position: relative;

        display: flex;
        flex: 1 0 100%;
        flex-flow: row wrap;
        justify-content: space-between;

        padding: 4rem 0 0;

        @include mq('large') {
            padding: 8rem 0 0;
        }

        // sitemap items
        > ul {
            position: relative;

            display: flex;
            flex: 0 0 100%;
            flex-flow: row wrap;
            justify-content: flex-start;

            margin: 4rem 0 0;

            @include mq('large') {
                flex: 0 0 100%;
                flex-flow: row nowrap;
            }

            li {
                position: relative;

                display: flex;
                flex: 1 0 100%;
                flex-flow: row wrap;

                margin-bottom: 6rem;

                @include mq('large') {
                    flex: 1 0 calc(100% / 3);

                    margin: 0;
                }

                &:nth-child(3) {
                    @include mq('medium') {
                        order: 1;
                    }

                    @include mq('large') {
                        order: 0;
                    }
                }

                &:nth-child(5) {
                    @include mq('medium') {
                        order: 2;
                    }

                    @include mq('large') {
                        order: 0;
                    }
                }

                ul {
                    margin-top: $spacer * 4;

                    white-space: wrap;

                    a {
                        color: rgba($white, 0.7);
                        text-decoration: none;

                        transition: color 0.2s $ease-out-soft;

                        &:hover {
                            color: rgba($white, 1);
                            text-decoration: none;
                        }
                    }

                    li {
                        position: relative;

                        display: block;

                        width: 100%;
                        margin-bottom: $spacer * 2;
                    }
                }
            }
        }

        &__column {
            @include mq('large') {
                padding: 0 ($spacer * 3);

                &:first-child {
                    padding: 0 ($spacer * 3) 0 0;
                }

                &:last-child {
                    padding: 0 0 0 ($spacer * 3);
                }
            }
        }

        .footer-email-capture-view-next {
            display: none;

            .footer-email-capture-view-next-cta-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                a:first-child {
                    margin-bottom: 1.3rem;
                }
            }
        }

        &__links {
            display: flex;
            flex-direction: row;

            @include mq('xsmall', 'max') {
                flex-wrap: wrap;
            }

            ul {
                padding: 0;

                &:first-child {
                    padding: 0;

                    @include mq('medium') {
                        padding-right: calc(var(--bs-gutter-x) * 0.5);
                    }
                }

                &:nth-child(2) {
                    margin-left: calc(var(--bs-gutter-x) * 0.5);
                    padding: 0;

                    @include mq('xsmall', 'max') {
                        margin-left: 0;
                    }

                    @include mq('xlarge') {
                        margin-top: 4rem;
                        padding-left: calc(var(--bs-gutter-x) * 0.5);
                    }
                }

                li {
                    margin-right: 0;
                    padding: 0;
                }
            }
        }

        .btn-cloud {
            margin-top: 4rem;

            @include mq('large') {
                margin-top: 0;
            }

            .btn {
                margin: {
                    bottom: 2rem;
                    right: calc(var(--bs-gutter-x) * 0.5);
                }

                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }

        .custom-input__footer {
            margin: 2rem 0 3rem;
        }
    }

    &__sitemap-newsletter-social {
        position: relative;

        display: block;
        flex: 0 0 100%;

        @include mq('medium') {
            flex: 0 0 33.33%;
        }
    }

    &__sitemap-newsletter {
        &--supheader {
            position: relative;

            display: block;

            margin-bottom: -0.6rem;

            color: $grey-silver;
        }
    }

    &__sitemap-social {
        position: relative;

        margin-top: 2.9rem;
        margin-left: -1.3rem;

        ul {
            position: relative;

            display: flex;
            flex-flow: row nowrap;
        }

        a {
            position: relative;

            display: inline-block;

            width: 4rem;
            height: 4rem;

            &:hover {
                svg * {
                    fill: $white;
                }
            }
        }

        svg {
            @include is-absolute-fs;

            * {
                fill: $grey-silver;

                transition: fill 0.2s $ease-out-soft;
            }
        }
    }

    // bottom
    &__bottom-wrapper {
        display: flex;
        flex: 1 0 100%;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;

        margin-top: 0;

        @include mq('large') {
            flex-flow: row nowrap;
        }
    }

    &__bottom-links {
        flex: 1 0 auto;

        text-align: right;

        @include mq('large', 'max') {
            flex: 1 0 100%;

            text-align: left;
        }

        a,
        p {
            display: inline-block;

            margin-right: calc(var(--bs-gutter-x) * 0.5);

            color: rgba($white, 0.7);
            text-decoration: none;

            transition: color 0.2s $ease-out-soft;

            a:before {
                bottom: -0.5rem;

                background: $white;
            }

            &:hover {
                color: rgba($white, 1);
            }
        }

        p {
            margin-top: 0;
            margin-right: 0;

            color: $white;

            @include mq('medium', 'max') {
                display: block;

                margin-top: 4rem;
            }
        }
    }

    &__bottom-copyright {
        @include h10;

        flex: 1 0 100%;

        margin-bottom: 3rem;

        color: $grey-silver;

        @include mq('large') {
            flex: 1 0 auto;

            margin-bottom: 0;
        }

        ul {
            display: flex;
            flex: {
                direction: row;
                wrap: wrap;
            }

            li {
                margin: 0 2rem 0.5rem 0;

                @include mq('large') {
                    margin: 0 1.5rem 0 0;

                    &:after {
                        margin-left: 1.5rem;
                    }
                }

                &:after {
                    content: '|';

                    margin-left: calc(var(--bs-gutter-x) * 0.5);
                }

                &:last-child:after {
                    content: '';

                    margin: 0;
                }
            }
        }
    }
}

.fpc-footer .footer {
    margin-top: 0;
}

.sfmc-email-signup {
    .custom-input {
        margin-bottom: 1em;
    }

    .invalid-feedback {
        position: relative;
        top: 0;
        left: 0;

        margin: 15px 0 0;
    }
}

.email-capture .disclaimer {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-left: 0;

    label {
        font-size: 1.1rem;
    }
}
