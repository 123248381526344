.footer {
    &__bottom-countryselector {
        margin-top: 4rem;
        margin-left: 0.5rem;

        text-align: right;

        @include mq('large') {
            margin-top: 0.25rem;
        }

        button,
        p {
            @include h10;

            display: inline-block;

            margin-right: 1.5rem;

            color: $white;
            text-decoration: none;

            transition: color 0.2s $ease-out-soft;

            @include mq('large') {
                display: flex;
            }
        }

        .icon__flag {
            position: static;
        }

        p {
            position: relative;

            margin-right: 1rem;
        }

        button {
            &:hover {
                p {
                    text-decoration: none;

                    transform: scale(1.048) translateZ(0);

                    transition: transform $button-out-time $ease-hover;
                }
            }
        }
    }
}

.navigation-primary {
    &__countryselector {
        margin-left: 0.5rem;

        text-align: right;

        button,
        p {
            display: block;

            text-decoration: none;

            transition: color 0.2s $ease-out-soft;
        }

        a:hover {
            text-decoration: underline;
        }

        p {
            position: relative;

            padding-left: 2.5rem;
        }
    }
}
