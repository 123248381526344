@import 'animation';

// global skrollex animations

[data-skrollex],
[data-skrollex-child] {
    &,
    &.sk-init {
        opacity: 0;
        transform: translateY(1.2rem);

        will-change: transform, opacity;

        @media (prefers-reduced-motion) {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.sk-show {
        opacity: 1;
        transform: none;

        transition:
            opacity 0.3s $ease-out-soft,
            transform 0.3s $ease-out-slide;
    }

    &.sk-show-complete {
        opacity: 1;
        transform: none;
    }
}
