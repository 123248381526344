.experience-html-block,
.richtext {
    ul,
    ol {
        padding-left: ($spacer * 2);

        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }
}
