.table {
    border-collapse: collapse;

    th,
    td {
        @include h7;
        padding: 1.1rem calc(var(--bs-gutter-x) * 0.5);

        vertical-align: middle;

        border: none;

        @include mq('large') {
            @include h9;
        }

        &:first-child {
            padding-left: 2rem;
        }

        &:last-child {
            padding-right: calc(var(--bs-gutter-x) * 2);
        }
    }

    th {
        @include font-family('medium');

        min-width: 5.15rem;
    }

    td {
        @include font-family('regular');
        min-width: 7rem;

        word-wrap: break-word;
    }

    .btn {
        float: right;
    }

    tbody {
        tr:nth-of-type(odd) {
            th:first-child {
                border-top-left-radius: 0.4rem;
                border-bottom-left-radius: 0.4rem;
            }

            td:last-child {
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
            }
        }
    }
}

.table-dark.table-striped {
    color: $white;

    background-color: $black;

    tbody tr:nth-of-type(odd) {
        background-color: $grey-dark;
    }
}

.table-light.table-striped {
    color: $black;

    background-color: $white;

    tbody tr:nth-of-type(odd) {
        background-color: $grey;
    }
}
