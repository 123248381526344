// split off from js/modules/storytelling-scroller/style.scss

.hero-caption {
    $this: &;
    position: relative;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    width: 100%;
    padding: 4rem 13vw;

    // STATES

    &.is-left {
        justify-content: flex-start;

        h2,
        h4 {
            text-align: left;
        }
    }

    &.is-right {
        justify-content: flex-end;

        h2,
        h4 {
            text-align: right;
        }
    }

    &.is-center {
        justify-content: center;

        h2,
        h4 {
            text-align: center;
        }
    }

    // ELEMENTS

    h2 {
        flex: 1 0 100%;

        + .hero-caption__cta,
        + .hero-caption__cta-wrapper {
            margin-top: 6rem;
        }
    }

    h4 {
        max-width: 52rem;
    }

    // THEME

    &.is-dark {
        #{$this}__headline,
        #{$this}__blurb {
            color: $black;
        }
    }

    &.is-light {
        #{$this}__headline,
        #{$this}__blurb {
            color: $white;
        }
    }
}
