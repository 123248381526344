@import './variables';
@import 'bootstrap/scss/variables';

.product__card {
    &__image {
        margin-bottom: 2rem;

        @include mq('medium') {
            width: 100%;
            height: auto;
            max-width: 20rem;
            margin: 0;
        }
    }

    &__header {
        margin-bottom: 1rem;

        .product__id {
            color: $grey4;
        }
    }

    &__info {
        height: 100%;
    }

    &__action-urls {
        position: absolute;
        bottom: 0;
        right: calc(var(--bs-gutter-x) * 0.5);
    }

    &__action-url {
        @include font-family('medium');

        white-space: nowrap;

        &:before {
            display: none;
        }

        &:last-of-type {
            margin-left: 2rem;
        }
    }
}
