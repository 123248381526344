.pane-tabs {
    background-color: var(--pat-theme-back, $white);

    &__wrapper {
        position: relative;

        &:before,
        &:after {
            position: absolute;
            top: -0.5rem;
            bottom: 0;
            content: '';
            z-index: 2;

            display: none;

            width: $spacer * 9;

            opacity: 0;

            pointer-events: none;

            transition: opacity 0.5s $ease-in-out-soft;

            @include mq('medium') {
                display: block;
            }
        }

        &:before {
            left: $spacer * 3;

            background: linear-gradient(-90deg, transparent 0%, var(--pat-theme-back, $white) 90%);

            @include mq('large') {
                left: $spacer * 7;
            }

            @include mq('xlarge') {
                left: $spacer * 9;
            }
        }

        &:after {
            right: $spacer * 3;

            background: linear-gradient(90deg, transparent 0%, var(--pat-theme-back, $white) 90%);

            @include mq('large') {
                right: $spacer * 7;
            }

            @include mq('xlarge') {
                right: $spacer * 9;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            @include DropShadow(4);
            position: absolute;
            top: 25%;
            z-index: 3;

            display: none;
            align-content: center;
            align-items: center;

            width: $spacer * 4;
            height: $spacer * 4;
            margin: 0;
            padding: 0;

            border-radius: $spacer * 4;

            background: $white;

            opacity: 0;
            transform: translateY(-50%);

            pointer-events: none;

            transition: opacity 0.5s $ease-in-out-soft;

            @include mq('medium') {
                display: inline-flex;
            }

            figure {
                position: relative;

                width: $spacer * 1.4;
                height: $spacer * 1.4;
            }

            svg {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                object-fit: contain;
            }
        }

        .swiper-button-prev {
            left: 0;

            svg {
                transform: rotate(180deg);
            }
        }

        .swiper-button-next {
            right: 0;
        }

        > .row {
            position: relative;
        }
    }

    &--can-scroll-left,
    &--can-scroll-right {
        .pane-tabs__wrapper {
            padding-right: 0;
            padding-left: 0;
        }

        .pane-tabs__btn-group {
            padding-left: $spacer * 3;
        }
    }

    &--can-scroll-left {
        .swiper-button-prev {
            opacity: 1;

            pointer-events: all;
        }

        .pane-tabs__wrapper {
            &:before {
                opacity: 1;
            }
        }
    }

    &--can-scroll-right {
        .swiper-button-next {
            opacity: 1;

            pointer-events: all;
        }

        .pane-tabs__wrapper {
            &:after {
                opacity: 1;
            }
        }
    }

    &__btn-group {
        position: relative;

        flex-wrap: nowrap;

        margin: -0.5rem 0 $spacer * 1.5;
        padding: 0.5rem $spacer $spacer * 1.5;

        white-space: nowrap;

        overflow: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        scrollbar-color: $grey-white transparent;
        scrollbar-width: thin;
        overscroll-behavior-x: contain;
        scroll-snap-type: x mandatory;
        scroll-snap-align: end;
        scroll-behavior: smooth;

        &::-webkit-scrollbar:horizontal {
            height: $spacer * 0.5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: $spacer;

            background-color: color-mix(
                in srgb,
                var(--pat-theme-back, $black),
                var(--pat-theme-fore, $white) 30%
            );
        }

        &::-webkit-scrollbar-track {
            border-radius: $spacer;

            background-color: transparent;
        }

        @media (hover: none) {
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }

        @include mq('large') {
            margin-bottom: $spacer * 2.5;
            padding-bottom: $spacer * 2.5;
        }
    }

    &__content {
        position: relative;

        > div {
            display: none;
        }

        // Resets container spacing for children directly inside of pane tabs
        .experience-region > div[data-pd-component-type] > .container,
        .experience-region > div[data-pd-component-type] > .container-xl {
            padding-top: 0;
            padding-bottom: 0;
        }

        .experience-region > div[data-pd-component-type] > div {
            padding: 0;
        }

        .experience-region .flex-router > .container {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: var(--bs-gutter-x);

            @include mq('medium') {
                padding-left: calc(var(--bs-gutter-x) * 2);
            }

            @include mq('large') {
                padding-left: calc(var(--bs-gutter-x) * 4);
            }

            @include mq('xlarge') {
                padding-left: calc(var(--bs-gutter-x) * 5);
            }
        }

        // Page designer slider - overwriting Swiper defaults
        .experience-region .flex-router > .slider-page-designer__container {
            height: auto;
            padding-bottom: 0.1rem;

            overflow-x: hidden;

            .swiper-wrapper {
                height: auto;
                min-height: auto;
            }
        }

        // Resets router cards bg colors
        .card-tile.card--router {
            .card__copy,
            .card__inner {
                background-color: var(--pat-theme-back, transparent);
            }
        }

        // Page designer slider - overwriting PD Swiper defaults
        .slider-page-designer__container {
            overflow-x: hidden !important;

            .swiper-pointer-events {
                touch-action: none;
            }

            .swiper-wrapper {
                height: auto;
                min-height: auto;
            }
        }

        // Overrides for Marketing Grid: Default/Flexible
        .marketing-grid {
            &__items {
                padding-right: var(--bs-gutter-x);
                padding-left: var(--bs-gutter-x);

                @include mq('medium') {
                    padding-right: calc(var(--bs-gutter-x) * 2);
                    padding-left: calc(var(--bs-gutter-x) * 2);
                }

                @include mq('large') {
                    padding-right: calc(var(--bs-gutter-x) * 4);
                    padding-left: calc(var(--bs-gutter-x) * 4);
                }

                @include mq('xlarge') {
                    padding-right: calc(var(--bs-gutter-x) * 5);
                    padding-left: calc(var(--bs-gutter-x) * 5);
                }
            }
        }

        // Overrides for Marketing Grid: Fixed
        .fixed-marketing-tile-layout {
            padding: 0;
            padding-left: calc(var(--bs-gutter-x) * 0.5);

            @include mq('medium') {
                padding-right: calc(var(--bs-gutter-x) * 1.5);
                padding-left: calc(var(--bs-gutter-x) * 1.5);

                &--3,
                &--4,
                &--5,
                &--6 {
                    padding-right: 0;
                }
            }

            @include mq('large') {
                padding-right: calc(var(--bs-gutter-x) * 4.5);
                padding-left: calc(var(--bs-gutter-x) * 4.5);

                &--3,
                &--4,
                &--5,
                &--6 {
                    padding-right: calc(var(--bs-gutter-x) * 3.5);
                    padding-left: calc(var(--bs-gutter-x) * 3.5);
                }
            }

            @include mq('xlarge') {
                &--3,
                &--4,
                &--5,
                &--6 {
                    padding-right: calc(var(--bs-gutter-x) * 4.5);
                    padding-left: calc(var(--bs-gutter-x) * 4.5);
                }
            }

            .fixed-marketing-tile-layout__items {
                @include mq('large') {
                    height: 84.8rem;

                    .marketing-tile__ratio {
                        padding-bottom: 0;
                    }
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                background: var(--pat-theme-cta-back, #fff);

                svg {
                    fill: var(--pat-theme-cta-fore, #000);
                }
            }
        }
    }
}

// Page designer slider - overwriting PD Swiper defaults
body.is-user-touch {
    .pane-tabs {
        .swiper-button-prev,
        .swiper-button-next {
            opacity: 0;
        }

        &--can-scroll-left {
            .swiper-button-prev {
                opacity: 1;
            }
        }

        &--can-scroll-right {
            .swiper-button-next {
                opacity: 1;
            }
        }
    }
}
