.modal-flag-selector {
    &__copy {
        text-align: left;
        margin: {
            top: 2rem;
            bottom: 4rem;
        }
    }

    &.modal__bottom {
        .modal-body {
            .flags {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                width: 100%;
                margin: 0;
                padding: 0;

                button {
                    max-width: 50%;

                    figure {
                        width: 100%;
                        padding: 5px;

                        background-image: url('../images/vectors/circle.svg');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                    }

                    .icon__flag {
                        position: static;

                        img {
                            width: 16rem;
                            height: 16rem;
                        }
                    }
                }
            }
        }
    }

    .modal-body {
        flex-direction: column;
    }

    .country-modal-open {
        margin: 0 auto;
        margin-top: 4rem;

        color: $white !important;
        font-weight: $font-weight-medium;
        font-size: 1.4rem;
        text-align: center;
    }

    .modal-content {
        min-height: 420px;
    }
}
