.stories-menu-tile {
    max-width: 100%;

    &:empty {
        display: none;
    }

    .marketing-tile {
        width: 100%;
        height: 25.9rem;
        margin-bottom: 4rem;

        border-radius: $border-radius;

        background: $white;

        overflow: hidden;
        aspect-ratio: 295/168;

        @include mq('large', 'min') {
            max-width: 25.9rem;

            border-radius: $border-radius;
            aspect-ratio: unset;
        }
    }

    .marketing-tile__ratio {
        display: none;
    }

    .product-tile {
        // stories nav menu tile

        &.stories-marketing-tile,
        &--feat {
            height: 100%;
            margin-bottom: 0;
        }

        &__image-wrap.in-grid {
            padding-top: 100%;
        }

        &__tile-link {
            position: absolute;
            inset: 0;
        }
    }
}

// augmenting mega menu hover state

.navigation-primary__nav-item {
    &.js-menu-stories {
        &:hover {
            .stories-marketing-tile,
            .stories-menu-tile {
                display: unset;
            }

            .stories-menu-tile {
                figure.card__svg,
                figure.product-tile--feat__cta {
                    position: relative;
                    top: unset;
                    left: unset;

                    width: 100%;
                    height: auto;

                    background: transparent;

                    opacity: 1;
                    transform: none;

                    @include mq('large') {
                        background: transparent;

                        opacity: 1;

                        transition-delay: 0.1s;
                    }
                }
            }
        }
    }
}
