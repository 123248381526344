@import 'base/components/notification';
@import 'base/components/tabs';
@import './components/breadcrumb';
@import 'base/components/spinner';
@import 'base/components/tooltip';
@import './tooltip';
@import './popover';
@import './forms';
@import './buttons';
@import './tables';
@import './headers';
@import './pricing';
@import './productCards';
@import './productTiles';
@import './../components/productTileMinimal';
@import './typography';
@import './iconography';
@import './icons';
@import './common';
@import './header';
@import './megamenu';
@import './mega-text';
@import './stories-menu-tile';
@import './footer';
@import './customInputMixins';
@import './customInput';
@import './accordions';
@import './alerts';
@import './badges';
@import './tags';
@import './loading';
@import './spinner';
@import '../plugin/instorepickup/instorepickup';
@import './pristine';
@import './headerTabs';
@import './scroll';
@import './section-header';
@import './positioning-header';
@import './pane-tabs';
@import './photo-tile';
@import './richText';
@import './chatBubble';

////////////////////////////////////////////////////////
// Icons
////////////////////////////////////////////////////////
:root {
    --arrow-expand: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/arrow-expand.svg',
            (
                'auto': $black,
            )
        )};
    --arrow-expand-white: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/arrow-expand.svg',
            (
                'auto': $white,
            )
        )};
    --check: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/check.svg',
            (
                'black': $black,
            )
        )};
    --chevron-left: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-left.svg',
            (
                'black': $black,
            )
        )};
    --chevron-right: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-right.svg',
            (
                'black': $black,
            )
        )};
    --chevron-up-small: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-up-small.svg',
            (
                'black': $black,
            )
        )};
    --chevron-up-small-white: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-up-small.svg',
            (
                'black': $white,
            )
        )};
    --chevron-down: #{svg('cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-down.svg')};
    --chevron-down-small: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-down-small.svg',
            (
                'black': $black,
            )
        )};
    --chevron-down-small-white: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-down-small.svg',
            (
                'black': $white,
            )
        )};
    --chevron-down-small-invalid: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chevron-down-small.svg',
            (
                'black': $red-utility,
            )
        )};
    --plus: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/plus.svg',
            (
                'black': $black,
            )
        )};
    --minus: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/minus.svg',
            (
                'black': $black,
            )
        )};
    --ser--quality: #{svg('cartridges/app_patagonia_base/cartridge/assets/svgs/ser/quality.svg')};
    --ser--quality-white: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/ser/quality.svg',
            (
                'auto': $white,
            )
        )};
    --form-feedback-icon-valid: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/check.svg',
            (
                'auto': $green-utility,
            )
        )};
    --lock-locked: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/lock-locked.svg',
            (
                'auto': $black,
            )
        )};
    --chat-bubble-white: #{svg(
            'cartridges/app_patagonia_base/cartridge/assets/svgs/chat-bubble.svg',
            (
                'auto': $white,
            )
        )};
}
