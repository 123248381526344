// from js/modules/grid/grid-product-tiles/style.scss

.grid-product-tiles {
    .row {
        @include mq('medium') {
            justify-content: center;
        }
    }

    &.has-no-header {
        padding-top: 2rem;

        @include mq('medium') {
            padding-top: 10rem;
        }
    }

    .cta-progress {
        @include mq('large') {
            bottom: calc(100% - 3rem);
        }
    }

    &__header {
        $this: &;
        position: relative;

        display: block;

        margin-bottom: 1.6rem;
        padding-top: 3.4rem;

        overflow: visible;

        @include mq('xlarge') {
            margin: 6.6rem 0 3.4rem;
            padding-top: 0;
        }

        @include mq('medium', 'max') {
            .cta-progress {
                bottom: 0.2rem;
            }
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        &.is-after-slider {
            margin-top: 0;
        }

        // Centered product tile version
        &--centered {
            margin: 2rem 0;

            @include mq('medium') {
                margin: 7.3rem 0 1rem;
            }

            .grid-product-tiles__header-title {
                margin-top: 0;

                text-align: center;

                @include mq('medium') {
                    margin-bottom: 2rem;
                }
            }

            + .cta-progress-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                height: 3.2rem;

                overflow: visible;

                @include mq('medium') {
                    height: 4.4rem;
                    margin-bottom: 3rem;
                }

                @include mq('medium', 'max') {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                .cta-progress {
                    bottom: 0;
                    right: auto;

                    margin: 0 auto;
                }
            }
        }
    }

    &__header-title {
        max-width: 40em;

        @include mq('large') {
            margin-top: 0;

            text-align: left;
        }

        span {
            @include h4;
        }
    }

    &__header-count {
        @include font-family('demi');
    }

    &__header-subhead {
        @include p;
        max-width: 40em;
        margin-top: $spacer * 2;
    }
}
