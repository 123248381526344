// from bits/cta-circle/style.scss

.cta-circle {
    position: relative;

    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    height: 4.4rem;
    min-width: 4.4rem;

    text-decoration: none;

    border-radius: 3rem;

    transition: transform $button-out-time $ease-hover;

    &__heading {
        position: relative;
        z-index: 2;

        display: block;

        width: 100%;
        padding: 0 5px;

        color: $white;
        text-align: center;
        text-transform: lowercase;
        vertical-align: middle;
    }

    svg {
        width: 1.6rem;
        height: 1.6rem;
    }

    &:hover {
        text-decoration: none;

        transform: scale(1.05);

        transition: transform $button-out-time $ease-hover;
    }

    ////////////////////////////////////////////////////////////////
    // VARIATIONS
    &--swatch {
        svg {
            visibility: hidden;

            opacity: 0;

            pointer-events: none;
        }

        &.disabled {
            visibility: visible;

            opacity: 0.4 !important;
        }

        &.active {
            svg {
                visibility: visible;

                opacity: 1;

                pointer-events: all;
            }
        }

        &.split-swatch {
            svg {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                &:first-child {
                    clip: rect(auto, 0.8rem, auto, auto);
                }

                &:last-child {
                    clip: rect(auto, auto, auto, 0.8rem);
                }
            }
        }
    }

    &.cta-circle-xlg {
        width: 4.8rem;
        height: 4.8rem;

        border-radius: 50%;

        @include mq('large') {
            width: 7.2rem;
            height: 7.2rem;
        }

        svg {
            width: 2rem;
            height: 2rem;

            @include mq('large') {
                width: 3.8rem;
                height: 3.8rem;
            }
        }

        &.split-swatch {
            svg {
                &:first-child {
                    clip: rect(auto, 1rem, auto, auto);

                    @include mq('large') {
                        clip: rect(auto, 1.6rem, auto, auto);
                    }
                }

                &:last-child {
                    clip: rect(auto, auto, auto, 1rem);

                    @include mq('large') {
                        clip: rect(auto, auto, auto, 1.6rem);
                    }
                }
            }
        }
    }

    &.cta-circle-lg {
        width: 5.6rem;
        height: 5.6rem;

        border-radius: 3rem;

        .cta-circle {
            &__heading {
                line-height: 5.6rem;
            }
        }

        svg {
            width: 2rem;
            height: 2rem;
        }

        &.split-swatch {
            svg {
                &:first-child {
                    clip: rect(auto, 1rem, auto, auto);
                }

                &:last-child {
                    clip: rect(auto, auto, auto, 1rem);
                }
            }
        }
    }

    &.cta-circle-sm {
        height: 3.2rem;
        min-width: 3.2rem;

        border-radius: 1.6rem;

        .cta-circle {
            &__heading {
                line-height: 3.2rem;
            }
        }

        svg {
            width: 1.2rem;
            height: 1.2rem;
        }

        &.split-swatch {
            svg {
                &:first-child {
                    clip: rect(auto, 0.6rem, auto, auto);
                }

                &:last-child {
                    clip: rect(auto, auto, auto, 0.6rem);
                }
            }
        }
    }

    &.cta-circle-light {
        background: $white;

        .cta-circle {
            &__heading {
                color: $black;
            }
        }

        &.disabled {
            background: $grey-silver;
        }

        svg {
            fill: $black;
        }

        &.cta-circle-outlined {
            svg {
                fill: $white;
            }
        }
    }

    &.cta-circle-dark {
        background: $black;

        .cta-circle {
            &__heading {
                color: $white;
            }
        }

        &.disabled {
            background: $grey-silver;
        }

        svg {
            fill: $white;
        }

        &.cta-circle-outlined {
            svg {
                fill: $black;
            }
        }
    }

    &.cta-circle-outlined {
        border: 4px solid $black;
        border-radius: 3rem;

        background-color: transparent;

        svg {
            fill: $black;
        }

        .cta-circle {
            &__heading {
                color: $black;
            }

            &.disabled {
                border: 4px solid $grey-silver;

                background: $white;

                .cta-circle {
                    &__heading {
                        color: $grey-silver;
                    }
                }

                svg {
                    fill: $grey-silver !important;
                }
            }

            &.cta-circle--swatch {
                &.cta-circle-light {
                    border: 3px solid $white !important;
                }

                svg {
                    fill: $black;
                }

                // make the black swatch checkmark white
                &[data-color='#000'] svg {
                    fill: $white;
                }
            }
        }

        &.disabled {
            svg {
                fill: $grey-silver;
            }
        }

        &.active {
            border: 4px solid $white;

            .cta-circle {
                &__heading {
                    color: $white;
                }
            }
        }

        // lightened elements when border or no background exists

        &.cta-circle-outlined.cta-circle-light,
        &.cta-circle-transparent.cta-circle-light {
            .cta-circle {
                &__heading {
                    color: $white;
                }
            }

            svg {
                fill: $white;
            }

            &.disabled {
                .cta-circle {
                    &__heading {
                        color: $grey-silver;
                    }
                }

                svg {
                    fill: $grey-silver !important;
                }
            }

            &.active {
                background: $white;

                .cta-circle {
                    &__heading {
                        color: $black;
                    }
                }
            }
        }

        // borders for light/dark bordered variation

        &.cta-circle-outlined {
            &.cta-circle-light {
                border: 4px solid $white;

                &.disabled {
                    border: 4px solid $grey-silver;
                }
            }

            &.cta-circle-dark {
                border: 4px solid $black;

                &.disabled {
                    border: 4px solid $grey-silver;

                    .cta-circle {
                        &__heading {
                            color: $grey-silver;
                        }
                    }

                    svg {
                        fill: $grey-silver;
                    }
                }

                svg {
                    fill: $black;
                }
            }
        }
    }

    .is-light {
        .cta-circle.cta-circle-outlined.cta-circle-light {
            border: 4px solid $white;

            .cta-circle {
                &__heading {
                    color: $white;
                }
            }

            &.cta-circle--swatch {
                &.cta-circle-light {
                    border: 3px solid $black;
                }
            }

            svg {
                fill: $white;
            }
        }

        .cta-circle {
            &__heading {
                color: $white;
            }
        }

        &.disabled {
            background: $grey-silver;

            .cta-circle {
                &__heading {
                    color: $grey;
                }
            }

            svg {
                fill: $grey-silver !important;
            }
        }

        &.active {
            background: $white;

            .cta-circle {
                &__heading {
                    color: $black;
                }
            }
        }
    }

    .is-dark {
        .cta-circle.cta-circle-outlined.cta-circle-dark {
            border: 4px solid $black;

            .cta-circle {
                &__heading {
                    color: $black;
                }
            }

            &.cta-circle--swatch {
                &.cta-circle-dark {
                    border: 4px solid $white;
                }
            }

            svg {
                fill: $black;
            }
        }

        .cta-circle {
            &__heading {
                color: $black;
            }
        }

        &.disabled {
            background: $grey-silver;

            .cta-circle {
                &__heading {
                    color: $grey;
                }
            }

            svg {
                fill: $grey-silver !important;
            }
        }

        &.active {
            background: $black;

            .cta-circle {
                &__heading {
                    color: $white;
                }
            }
        }
    }

    #white .cta-circle.cta-circle-outlined.cta-circle-light svg {
        fill: $black;
    }

    .cta-circle.cta-circle--svg-right {
        svg {
            margin-left: 0.2rem;
        }

        &.cta-circle-sm {
            svg {
                margin-left: 0.1rem;
            }
        }
    }

    .cta-circle.cta-circle--svg-left {
        svg {
            left: calc(50% - 0.2rem);
        }

        &.cta-circle-sm {
            svg {
                left: calc(50% - 0.1rem);
            }
        }
    }

    ////////////////////////////////////////////////////////////////
    // STATES

    &.disabled,
    &:disabled {
        border-color: $grey-silver;

        background: $grey-silver;

        cursor: default;

        &:hover {
            transform: none;
        }

        svg {
            fill: $white;
        }

        .cta-circle:not(.cta-circle-outlined) {
            .cta-circle__heading {
                color: $white;
            }
        }

        &.cta-circle-outlined {
            background-color: transparent;

            .cta-circle__heading {
                color: $grey-silver;
            }

            svg {
                fill: $grey-silver;
            }
        }
    }
}
