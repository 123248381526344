#addToBagQuickAdd {
    @include mq('medium', 'max') {
        .modal-dialog {
            position: absolute;
            inset: 0;

            min-height: 0;
        }

        .modal-add-to-bag {
            &-body {
                width: 100%;

                h4 {
                    @include h5;
                }

                > div {
                    align-items: flex-start;

                    width: 100%;
                }
            }

            &__attributes {
                > p {
                    text-align: left;
                }
            }

            &__info {
                .ser-tabs {
                    text-align: center;

                    .navigation-subnav-tabs {
                        margin-top: $spacer * 3;
                    }

                    .tab-content {
                        padding-bottom: $spacer * 3;
                    }
                }
            }
        }
    }

    .modal-add-to-bag {
        &-body {
            padding: $spacer * 2;

            border-top-left-radius: $card-border-radius;
            border-top-right-radius: $card-border-radius;

            @include mq('medium') {
                padding: ($spacer * 5.5) ($spacer * 10);
            }

            h4 {
                margin-bottom: $spacer * 4;

                @include mq('medium') {
                    margin-bottom: $spacer * 6.25;
                }

                &::after {
                    content: '';

                    display: inline-block;

                    width: 2.4rem;
                    height: 2.4rem;
                    margin-right: $spacer * -0.5;

                    vertical-align: middle;

                    background: var(--check) no-repeat center / 50%;

                    @include mq('medium') {
                        width: 4rem;
                        height: 4rem;
                        margin-right: $spacer;
                    }
                }
            }
        }

        &__name {
            @include h6;
        }

        &__attributes {
            margin-bottom: $spacer * 3;

            @include mq('medium') {
                margin-bottom: $spacer * 5.5;
            }

            > p {
                @include p-sm;
            }
        }
    }
}

.modal-add-to-bag {
    z-index: 9999;

    padding: 0 !important;

    @include mq('tiny') {
        &__clr::after {
            content: '|';

            display: inline-block;

            margin: 0 ($spacer * 0.5);
        }
    }

    .modal-content {
        .modal-body {
            padding: 6rem (var(--bs-gutter-x));

            p {
                margin-bottom: 0 !important;
            }

            &.light-theme {
                padding: $spacer * 5 (var(--bs-gutter-x)) $spacer * 4;

                color: $black;

                background: $white;
            }

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;

                text-align: center;

                h4,
                h6 {
                    margin-bottom: 1.6rem;
                }

                h4 {
                    &::after {
                        content: '';

                        display: inline-block;

                        width: 4rem;
                        height: 4rem;

                        vertical-align: middle;

                        background: var(--check) no-repeat center / 50%;

                        @include mq('large') {
                            width: 6rem;
                            height: 6rem;
                        }
                    }
                }
            }
        }
    }

    &.modal.modal__content {
        .modal-content {
            max: {
                width: 62rem;
                height: unset;
            }
        }

        p {
            margin-bottom: 0 !important;
        }

        .modal-body {
            > div {
                display: flex;
                flex-direction: column;
                align-items: center;

                text-align: center;

                h4,
                h6 {
                    margin-bottom: 2.4rem;
                }

                picture {
                    max-width: 186px;

                    @include mq('large') {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    &__image {
        height: auto;
        max-width: 200px;
        margin-bottom: 2.4rem;

        border-radius: $border-radius;
    }

    &__name {
        margin-bottom: 1rem;
        font: {
            weight: bold;
            size: 20px;
        }

        .badge {
            margin-left: $spacer * 0.5;

            vertical-align: top;
        }
    }

    &__attributes {
        display: flex;
        flex-direction: column;

        @include mq('tiny') {
            flex-direction: row;
        }

        p {
            font-size: 1.4rem;
        }
    }

    &__name,
    &__attributes p {
        line-height: 2.4rem;
    }

    &__info {
        color: $black;

        background: $grey-white;

        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
    }

    &__price {
        font-weight: $font-weight-medium;

        > div.price {
            display: inline;
        }
    }

    .price {
        margin-left: 0.5rem;
    }
}
