body .embeddedServiceHelpButton {
    .helpButton {
        $chat-btn-size: 54px;
        bottom: calc(24px + env(safe-area-inset-bottom));
        right: 20px;

        &,
        .uiButton {
            width: $chat-btn-size;
            height: $chat-btn-size;
            min-width: 0;
            margin: 0;
            padding: 0;

            border-radius: $chat-btn-size;
        }

        .uiButton {
            @include DropShadow(4);

            font-family: var(--pata-font-sans);

            background-color: $black;
            background-image: var(--chat-bubble-white);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 65% auto;

            transition: transform 0.25s ease-in-out;

            @include focus-visible {
                outline: $blue-utility solid 4px;
            }

            &.helpButtonDisabled {
                // no agents
                display: none;
            }
        }

        .helpButtonEnabled:hover {
            transform: scale(1.05);
        }

        .helpButtonEnabled:hover::before,
        .helpButtonEnabled:focus::before {
            display: none;
        }

        .helpButtonLabel,
        span {
            @include visually-hidden;
        }
    }
}
