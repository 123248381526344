.modal.modal-add-to-wishlist {
    .modal-content {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        @include mq('medium') {
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }

    .modal-body {
        &.modal-add-to-wishlist-body {
            display: block;

            width: 100%;
            padding: $spacer * 5 $spacer * 2;

            text-align: left;

            @include mq('medium') {
                padding: $spacer * 6 $spacer * 6 $spacer * 8;

                text-align: center;
            }
        }
    }

    .heading-container {
        display: flex;
        align-items: center;

        margin-bottom: $spacer * 4;

        @include mq('medium') {
            justify-content: center;
        }

        &.error {
            margin-bottom: 0;
        }
    }

    svg {
        width: 1.6rem;
        height: 1.2rem;
        margin-left: $spacer;

        @include mq('medium') {
            width: 2.8rem;
            height: 2.1rem;
            margin-left: $spacer * 1.5;
        }
    }

    h1 {
        @include h5;
    }

    p {
        @include h6;

        margin-bottom: $spacer * 3;
    }

    a {
        @include mq('medium') {
            min-width: 36rem;
        }
    }
}
