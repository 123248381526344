// PATAGONIA LOADING ANIMATION
$loading-animation-speed: 3s; // Animation Speed for one complete line bounce animation
$loading-animation-delay: -1.55s; // Offset to make animation start in a specific spot
$loading-animation-colors: $loading-animation-speed * 4; // Timing to cycle through four colors
$loading-animation-dasharray: 341.418; // Stroke Length if Mountain Line
$loading-animation-breakpoint: 320px; // Breakpoint Size for Stroke Thickness Change

.patagonia-loading {
    width: 100%;
    height: auto;
    max-width: 320px;
    padding: 10px;
    box-sizing: border-box;

    overflow: visible;

    .mountains {
        fill: none;
        stroke: $grey-silver;
        stroke-width: 3px;
        stroke-linecap: round;

        .veil.is-dark & {
            stroke: $grey-tundora;
        }
    }

    .mountains-animated {
        fill: none;
        stroke: #000;

        animation:
            patagonia-loader-mountains $loading-animation-speed ease-in-out infinite,
            patagonia-loader-color-mountains $loading-animation-colors linear infinite;
        animation-delay: $loading-animation-delay, 0s;
        stroke-width: 4px;
        stroke-dasharray: $loading-animation-dasharray;
        stroke-linecap: round;

        .veil.is-dark & {
            animation-name: patagonia-loader-mountains, patagonia-loader-color-mountains-dark;
        }
    }

    @media (max-width: $loading-animation-breakpoint) {
        max-width: 80%;
        padding: 10px;

        .mountains,
        .mountains-animated {
            stroke-width: 6px;
        }
    }
}

@keyframes patagonia-loader-mountains {
    0%,
    5% {
        stroke-dashoffset: $loading-animation-dasharray * 2;
    }
}

@keyframes patagonia-loader-color-mountains {
    0%,
    24.99% {
        stroke: $black;
    }

    25%,
    49.99% {
        stroke: $red-brand;
    }

    50%,
    74.99% {
        stroke: $purple-brand;
    }

    75%,
    100% {
        stroke: $blue-brand;
    }
}

@keyframes patagonia-loader-color-mountains-dark {
    0%,
    24.99% {
        stroke: $white;
    }

    25%,
    49.99% {
        stroke: $red-brand;
    }

    50%,
    74.99% {
        stroke: $purple-brand;
    }

    75%,
    100% {
        stroke: $blue-brand;
    }
}
